import { PORT_BACKEND_URL_SRC } from "../srcLocalPortReferenceBackend";

export const SendNumberOtp = async (phoneNumber) => {
    const isPlus = phoneNumber?.includes('+')
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/verify-phone-number`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                phoneNumber : isPlus ? phoneNumber : '+' + phoneNumber
            })
        });
        return response

    } catch (error) {
        alert(error.message);
    }
};