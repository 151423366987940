import React, { useEffect, useState } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';

const Theme = ({setThemeMode}) => {
    const [currentTheme, setCurrentTheme] = useState('dark');
    const [flag,setFlag] = useState(false)
    setTimeout(() => {
        setFlag(true)
      }, 100)

      useEffect(() => {
        setThemeMode(currentTheme)
      }, [currentTheme])

    function setTheme(themeName) {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('theme', themeName);
            document.documentElement.classList.remove('dark', 'light');
            document.documentElement.classList.add(themeName);
        }
    }

    function keepTheme() {
        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        setTheme(newTheme);
        setCurrentTheme(newTheme);
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const savedTheme = sessionStorage.getItem('theme') || 'dark';
            setTheme(savedTheme);
            setCurrentTheme(savedTheme);
        }
    }, []);

    return (
        <div className='sm:block hidden'>
            {flag && <div className='fixed dark-light-mode left-4 top-4 w-fit'>
                <input 
                    type="checkbox" 
                    checked={currentTheme === 'dark'}
                    onChange={keepTheme}
                    className="checkbox" 
                    id="checkbox"
                />
                <label htmlFor="checkbox" className="checkbox-label">
                    <i className="fas fa-moon"></i>
                    <i className="fas fa-sun"></i>
                    <span className="ball"></span>
                </label>
            </div>}
        </div>
    );
}

export default Theme
