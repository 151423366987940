import "../../../styles/sharedStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { getUserEmailByToken } from "../../../api/emailAPI";
// import { getUserIdByToken } from "../../../api/emailAPI";
import VerificationEmail from "../../../components/authBlocks/verificationEmail";
const {
  PORT_BACKEND_URL_SRC,
} = require("../../../srcLocalPortReferenceBackend");

function UserVerifyEmail() {
  const [verificationCode, setVerificationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useParams(); // Retrieve the token from the URL

  // Log eventId and redirectTo on component mount and whenever location.state changes
  useEffect(() => {
    const eventId = localStorage.getItem("eventId"); // Fetching eventId from localStorage
    // const redirectTo = localStorage.getItem("redirectTo"); // Fetching redirectTo from localStorage
  }, [location]);

  const handleResendEmail = async () => {
    try {
      if (!token) {
        alert("Verification token is not available. Cannot resend Email.");
        return;
      }
      const response = await fetch(
        `${PORT_BACKEND_URL_SRC}/resend-email-temp-user`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ emailVerificationToken: token }),
        }
      );

      const jsonData = await response.json();

      if (!response.ok) {
        throw new Error("Failed to resend Email to temporary user");
      }

      alert(jsonData?.message);
    } catch (error) {
      console.error("Error during Email resend:", error);
      alert(error.message);
    }
  };

  const handleVerification = () => {
    setIsLoading(true);
    fetch(`${PORT_BACKEND_URL_SRC}/verify-email/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: verificationCode }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.success) {
          localStorage.setItem("authKey", data?.data?.token);
          localStorage.setItem("userId", data?.data?.userId);
          if (data?.data?.role === "regular") {
            if (localStorage.getItem("eventId")) {
              navigate(`/user-add-card?userId=${data?.data?.userId}`);
            } else {
              navigate(`/user-discovery`);
            }
          }
          if (data?.data?.role === "creator") {
            navigate("/user-free-chat");
          }
        } else {
          alert(data.message);

          console.error("11111 outside else spotify redirection");
        }
      })
      .catch((error) => {
        console.error("Error during verification:", error);
        alert(`Error during verification: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex full-screen">
      <div className="m-auto">
        <VerificationEmail
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          handleVerification={handleVerification}
          handleResendEmail={handleResendEmail} // Pass the resend function to the component
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default UserVerifyEmail;
