import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { removeLocalStorage } from '../../../utils/removeLocalstorage';

const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

export const useFetchEventDetails = (setResponse, navigate, setLoader) => {
  const [eventData, setEventData] = useState(null);
  const [bookingDetails, setBookingDetails] = useState({})
  const { eventId } = useParams();

  useEffect(() => {
    async function fetchEventDetails() {
      setLoader(true)
      try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event/${eventId}`, {
          method: 'GET',
          headers: {
            'Authorization': localStorage.getItem('authKey')
          }
        });
        const data = await response.json();
        setLoader(false)
        setResponse({ response: response, data: data })
        if (response.status === 211 && navigate) {
          alert(data?.message)
          removeLocalStorage()
          navigate('/')
        }
        if (response.ok) {
          setEventData(data?.event);
          setBookingDetails(data?.bookingParticipantInfo)
        } else {
          console.error(data.message || 'Failed to fetch event details.');
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    }
    fetchEventDetails();
  }, [eventId]);

  return [eventData, setEventData, bookingDetails];
};

export const updateAttendance = async (participantObjectId, eventId, isAttending, setAttendantMessage, setEventData, eventData) => {
  // Create the request body
  const requestBody = {
    participantObjectId,
    eventId, // Use the captured eventId from the outer scope
    isAttending,
  };

  try {
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/update-attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    // Convert response to JSON and log it
    // const jsonResponse = await response.json();

    if (response.ok) {
      setAttendantMessage('Attendance updated successfully.');
    } else {
      console.error('Attendance update failed:', response.status, response.statusText);
      // Handle the error here, for example, by displaying an error message to the user.
    }
  } catch (err) {
    console.error('Error in updateAttendance:', err);
    // Handle the error here, for example, by displaying an error message to the user.
  }
};


export const handleAddClick = async (participantObjectId, eventId) => {
  try {
    // Construct the request payload
    const payload = {
      participantObjectId,
      eventId,
      isAttending: true, // Assuming you're setting attendance to true
    };

    // Perform the API request
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/update-attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json(); // Assuming the backend returns JSON data

    if (response.ok) {
      // If the API call was successful, you can return true or the data as needed
      return true; // Indicate success
    } else {
      // Handle non-200 responses
      console.error('Update failed:', data);
      return false;
    }
  } catch (error) {
    // Handle errors in the API call itself (network issues, etc.)
    console.error('Error in handleAddClick:', error);
    return false;
  }
};

export const handleScan = async (scannedData, setAttendantMessage, eventId, setEventData, setIsScanning) => {
  try {
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/scan-participant-qr`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eventId, scannedData }),
    });

    const data = await response.json();

    if (response.ok) {
      if (data.success) {
        const addSuccess = await handleAddClick(data.participant._id, eventId);
        if (addSuccess) {
          setAttendantMessage(`Participant ${data.participant.firstName} ${data.participant.lastName} verified.`);
          setEventData(prevEventData => {
            // Assuming eventParticipants is an array of participant objects
            const updatedParticipants = prevEventData.eventParticipants.map(p => {
              if (p._id === data.participant._id) {
                // Mark the participant as verified
                return { ...p, verified: true };
              }
              return p;
            });
            return { ...prevEventData, eventParticipants: updatedParticipants };
          });
          setIsScanning(false); // Stop scanning
        } else {
          setAttendantMessage('Failed to verify participant.');
        }
      } else {
        setAttendantMessage('QR code verification failed.');
      }
    } else {
      // Handle non-200 responses
      console.error('QR code verification failed:', data.message);
      setAttendantMessage('QR code verification failed.');
    }
  } catch (error) {
    // Handle errors in the API call itself (network issues, etc.)
    console.error('Error in handleScan:', error);
    setAttendantMessage('Error while verifying QR code.');
  }
};
