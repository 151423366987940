import React, { useState } from 'react'
import InputField from '../miscBlocks/inputField'
import { verifyPhoneNumbers } from '../../api/VerifyPhoneNumberApi.js'
import LoadingData from '../loading/Loading.js'

const VerifyPhoneNumber = ({ setVerifyPhoneNumber, verifyPhoneNumber, userData, setUserData }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [verificationCode, setVerificationCode] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const apiResponse = await verifyPhoneNumbers(verificationCode, userData?.phoneNumber)
        const data = await apiResponse.json()
        if(data?.success){
            setIsLoading(false)
            setVerifyPhoneNumber({ ...verifyPhoneNumber, popup: false })
            setUserData({...userData, isPhoneVerificationCompleted: true})
        }
        else{
            setIsLoading(false)
            alert(data?.message)
        }
    }
    
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Verify Phone Number
                        </h3>
                        <button type="button" onClick={() => setVerifyPhoneNumber({ ...verifyPhoneNumber, popup: false })} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 pt-1">
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <InputField
                                label="Verification Code"
                                type="text"
                                placeholder="Enter 6-digit code"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                            <div className="mt-8 flex flex-col gap-y-4">
                                    <button
                                        className="flex justify-center py-4 !bg-[#686D76] rounded-xl text-white font-bold text-lg min-w-[200px] px-10"
                                    >
                                        {isLoading ? <LoadingData/> : "Verify"}
                                    </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyPhoneNumber
