import React from 'react';

const SpotifyLinkPreviewer = ({ link }) => {
  const isSpotifyLink = link.includes('open.spotify.com/track');
  const isMobile = window.innerWidth < 600
  return isSpotifyLink ? (
    <iframe
      src={link.replace('open.spotify.com/track', 'open.spotify.com/embed/track')}
      width={isMobile ? '230': '300'}
      height="80"
      allowTransparency="true"
      allow="encrypted-media"
      style={{ border: 'none', borderRadius: "10px" }}
      title="Spotify music preview">
    </iframe>
  ) : (
    <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
  );
};

export default SpotifyLinkPreviewer;