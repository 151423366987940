import * as React from 'react';

function EventHeader({ eventName }) {
    return (
        <div className="text-center">
            <h1 className='text-5xl font-semibold'>{eventName}</h1>
            <p className='font-medium text-lg text-gray-500 mt-4'>
                Consider this form, the ticket process to get to the event. 
                The event is free and your answers will be reviewed by the organizer.
            </p>
        </div>
    );
}

export default EventHeader;
