const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const attendenceConfirm = async (bookingTransactionId, participantId, eventId) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/change-participant-status-to-confirm`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({ bookingTransactionId, participantId, eventId })
        });
        const responseAttendanceData = await response.json()
        if(response.ok){
            return responseAttendanceData
        }
        else{
            alert(responseAttendanceData.message)
        }
    } catch (error) {
        console.error('Error during login:', error);
    }
}