import '../../styles/sharedStyle.css';
import React from 'react';
import SupportVideo from '../../components/media/supportVideo';
import FeedbackForm from '../../components/support/feedbackForm';
import DetailBackButton from '../../components/events/detailSection/detailBackButton';
import DetailEventTitle from '../../components/events/detailSection/detailEventTitle';
import DetailEventDescription from '../../components/events/detailSection/detailEventDescription';

function Support() {
    return (
        <div className="basic-container">
            <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>
            <div className="card">
                <DetailEventTitle eventName={"Get started"} />
            </div>
            <div className="video-container flex justify-center items-center"> {/* Centering container */}
                <SupportVideo />
            </div>
            <div className="card mt-5 mb-5">
                <div className="event-title-container">
                    <h1 className="event-name">{"Feedback"}</h1>
                </div>
            </div>
            <div className="card mt-5 mb-5">
                <DetailEventDescription description={"Tell us what feature you would love to see on the platforms or what is not working as you imagine <3"} />
            </div>
            <div className="feedback-container">
                <FeedbackForm />
            </div>
        </div>
    );
}

export default Support;
