import { PORT_BACKEND_URL_SRC } from '../srcLocalPortReferenceBackend';


export const getUserPhone = async () => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/get-user-phone`, {
            method: 'GET',
            //credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch user phone number:', response.status, response.statusText);
            throw new Error(`Failed to fetch user phone number: ${response.statusText}`);
        }

        const data = await response.json();
        return data.phoneNumber;
    } catch (error) {
        console.error('Error fetching user phone number:', error);
        throw error;
    }
};

export const getUserPhoneByToken = async (token) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/get-user-phone-by-token/${token}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            console.error('Failed to fetch user phone number with token:', response.status, response.statusText);
            throw new Error(`Failed to fetch user phone number with token: ${response.statusText}`);
        }

        const data = await response.json();
        return data.phoneNumber;
    } catch (error) {
        console.error('Error fetching user phone number with token:', error);
        throw error;
    }
};


