import React, { useState } from 'react';

function Chat({ isDoneShowing, responseLoading, onSendMessage }) {
    const [userMessage, setUserMessage] = useState('');

    const handleUserMessageChange = (e) => setUserMessage(e.target.value);

    const handleSendMessage = async () => {
        if (userMessage.trim()) {
            setUserMessage(''); // Clear the input after sending
            await onSendMessage(userMessage.trim());
        }
    };

    return (
        <div className="chat-container fixed bottom-0 inset-x-0 w-full md:w-3/4 mx-auto px-4 pb-4 pt-2 bg-transparent">
            <div className="flex items-center bg-white dark:bg-gray-800 dark:text-white rounded-2xl overflow-hidden shadow">
                <textarea
                    placeholder="Type your message..."
                    value={userMessage}
                    onChange={handleUserMessageChange}
                    onKeyPress={e => e.key === 'Enter' && !e.shiftKey ? (e.preventDefault(), handleSendMessage()) : null}
                    rows="3"
                    className="flex-grow focus:outline-none dark:bg-gray-800 dark:text-white focus:border-black text-black border-0 border-black rounded-l-xl p-4 mb-0 mr-2"
                    disabled={responseLoading || !isDoneShowing}
                />
                <button
                    onClick={handleSendMessage}
                    className="bg-black rounded-full p-3 text-white flex-shrink-0 m-2"
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default Chat;
