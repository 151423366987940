import React, { useRef, useEffect } from 'react';

const MindARFace = ({ onRotationChange }) => {
  const iframeRef = useRef(null);

  const refreshIframe = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.postMessage('refresh', '*');
    }
  };
  

  const arHtmlContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r128/three.min.js"></script>
        <script src="https://aframe.io/releases/1.4.2/aframe.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/mind-ar@1.2.2/dist/mindar-face-aframe.prod.js"></script>
      </head>
      <body style="margin: 0;">
        <a-scene mindar-face embedded vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false">
          <a-camera active="false" position="0 0 0"></a-camera>
          <a-entity mindar-face-target="anchorIndex: 1">
            <!-- Your AR content goes here -->
            <a-box position="0 0.5 0" rotation="0 45 45" color="#4CC3D9"></a-box>
          </a-entity>
        </a-scene>
        <script>
          // Listen for messages from the parent window
          window.addEventListener('message', (event) => {
            if (event.data === 'refresh') {
              window.location.reload();
            }
          });

          function sendRotationData() {
            const trackedEntity = document.querySelector('[mindar-face-target]');
            if (trackedEntity && trackedEntity.object3D) {
              // Get world rotation as a Quaternion
              const worldQuaternion = new THREE.Quaternion();
              trackedEntity.object3D.getWorldQuaternion(worldQuaternion);
        
              // Convert Quaternion to Euler angles
              const worldRotation = new THREE.Euler().setFromQuaternion(worldQuaternion, 'XYZ');
        
              // Send world rotation data
              window.parent.postMessage({
                rotation: {
                  x: worldRotation.x * (180 / Math.PI),
                  y: worldRotation.y * (180 / Math.PI),
                  z: worldRotation.z * (180 / Math.PI)
                }
              }, '*');
            }
          }

          // Call sendRotationData in your AR render loop or based on an event
          // Example: Call every 100 milliseconds
          setInterval(sendRotationData, 100);
        </script>
      </body>
    </html>
  `;

  useEffect(() => {
    const handleRotationData = (event) => {
      if (event.data.rotation) {
        onRotationChange(event.data.rotation);
      }
    };

    window.addEventListener('message', handleRotationData);

    return () => {
      window.removeEventListener('message', handleRotationData);
    };
  }, [onRotationChange]);

  return (
    <div>
      <button onClick={refreshIframe}>
        If face tracking is not working, click on this tab and slightly resize the window of your browser
      </button>
      <iframe
        ref={iframeRef}
        srcDoc={arHtmlContent}
        title="MindARFace"
        style={{ width: '100vw', height: '90vh', border: 'none' }}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default MindARFace;
