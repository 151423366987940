import React from 'react';
import termsOfService from '../../content/termsOfService.json';
import DetailBackButton from '../../components/events/detailSection/detailBackButton';
import '../../styles/policies.css'; // Import the CSS file

function TermsOfService() {
    return (
        <div className="basic-container">
            <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>

            <div className="card mt-5 mb-5">
                <div className="event-title-container">
                    <h1 className="event-name">{termsOfService.title}</h1>
                </div>
            </div>
            {termsOfService.sections.map((section, index) => (
                <div key={index}>
                    <div className="card mt-5 mb-5">
                    <h2 style={{ fontWeight: 'bold' }}>{section.heading}</h2>
                        <p>{section.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TermsOfService;
