// BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/detail.css';
function DetailEventDashboardButton() {
  let navigate = useNavigate();

  return (
    <button onClick={() => navigate('/user-dashboard-create')} className="dashboard-button !bg-[#686D76]">
      Dashboard
    </button>
  );
}

export default DetailEventDashboardButton;
