import { PORT_BACKEND_URL_SRC } from "../srcLocalPortReferenceBackend";

export const confirmFeatured = async (fieldData, eventId, action, eventData, requestingStatus) => {
    try {
        const body = {};
        body.adminSecureCode = fieldData;
        body.eventId = eventId;
        body.requestingStatus = requestingStatus;
        if (eventData.unFeatureReason) {
            body.isUnfeatured = action;
        } else {
            body.isFeatured = action;
        }
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/update-featured-event-status`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
        return response
    } catch (error) {
        alert(error)
    }
}