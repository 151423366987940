export const getRefundDetails = (status) => {
    switch (status) {
        case 'booked':
            return 'Booked';
        case 'pre-booked':
            return 'Pre Booked';
        case 'confirmed':
            return 'Confirmed';
        case 'checked-in':
            return 'Checked In';
        case 'cancelled':
            return 'Cancelled';
        case 'refund-failed':
            return 'Refund Failed';
        case 'refund-requested':
            return 'Refund Requested';
        case 'refund-processed':
            return 'Refund Processed';
        case 'refund-completed':
            return 'Refund Completed';
        case 'payment-failed':
            return 'Payment Failed';
        default:
            return 'Unknown Status';
    }
}
