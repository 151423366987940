import React, { useState } from 'react'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'

const DeleteFeaturedEvent = ({setDeleteFeaturedEvent, eventId}) => {
    const [message, setMessage] = useState('')
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/handle-unfeatured-event-request`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify({
                    unFeatureReason: message,
                    eventId: eventId
                }),
            });
            const responseData = await response.json()
            setDeleteFeaturedEvent(false)
            alert(responseData.message)
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    }
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen bg-white flex items-center">
            <div className="relative p-4 w-full max-w-3xl h-screen">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900">
                            Delete Featured Event
                        </h3>
                        <button type="button" onClick={() => setDeleteFeaturedEvent(false)} className="end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="card">
                            <label className='text-lg font-medium'>Please provide a reason for the removal of this featured event</label>
                            <textarea
                                id="content"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className='focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                placeholder="Enter your reason"
                                rows="4"
                                required
                            />
                        </div>
                        <div className='flex justify-center pb-5 gap-4'>
                            <button type='submit' className="back-button">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DeleteFeaturedEvent
