import React from 'react';
function InstagramPreview({ link }) {
    const postId = link.split("/p/")[1].split("/")[0];

    return (
            <iframe 
                title="Instagram Content"
                src={`https://www.instagram.com/p/${postId}/embed`}
                frameBorder="0"
                scrolling="no"
                allowTransparency="true"
                allowFullScreen={true}>
            </iframe>
    );
}
export default InstagramPreview;
