// qr-code-experience.js
import React, { useState } from 'react';
import QRScanner from './SimpleQRScanner';

const QRCodePage = () => {
  const [scannedCode, setScannedCode] = useState('');

  const handleScanSuccess = (decodedText, decodedResult) => {
    setScannedCode(decodedText);
  };

  const handleScanError = (error) => {
    console.error('Scan error:', error);
  };

  return (
    <div>
      <h2>Scan QR Code</h2>
      <QRScanner
        onScanSuccess={handleScanSuccess}
        onScanError={handleScanError}
      />
      {scannedCode && <p>Scanned Code: {scannedCode}</p>}
    </div>
  );
};

export default QRCodePage;
