import { useAnimations, useGLTF } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
// import Monocolor from '../../utils/monocolor';
import { removeLocalStorage } from '../../utils/removeLocalstorage';
import * as THREE from 'three';
import PageLoader from '../../utils/PageLoader';
import LoadingData from '../../components/loading/Loading';

const MaleModel = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale 2.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);
    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const MaleMono = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale 2 new texture.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);
    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const FemaleModel = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale female default.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);

    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const FemaleMono = () => {
    const group = useRef();
    const { scene, animations } = useGLTF('/models/blu/ale new texture.glb');
    const { actions } = useAnimations(animations, group);
    useEffect(() => {
        if (actions?.idle) {
          actions.idle.reset().play().setLoop(THREE.LoopRepeat, Infinity);
        }
    
        return () => {
          if (actions?.idle) actions.idle.stop();
        };
      }, [actions]);

    return (
        <group ref={group} scale={[3.6, 3.6, 3.6]} position={[0, -3, 0]}>
            <primitive object={scene} />
        </group>
    );
};

const UserChangeCharacter = () => {
    const navigate = useNavigate()
    // const location = useLocation();
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [maleSelectModel, setMaleSelectModel] = useState('MALE_OUTFIT')
    const [femaleSelectModel, setFemaleSelectModel] = useState('FEMALE_OUTFIT')
    const [loader, setLoader] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    // const renderCharacterModel = () => {
    //     switch (selectedCharacter) {
    //         case 'MALE_OUTFIT':
    //             return 'Male Normal'
    //         case 'MALE_UNIFORM':
    //             return 'Male Mono'
    //         case 'FEMALE_OUTFIT':
    //             return 'Female Normal'
    //         case 'FEMALE_UNIFORM':
    //             return 'Female Mono'
    //         default:
    //             return 'Male Normal' // Fallback to male model
    //     }
    // };


    useEffect(() => {
        const getCurrentCharacter = async () => {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/get-default-model`, {
                method: 'GET',
                headers: {
                    'Authorization': localStorage.getItem('authKey')
                },
            });

            const data = await response.json();
            setLoader(false)
            if (response.status === 211) {
                alert(data?.message)
                removeLocalStorage()
                navigate('/')

            }
            if (response?.status === 200) {
                setSelectedCharacter(data?.userInfo?.defaultAvatarModel)
                if (data?.userInfo?.defaultAvatarModel?.includes('FEMALE')) {
                    setFemaleSelectModel(data?.userInfo?.defaultAvatarModel)
                }
                else if (data?.userInfo?.defaultAvatarModel?.includes('MALE')) {
                    setMaleSelectModel(data?.userInfo?.defaultAvatarModel)
                }
            }
            else {
                alert(data?.message)
            }
        }
        getCurrentCharacter()
    }, [])

    const handleSave = async () => {
        setIsLoading(true)
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/update-default-model`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                defaultAvatarModel: selectedCharacter
            }),
        });

        const data = await response.json();
        setIsLoading(false)
        if (data?.success === true) {
            alert(data?.message)
            if (data?.userInfo?.role === 'creator') {
                navigate('/user-dashboard-create')
            }
            else if (data?.userInfo?.role === 'regular') {
                navigate('/user-dashboard-book')
            }
        }
        else {
            alert(data?.message)
        }
    }


    return (
        <>
            {loader ? <PageLoader/> : <div className="dark:bg-[#191a1b]">
                <div className="p-4 flex flex-col gap-2 rounded-3xl dark:bg-[#191a1b] border-0 border-white">
                    <div className="event-title-container">
                        <h1 className="event-name text-center">Change Character</h1>
                        {/* <div className='text-center text-lg'>Our application use model for ai please select model from below</div> */}
                    </div>
                    {/* <div className='text-center text-lg'><span className='font-bold'> Selected</span> : {renderCharacterModel()}</div> */}
                    <div className='flex flex-col mt-4 lg:mt-12 lg:flex-row items-center justify-center gap-10 lg:gap-20'>
                        <div>
                        <div className={`card cursor-pointer flex flex-col items-center w-[320px] sm:w-[440px] ${!selectedCharacter.includes("FEMALE") && 'outline outline-[#4535C1]'}`}>
                                <Canvas style={{ width: '230px', height: '300px', zIndex: 200 }}>
                                    <ambientLight intensity={1} />
                                    <directionalLight position={[10, 10, 5]} intensity={1} />
                                    {/* <Model model={'ale 2.glb'} /> */}
                                    {maleSelectModel === 'MALE_OUTFIT' && <MaleModel />}
                                    {maleSelectModel === 'MALE_UNIFORM' && <MaleMono />}
                                </Canvas>
                            </div>
                            <div className='flex gap-5 justify-center'>
                                <div onClick={() => {
                                    setSelectedCharacter('MALE_OUTFIT')
                                    setMaleSelectModel('MALE_OUTFIT')
                                }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Outfit</span></div>
                                <div onClick={() => {
                                    setSelectedCharacter('MALE_UNIFORM')
                                    setMaleSelectModel('MALE_UNIFORM')
                                }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Uniform</span></div>
                            </div>
                        </div>
                        <div>
                        <div className={`card cursor-pointer flex flex-col items-center w-[320px] sm:w-[440px] ${selectedCharacter.includes("FEMALE") && 'outline outline-[#4535C1]'}`}>
                                <Canvas style={{ width: '230px', height: '300px', zIndex: 200 }}>
                                    <ambientLight intensity={1} />
                                    <directionalLight position={[10, 10, 5]} intensity={1} />
                                    {femaleSelectModel === 'FEMALE_OUTFIT' && <FemaleModel />}
                                    {femaleSelectModel === 'FEMALE_UNIFORM' && <FemaleMono />}
                                </Canvas>
                            </div>
                            <div className='flex gap-5 justify-center'>
                                <div onClick={() => {
                                    setSelectedCharacter('FEMALE_OUTFIT')
                                    setFemaleSelectModel('FEMALE_OUTFIT')
                                }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Outfit</span></div>
                                <div onClick={() => {
                                    setSelectedCharacter('FEMALE_UNIFORM')
                                    setFemaleSelectModel('FEMALE_UNIFORM')
                                }} className='!w-[120px] rounded-lg h-[42px] animated-btn cursor-pointer flex justify-center items-center'><span className='text'>Uniform</span></div>
                            </div>
                        </div>
                    </div>
                    <button className='py-4 px-8 animated-btn m-auto mt-8 !w-fit' onClick={handleSave}><span className='text'>{isLoading ? <LoadingData/> : "Save"}</span></button>
                </div>
            </div> }
        </>
    )
}

export default UserChangeCharacter
