import React, { useEffect, useState } from 'react';
import StripeCheckPopup from '../components/popup/stripeCheckPopup';

const UserStatusModel = (props) => {
  const { eventResponse } = props
  const { response, data } = eventResponse
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const checkStatus = async () => {
    if (response?.status === 266) {
      setIsOpen(true);
      setMessage(data?.message);
    }
  };

  useEffect(() => {
    checkStatus()
  }, [response?.status])

  return (
    <>
      {isOpen && <StripeCheckPopup stripeAcknowledgementMeesage={data?.data?.stripeAcknowledgementMeesage} setIsOpen={setIsOpen} message={message} userId={data?.data?.userId} email={data?.data?.email}/>}
    </>
  );
};

export default UserStatusModel;
