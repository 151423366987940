// SocialMediaLinks.js
import React from 'react';
import SpotifyPreview from '../../previews/spotifyPreview';
import SoundcloudPreview from '../../previews/soundcloudPreview';
import TikTokPreview from '../../previews/tiktokPreview';
import InstagramPreview from '../../previews/instagramPreview';
import '../../../styles/detail.css';
import YoutubePreview from '../../previews/youtubePreview';

function DetailSocialMediaLinks({ links }) {
  const renderSocialMediaPreview = (link) => {
    if (link.includes("spotify.com")) {
      return <SpotifyPreview link={link} />;
    } else if (link.includes("soundcloud.com")) {
      return <SoundcloudPreview link={link} />;
    } else if (link.includes("tiktok.com")) {
      return <TikTokPreview link={link} />;
    } else if (link.includes("instagram.com")) {
      return <InstagramPreview link={link} />;
    } else if (link.includes("youtube.com")) {
      return <YoutubePreview link={link} />;
    } else {
      return <div className="unsupported-link">Unsupported link type.</div>;
    }
  };

  return (
    <div className="social-media-links-container grid sm:grid-cols-2 gap-4">
      {links?.map((link, index) => (
        <div key={index} className="social-media-preview-card bg-transparent">
          {renderSocialMediaPreview(link)}
        </div>
      ))}
    </div>
  );
}

export default DetailSocialMediaLinks;
