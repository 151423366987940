import React from 'react';
import ReactPlayer from 'react-player';
import '../../styles/supportStyle.css'; 

const VideoPlayer = () => {
    return (
        <div className="video-wrapper">
            <ReactPlayer
                className="react-player"
                url="https://vimeo.com/889772818"
                controls={true}
                width="100%"
                height="100%"
            />
        </div>
    );
}


export default VideoPlayer;
