import React from "react";

const StripeFillDetails = ({ setPopup }) => {
  return (
    <div
      id="authentication-modal"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      tabindex="-1"
      aria-hidden="true"
      className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-[50000] justify-center items-center w-full md:inset-0 max-h-full h-screen"
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Stripe Details
            </h3>
            <button
              type="button"
              onClick={() => setPopup(false)}
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              data-modal-hide="authentication-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5">
            <div className="flex sm:flex-row flex-col gap-4 sm:gap-12">
              <div className="p-4">
                <div className="font-bold text-2xl underline">Individual</div>
                <ul className="mt-3 !list-disc">
                  <li>Type of Business</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Date of Birth</li>
                  <li>Home Address</li>
                  <li>Business Website</li>
                  <li>Social Security Number</li>
                  <li>Bank Account Number</li>
                  <li>Routing Number</li>
                </ul>
              </div>
              <div className="p-4">
                <div className="font-bold text-2xl underline">Company</div>
                <ul className="mt-3 !list-disc">
                  <li>Business Structure</li>
                  <li>Legal Business Name</li>
                  <li>Employer Identification Number</li>
                  <li>Business Address</li>
                  <li>Business Phone Number</li>
                  <li>Industry</li>
                  <li>Business Website</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Date of Birth</li>
                  <li>Home Address</li>
                  <li>Phone Number</li>
                  <li>Social Security Number</li>
                  <li>Bank Account Number</li>
                  <li>Routing Number</li>
                  <li>Photo ID</li>
                </ul>
              </div>
              <div className="p-4">
                <div className="font-bold text-2xl underline">
                  Not profit organisation (NGO)
                </div>
                <ul className="mt-3 !list-disc">
                  <li>Legal Business Name</li>
                  <li>Employer Identification Number</li>
                  <li>Business Address</li>
                  <li>Business Phone Number</li>
                  <li>Industry</li>
                  <li>Business Website</li>
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Email</li>
                  <li>Job Title</li>
                  <li>Date of Birth</li>
                  <li>Home Address</li>
                  <li>Phone Number</li>
                  <li>Social Security Number</li>
                  <li>Bank Account Number</li>
                  <li>Routing Number</li>
                  <li>Photo ID</li>
                </ul>
              </div>
            </div>
            <div className="mt-6 text-gray-700 dark:text-gray-400">
              Note: Please ensure you have these documents ready based on your
              account type before proceeding with the Stripe Connect flow.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeFillDetails;
