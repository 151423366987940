import React, { useState } from 'react'
import DetailEventImage from '../../components/events/detailSection/detailEventImage'
import DetailEventDescriptionIndex from '../../components/events/detailSection/detailEventDescriptionIndex'
import DetailEventDescription from '../../components/events/detailSection/detailEventDescription'
import DetailEventLocation from '../../components/events/detailSection/detailEventLocation'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import UserDetailsPopup from '../../components/popup/userDetailsPopup'

const UserEventDetails = ({ page, eventDetails, bookingParticipantInfo }) => {
    const [popup, setPopup] = useState(false)
    const formatDateandTime = (dateString) => {
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        const formattedTime = dateObject.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric'
        });

        return `${formattedDate}, ${formattedTime}`
    }

    return (
        <>
            {
                popup && <UserDetailsPopup eventId={page === 'buyTicket' ? eventDetails?._id : eventDetails?.eventId} setPopup={setPopup}/>
            }
            <div>
                <div className="card">
                    <div className="event-title-container flex justify-between items-center">
                        <h1 className="event-name mb-0 whitespace-nowrap w-full overflow-hidden overflow-ellipsis">{eventDetails ? eventDetails.eventName : 'Loading...'}</h1>
                    </div>
                </div>
                <div className="flex-container">
                    <div className="card flex-item">
                        <DetailEventImage eventImage={eventDetails && eventDetails.eventImage} altText="My image" />
                    </div>
                    <div className="card flex-item">
                        <div className="card dark:bg-[#191a1b]">
                            <DetailEventDescriptionIndex name="Ticket Price" value={eventDetails && eventDetails?.ticketPrice ? '$' + eventDetails.ticketPrice : 'Loading...'} />
                        </div>
                        {page === 'buyTicket' && eventDetails?.discountedTicketPrice && <div className="card dark:bg-[#191a1b]">
                            <DetailEventDescriptionIndex name="Discounted Price" value={eventDetails && eventDetails?.discountedTicketPrice ? '$' + eventDetails.discountedTicketPrice : 'Loading...'} />
                        </div>}
                        <div className="card dark:bg-[#191a1b]">
                            <DetailEventDescriptionIndex name="Time" value={eventDetails && eventDetails?.eventTime ? formatDateandTime(eventDetails?.eventTime) : 'Loading...'} />
                        </div>
                        <div className="card dark:bg-[#191a1b]">
                            <DetailEventDescriptionIndex name="Event Creator" value={eventDetails && eventDetails.eventCreatorInfo?.firstName ? (eventDetails?.eventCreatorInfo?.firstName + ' ' + eventDetails?.eventCreatorInfo?.lastName) : 'Loading...'} />
                        </div>
                        {page && page === "featuredEvent" && <>
                            <div className="card dark:bg-[#191a1b]">
                                <DetailEventDescriptionIndex name="Creator Email" value={eventDetails && eventDetails?.eventCreatorInfo?.email ? eventDetails?.eventCreatorInfo?.email : 'Loading...'} />
                            </div>
                            <div className="card dark:bg-[#191a1b]">
                                <DetailEventDescriptionIndex name="Creator Phone no" value={eventDetails && eventDetails?.eventCreatorInfo?.phoneNumber ? eventDetails?.eventCreatorInfo?.phoneNumber : 'Loading...'} />
                            </div>
                        </>}
                        {(page === 'buyTicket' || page === 'regularUserDetails')&& bookingParticipantInfo &&
                            <div className="card dark:bg-[#191a1b] flex justify-between">
                                <span className="description-name font-bold dark:text-white">Attendees</span>
                                <div className='flex'>
                                    {
                                        bookingParticipantInfo?.data?.map((item, index) => {
                                            return (
                                                <div className={`p-1 bg-white rounded-full cursor-pointer ${index !== 0 && 'ml-[-15px]'}`} title={item?.participantFirstName} onClick={() => setPopup(true)}>
                                                    <img className='w-8 h-8 rounded-full' src={PORT_BACKEND_URL_SRC + '/' + item?.profilePic} alt='image'/>
                                                </div>
                                            )
                                        })
                                    }
                                    {bookingParticipantInfo?.totalCount > 3 && <div className='p-1 ml-[-15px] cursor-pointer bg-white text-white rounded-full' onClick={() => setPopup(true)}>
                                        <div className='w-8 h-8 rounded-full text-xs bg-gray-300 flex justify-center items-center'>+{bookingParticipantInfo?.totalCount - 3}</div>
                                    </div>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {page && page === "featuredEvent" && eventDetails?.unFeatureReason && <>
                    <div className="card">
                        <DetailEventDescriptionIndex name="Reason to Unfeature : " value={eventDetails ? eventDetails?.unFeatureReason : 'Loading...'} />
                    </div>
                </>}
                <div className="card">
                    <DetailEventDescription description={eventDetails ? eventDetails.eventDescription : 'Loading...'} />
                </div>
                <div className="card">
                    <DetailEventLocation location={eventDetails ? eventDetails.address : 'Loading...'} />
                </div>
            </div>
        </>
    )
}

export default UserEventDetails