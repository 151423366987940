import React from 'react';

function InputField({ label, type ,placeholder,name, value, onChange, disable}) {
    return (
        <div className="card mt-5 mb-5">
            <label className='text-lg font-medium'>{label}</label>
            <input
                name={name}
                value={value}
                onChange={onChange}
                className='focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent'
                placeholder={placeholder}
                type={type}
                disabled={disable}
                required={true}
            />
        </div>
    );
}

export default InputField;
