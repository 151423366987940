import '../../../styles/sharedStyle.css';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordForgetForm from '../../../components/authBlocks/passwordForgetForm';
import ThreeDHeart from '../../../components/geometries/rotatingHeart';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');


function UserForgetsPassword() {
    const [resetEmail, setResetEmail] = React.useState('');
    const navigate = useNavigate();

    const handleForgotPassword = () => {
        if (!resetEmail) {
            alert('Please enter your email to reset your password.');
            return;
        }

        fetch(`${PORT_BACKEND_URL_SRC}/forget-password`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: resetEmail })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    // Redirect to the user-confirmation-reset-password page after a successful response
                    navigate('/user-confirmation-reset-password');
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Error during password reset:', error.message);
                alert('Error during password reset. Please try again.');
            });
    };

    return (
        <div className="full-screen">
            <div className="flex flex-wrap">
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
                    <PasswordForgetForm
                        resetEmail={resetEmail}
                        setResetEmail={setResetEmail}
                        handleForgotPassword={handleForgotPassword}
                    />
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserForgetsPassword;
