import React from 'react'
import { useNavigate } from 'react-router-dom'

const PremiumAccountPopup = ({message, setModel, model}) => {
    const navigate = useNavigate()
    const handleSubscribe = () => {
        setModel({...model, isFirstVisit: false})
        navigate('/subscription-plan')
    }
  return (
    <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-[150]">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="p-4 md:p-5">
                        <div className='text-xl'>
                            {/* If you'd like to access advanced features, please subscribe. A $2 per week fee will be applied for using these features  */}
                            {message}
                        </div>
                        <div className='flex justify-center mt-6 gap-4'>
                                <button className="animated-btn !w-fit py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center" onClick={handleSubscribe}><span className='text'>Subscribe</span></button>
                                <button className="text-white bg-red-600 py-4 hover:bg-black focus:ring-4 focus:outline-none focus:bg-black font-semibold rounded-lg text-lg px-5 py-4 text-center " onClick={() => navigate('/user-dashboard-book')}>Skip</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default PremiumAccountPopup
