import React, { useEffect, useState } from 'react'
import HamburgerMenu from '../../components/miscBlocks/hamburgerMenu';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useLocation, useNavigate } from 'react-router-dom';
import PlaceholderCardUser from './placeholder-card';
import EventCardUser from './event-card-user';
import SharePreviewPopup from '../../components/events/sharePreviewPopUp';
import ConfirmationPopup from '../../components/popup/bookingDonePopup';
import { handleSpotifyStatusCode } from '../../statuscodemanage/checkSpotifyStatus';
import { getCurrentUser } from '../../api/authAPI';
import { spotifyConnect } from '../../api/spotifyConnectApi';
import { removeLocalStorage } from '../../utils/removeLocalstorage';
import PageLoader from '../../utils/PageLoader';

const UserDashboardBook = () => {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(useLocation().search);
  const message = searchParams.get('message')
  const [createdEvents, setCreatedEvents] = useState()
  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedEventForShare, setSelectedEventForShare] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [response, setResponse] = useState({})
  const [loader, setLoader] = useState(false)
  const [isSpotifyConnected, setIsSpotifyConnected] = useState(null)
  const [eventPopup, setEventPopup] = useState({
    popup: false,
    message: ""
  })
  const [cancelLoading, setCancelLoading] = useState({
    loading: false,
    eventId: ''
  })

  const fetchUserDataAndEvents = async () => {
    setLoader(true)
    try {
      const eventsResponse = await fetch(`${PORT_BACKEND_URL_SRC}/api/creator-event-list`, {
        method: 'GET',
        headers: { Authorization: localStorage.getItem('authKey') }
      });
      const eventsData = await eventsResponse.json();
      setLoader(false)
      handleSpotifyStatusCode(eventsResponse.status, eventsData.message)
      if (eventsResponse.status === 211) {
        alert(eventsData.message)
        removeLocalStorage()
        navigate('/')
      
      }
      if (eventsResponse.status === 200) {
        if (eventsData.success) {
          setCreatedEvents(eventsData.data);
          setIsSpotifyConnected(eventsData?.user?.isSpotifyAuthCompleted)
          let users = await getCurrentUser(navigate, localStorage.getItem('authKey'), setResponse, 'dashboard')
          setCurrentUser(users.data)
          
          if (message) {
            alert(message)
          }
        } else {
          // toast.error('You do not have permission to view this page or no events found.');
        }
      }
    } catch (error) {
      console.error('Error fetching created events:', error);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleCancelbtn = async (event) => {
    setCancelLoading({ ...cancelLoading, loading: true, eventId: event.eventInfo._id })
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-cancel-by-regular-user/${event.eventInfo._id}?bookingTransactionId=${event?.bookingTransaction?._id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authKey')
      },
    })
      .then(response => response.json())
      .then(data => {
        setCancelLoading({ ...cancelLoading, loading: false })
        setEventPopup({ ...eventPopup, popup: true, message: data.message })
      })
  }

  const handleClick = async () => {
    // Show confirmation dialog with a custom message
    const userConfirmed = window.confirm(
      "Once you authorize your Spotify account with our platform, this connection will be permanent and cannot be changed later. Please ensure that you're using the correct Spotify credentials before proceeding. If you're unsure, take a moment to verify your login details to avoid issues with account access in the future."
    );
  
    // Proceed only if user confirms
    if (userConfirmed) {
      await spotifyConnect({ navigate, usedFor: 'REGULAR_USER_DASHBOARD' });
    }
  };
  
  const handleCancelClick = () => {
    fetchUserDataAndEvents()
    setEventPopup({ ...eventPopup, popup: false })
    window.location.reload()
  }

  useEffect(() => {
    if (localStorage.getItem('authKey')) {
      fetchUserDataAndEvents()
    }
    else {
      alert('You are not Authorize to access this route')
      navigate('/')
    }
  }, [])

  const handleLogoutUser = async () => {
    try {
      await fetch(`${PORT_BACKEND_URL_SRC}/logout`, {
        method: "POST",
        headers: { 'Authorization': localStorage.getItem('authKey') }
      });
      navigate("/");
      localStorage.removeItem('authKey')
      localStorage.removeItem('phoneNumber')
      localStorage.removeItem('phoneVerificationToken')
      localStorage.removeItem('emailVerificationToken')
      localStorage.removeItem('eventId')
      localStorage.removeItem('userId')
      localStorage.removeItem('flow')
    } catch (error) {
      console.error("Error logging out:", error);
      alert("Error logging out. Please try again.");
    }
  }

  const handleSettings = () => {
    localStorage.removeItem('flow')
    navigate("/user-dashboard-create-settings");
  };

  const handleDiscovery = () => {
    localStorage.removeItem('flow')
    navigate("/user-discovery")
  }

  const now = new Date().getTime();
  const upcomingEvents = createdEvents?.filter(
    (event) => new Date(event.eventInfo.eventTime) > now &&
      (event.bookingTransaction.eventParticipantInfo.status === "pre-booked" ||
        event.bookingTransaction.eventParticipantInfo.status === 'confirmed')
  );

  const pastEvents = createdEvents?.filter(
    (event) => new Date(event.eventInfo.eventTime) <= now &&
      (event.bookingTransaction.eventParticipantInfo.status === "pre-booked" ||
        event.bookingTransaction.eventParticipantInfo.status === 'confirmed')
  );


  const cancelEvents = createdEvents?.filter(
    (event) => {
      return (
        event.bookingTransaction.eventParticipantInfo.status === 'refund-failed' ||
        event.bookingTransaction.eventParticipantInfo.status === 'refund-requested' ||
        event.bookingTransaction.eventParticipantInfo.status === 'refund-completed' ||
        event.bookingTransaction.eventParticipantInfo.status === 'refund-processed' ||
        event.bookingTransaction.eventParticipantInfo.status === 'refunded');
    }
  );

  return (
    <>{!isSpotifyConnected && !loader && <div className="w-full z-50">
      <div className="bg-black text-center text-white py-2 text-sm z-50 dark:bg-gray-600">Your spotify account is incomplete. Please click the <span className='text-blue-500 hover:underline cursor-pointer' onClick={handleClick}>link</span> to enhance your experience</div>
    </div>}
      {loader ? <PageLoader/> : <div className="container mx-auto p-4">
        <div className='hamburger-menu-position'>
          <HamburgerMenu
            currentUser={currentUser}
            handleLogout={handleLogoutUser}
            handleSettings={handleSettings}
            handleDiscovery={handleDiscovery}
            setMenuVisible={setMenuVisible}
            menuVisible={menuVisible}
            isCreator={false}
          />
        </div>
        <h2 className="text-2xl font-bold mb-8 mt-12">Upcoming Events</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {upcomingEvents?.length ? (
            upcomingEvents.map((event) => (
              <EventCardUser
                key={event?._id}
                event={event.eventInfo}
                bookingTransaction={event.bookingTransaction.eventParticipantInfo}
                handleViewDetailsClick={() => navigate(`/user-dashboard-book-details/${event.eventInfo._id}?bookingId=${event.bookingTransaction._id}`)}
                handleShareClick={() => {
                  setSelectedEventForShare(event.eventInfo)
                  setShowSharePopup(true)
                }}
                handleCancelClick={() => handleCancelbtn(event)}
                cancelLoading={cancelLoading}
              />
            ))
          ) : (
            <PlaceholderCardUser />
          )}
        </div>

        <h2 className="text-2xl font-bold mb-8">Previous Events I've Attended</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {pastEvents?.length ? (
            pastEvents.map((event) => (
              <EventCardUser
                key={event._id}
                event={event.eventInfo}
                bookingTransaction={event.bookingTransaction.eventParticipantInfo}
                handleViewDetailsClick={() => navigate(`/user-dashboard-book-details/${event.eventInfo._id}?bookingId=${event.bookingTransaction._id}`)}
                cancelLoading={cancelLoading}
              />
            ))
          ) : (
            <PlaceholderCardUser />
          )}
        </div>

        <h2 className="text-2xl font-bold mb-8 mt-12">Cancelled Events</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          {cancelEvents?.length ? (
            cancelEvents.map((event) => (
              <EventCardUser
                key={event._id}
                event={event.eventInfo}
                bookingTransaction={event?.bookingTransaction?.eventParticipantInfo}
                handleViewDetailsClick={() => navigate(`/user-dashboard-book-details/${event.eventInfo._id}?bookingId=${event.bookingTransaction._id}`)}
                handleShareClick={() => {
                  setSelectedEventForShare(event.eventInfo)
                  setShowSharePopup(true)
                }}
                cancelLoading={cancelLoading}
                handleCancelClick={handleCancelbtn}
              />
            ))
          ) : (
            <PlaceholderCardUser />
          )}
        </div>

        {showSharePopup && selectedEventForShare && (
          <div className='share-preview-overlay'>
            <div className='share-preview-content '>
              <SharePreviewPopup
                event={selectedEventForShare}
                onClose={() => setShowSharePopup(false)}
              />
            </div>
          </div>
        )}
        {
          eventPopup.popup && <ConfirmationPopup message={eventPopup.message} handleDone={handleCancelClick} btnText={"Done"} />
        }
      </div>}
    </>
  )
}

export default UserDashboardBook
