// ConnectToSpotify.js
import React from 'react';

function ConnectToSpotify({ handleSpotifyLogin, handleSkip }) {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-[#191a1b] px-4">
            <div className="w-full max-w-md p-8 space-y-8 bg-white dark:bg-black shadow-lg rounded-xl">
                {/* Image inclusion */}
                <img
                    src="/images/spotify_logo.png"  // Adjust the path as necessary
                    alt="Spotify Logo"
                    className="mx-auto h-20"  // Set the height, adjust as necessary
                />
                <div className="text-center">
                    <h2 className="text-2xl font-bold">Spotify Login</h2>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 text-left">
                    You will be redirected to Spotify Login to gather important information regarding your music preferences as part of the booking experience. If you are already logged in on this device, you will be automatically redirected back to continue the booking process. Please note that once you authorize your Spotify account with our platform, this connection will be permanent and cannot be changed later. Ensure you're using the correct Spotify credentials before proceeding to avoid any future issues.
                    </p>
                    <p className="mt-5 text-sm font-bold text-gray-600 dark:text-gray-400 text-left">Note : For the best experience and access to exclusive features, we highly recommend upgrading to a Premium Account for advanced tools, priority support, and much more. Take your experience to the next level today!</p>
                </div>
                <div className='flex gap-5'>
                    <button
                        onClick={handleSpotifyLogin}
                        className="w-full py-3 animated-btn mt-4 bg-black font-bold text-white rounded-md"

                    >
                        <span className='text'>Connect</span>
                    </button>
                    <button
                        onClick={handleSkip}
                        className="w-full py-3 mt-4 bg-red-600 font-bold text-white rounded-md"

                    >
                        Skip
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConnectToSpotify;
