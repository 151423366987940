import React from 'react';
import '../../../styles/detailIntro.css';

const DetailEventDescription = ({ description }) => {
  return (
    <div className="event-description-event">
      <p className='whitespace-nowrap w-full overflow-hidden overflow-ellipsis'>{description}</p>
    </div>
  );
};

export default DetailEventDescription;
