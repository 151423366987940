import React from 'react';

function ThankYouFeedback({ redirectToHome }) {
    return (
        <div className='w-11/12 max-w-[700px] px-10 dark:border-gray-800 py-20 rounded-3xl bg-white border-2 border-gray-100 dark:!bg-black'>
            <h1 className='text-5xl font-semibold'>Thank You</h1>
            <p className='font-medium text-lg text-gray-500 mt-4'>
                Thanks for your feedback, we will get back to you soon as possible.
            </p>
            <div className='mt-8'>
                <button onClick={redirectToHome} className='font-medium text-base text-violet-500 cursor-pointer'>Back to home</button>
            </div>
        </div>
    );
}

export default ThankYouFeedback;
