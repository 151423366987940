import { handleSpotifyStatusCode } from '../statuscodemanage/checkSpotifyStatus';
import { removeLocalStorage } from '../utils/removeLocalstorage';

const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const loginUser = async (email, password, rememberMe, navigate, eventPopup, setEventPopup) => {
    try {
        const eventId = localStorage.getItem('eventId');
        const loginUrl = eventId 
            ? `${PORT_BACKEND_URL_SRC}/login?usedFor=SPOTIFY_AUTH_CHECK` 
            : `${PORT_BACKEND_URL_SRC}/login`;

        const response = await fetch(loginUrl, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password, rememberMe })
        });
        
        return response;
    } catch (error) {
        console.error('Error during login:', error);
        throw error;
    }
}


export const getCurrentUser = async (navigate, authKey, setResponse, dashboard) => {
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/get-user-profile?usedFor=${dashboard && dashboard}`, {
        method: 'GET',
        //credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': authKey
        }
    });
    const data = await response.json();
    setResponse({ response: response, data: data })
    handleSpotifyStatusCode(response.status, data.message)
    if (response.status === 211 && navigate) {
        alert(data?.message)
        removeLocalStorage()
        navigate('/')

    }
    if (!data.success) throw new Error(data.message);
    return data;
}

export const checkAuthentication = async (navigate) => {
    try {
        const userResponse = await fetch(`${PORT_BACKEND_URL_SRC}/check-auth`, {
            method: 'GET',
            credentials: 'include',
        });
        const userData = await userResponse.json();

        if (userData.isAuthenticated && userData.userId) {
        } else {
            navigate('/');
            return;
        }
    } catch (error) {
        console.error('Error fetching current user:', error);
        return;
    }
}