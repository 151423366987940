import React, { useEffect, useState } from 'react'
import DetailEventBackButton from '../../components/events/detailSection/detailBackButton'
// import { useFetchEventDetails } from '../user/dashboardCreate/user-dashboard-event-edit-utility';
import UserEventDetails from './user-event-details';
import QRCode from "react-qr-code";
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useLocation, useParams } from 'react-router-dom';
import UserPaymentDetails from './user-payment-details';
import { handleSpotifyStatusCode } from '../../statuscodemanage/checkSpotifyStatus';
import PageLoader from '../../utils/PageLoader';

const EventRegularUserDetails = () => {
    const [eventData, setEventData] = useState({});
    const [loading, setLoading] = useState(true)
    const { eventId } = useParams();
    const [bookingDetails, setBookingDetails] = useState({})
    const searchParams = new URLSearchParams(useLocation().search);
    const bookingId = searchParams.get('bookingId')

    useEffect(() => {
        async function fetchEventDetails() {
            try {
                const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event/${eventId}?bookingTransactionId=${bookingId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': localStorage.getItem('authKey')
                    }
                });
                const data = await response.json();
                setLoading(false)
                handleSpotifyStatusCode(response.status, data.message)
                if (response.ok) {
                    setEventData(data?.data[0]);
                    setBookingDetails(data?.bookingParticipantInfo)
                } else {
                    alert(data.message)
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        }
        fetchEventDetails();
    }, [])

    const passingData = {
        participantId: eventData?.participantId,
        eventId: eventData?.eventId,
        bookingTransactionId: eventData?.bookingTransactionDetails?._id
    }

    return (
        <>{loading ? <PageLoader /> :
            <div className='basic-container'>
                <div className="button-wrapper"><DetailEventBackButton /></div>
                <UserEventDetails eventDetails={eventData} page={'regularUserDetails'} bookingParticipantInfo={bookingDetails} />
                <UserPaymentDetails bookingTransactionDetails={eventData.bookingTransactionDetails} />
                <div
                    style={{
                        height: 'auto',
                        maxWidth: '180px',
                        width: '100%',
                        marginTop: "50px",
                    }}
                    className='bg-white p-1'
                >
                    <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={JSON.stringify(passingData)}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>}</>
    )
}

export default EventRegularUserDetails
