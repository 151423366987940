// feedbackApi.js

// Define the API endpoint for submitting feedback
const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

// Function to submit feedback
export async function submitFeedback(email, feedbackText) {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/submit-feedback`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                feedbackText,
            }),
        });

        if (response.ok) {
            return { success: true };
        } else {
            const errorData = await response.json();
            return { success: false, message: errorData.error };
        }
    } catch (error) {
        console.error('Error sending feedback:', error);
        return { success: false, message: 'An error occurred while sending feedback.' };
    }
}
