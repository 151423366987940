import React, { useState } from 'react';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useNavigate } from 'react-router-dom';

function ChatHamburgerMenu({ currentUser, handleLogout, handleAnalytics, handleFreeChat, handleSettings, handleDiscovery, handleNewsletter, setMenuVisible, menuVisible, isCreator }) {
    const url = window.location.pathname
    // const menuIconStyle = {
    //     fontSize: '40px',
    //     cursor: 'pointer',
    //     color: 'black',
    // };

    const [hoverIndex, setHoverIndex] = useState(null);
    const navigate = useNavigate()

    const menuStyle = {
        width: '200px',
        padding: '1rem',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        margin: '0 0 20px 0',
        transition: 'box-shadow 0.3s ease-in-out',
    };

    const menuItemStyle = (isHovered, index) => ({
        color: isHovered ? 'white' : 'black',
        fontWeight: 'bold',
        padding: '12px 20px',
        display: 'block',
        margin: '0px 0',
        borderRadius: '8px',
        backgroundColor: isHovered ? '#d3d3d3' : 'transparent',
        cursor: 'pointer',
        transition: 'all 0.05s ease',
        textAlign: 'center',
        border: isHovered ? '2px solid black' : '0px solid transparent',
        boxShadow: hoverIndex === index ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
    });

    return (
        <div className={`absolute w-full md:w-3/4 right-4 md:right-auto flex justify-end ${url === '/user-free-chat' ? 'top-20 md:top-14' : 'top-5'}`}>
            <div className="relative text-violet-500">
                <img className='rounded-full cursor-pointer' height={60} width={60} onClick={() => setMenuVisible(!menuVisible)} src={currentUser?.userProfilePic ? PORT_BACKEND_URL_SRC + '/' + currentUser?.userProfilePic : '/images/default_user_image.jpg'} alt="Profile Pic" />
                {menuVisible && (
                    <div className="absolute top-full right-0 mt-2 rounded-md shadow-lg" style={menuStyle}>
                        {isCreator && <>
                            <div
                                onClick={handleAnalytics}
                                onMouseEnter={() => setHoverIndex(1)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 1, 1)}
                            >
                                Engagement
                            </div>

                            <div
                                onClick={handleNewsletter}
                                onMouseEnter={() => setHoverIndex(2)}
                                onMouseLeave={() => setHoverIndex(null)}
                                style={menuItemStyle(hoverIndex === 2, 2)}
                            >
                                Newsletter
                            </div>
                        </>}
                        <div
                            onClick={() => {
                                if (isCreator) {
                                    navigate('/user-dashboard-create')
                                }
                                else {
                                    navigate('/user-dashboard-book')
                                }
                            }}
                            onMouseEnter={() => setHoverIndex(3)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 3, 3)}
                        >
                            Dashboard
                        </div>
                        <div
                            onClick={handleSettings}
                            onMouseEnter={() => setHoverIndex(4)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 4, 4)}
                        >
                            Settings
                        </div>
                        <div
                            onClick={handleLogout}
                            onMouseEnter={() => setHoverIndex(0)}
                            onMouseLeave={() => setHoverIndex(null)}
                            style={menuItemStyle(hoverIndex === 0, 0)}
                        >
                            Logout
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ChatHamburgerMenu;
