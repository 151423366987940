import "../styles/sharedStyle.css";
import React, { useState } from "react";
import { loginUser } from "../api/authAPI";
import LoginSection from "../components/authBlocks/loginSection";
// you can switch between video in the background or avatar
import LoginVideoIntro from "../components/media/loginVideoIntro";
import { useNavigate } from "react-router-dom";
import ConfirmationPopup from "../components/popup/bookingDonePopup";
// const { PORT_BACKEND_URL_SRC } = require("../srcLocalPortReferenceBackend");

//test

function Home({ themeMode }) {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [loading, setLoading] = useState(false)
  const [eventPopup, setEventPopup] = useState({
    popup: false,
    message: "",
    navigateUrl: ''
  })

  const handleLogin = async () => {
    setLoading(true)
    localStorage.setItem('rememberMe', rememberMe);
    try {
      const response = await loginUser(email, password, rememberMe, navigate, eventPopup, setEventPopup);
      const data = await response.json();
      if (response.status !== 200 && response.status !== 222 && response.status !== 233 && response.status !== 244 && response.status !== 255 && response.status !== 223) {
        // setEventPopup({...eventPopup, popup: true, message:data.message, navigateUrl: '/'})
        alert(data.message)
        setLoading(false)
      }
      if (data) {
        localStorage.setItem('authKey', data?.data?.token);
        localStorage.setItem('phoneNumber', data?.data?.phoneNumber);
        localStorage.setItem('phoneVerificationToken', data?.data?.phoneVerificationToken);
        localStorage.setItem('emailVerificationToken', data?.data?.emailVerificationToken);
        localStorage.setItem('userId', data?.data?.userId)
      }
      switch (response.status) {
        case 200:
          if (!data.success) {
            // throw new Error('Login failed. Please check your credentials.');
            alert(data.message)
          }
          else {
            setLoading(false)
            navigate(`/verify-login`);
          }
          return data;
        case 222:
          setEventPopup({ ...eventPopup, popup: true, message: data.message, navigateUrl: `/verify-sms/${data.data.phoneVerificationToken}` })
          break;
        case 233:
          setEventPopup({ ...eventPopup, popup: true, message: data.message, navigateUrl: `/verify-email/${data.data.emailVerificationToken}` })
          break;
        case 212:
          setEventPopup({ ...eventPopup, popup: true, message: data.message, navigateUrl: `/user-add-card?userId=${data?.data?.userId}` })
          break;
        case 244:
          const userConfirmed = window.confirm(data.message);
        
          // Proceed only if user confirms
          if (userConfirmed) {
            navigate('/user-spotify-login')
          }
          else{
            navigate('/user-buy-ticket')
          }
          break;
        case 223:
          alert(data.message)
          if (data.data.role === 'creator') {
            navigate('/user-free-chat')
          }
          else if (data.data.role === 'admin') {
            navigate('/get-all-referral-code')
          }
          else {
            if (localStorage.getItem('eventId')) {
              navigate(`/user-chat-experience/${localStorage.getItem('eventId')}`)
            }
            else {
              navigate('/user-discovery')
            }
          }
          break;
        default:
          if (!response.ok) {
            // The request was not successful
            console.error('Login request failed:', response.status, response.statusText);
            throw new Error(`Login failed: ${response.statusText}`);
          }
          break;
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleDone = () => {
    setEventPopup({ ...eventPopup, popup: false })
    setLoading(false)
    navigate(eventPopup.navigateUrl)
  }

  return (
    <div className="flex flex-col lg:flex-row full-screen">
      <LoginSection
        email={email}
        setEmail={setEmail}
        password={password}
        loading={loading}
        setPassword={setPassword}
        rememberMe={rememberMe}
        setRememberMe={setRememberMe}
        handleLogin={handleLogin}
        themeMode={themeMode}
      />

      <LoginVideoIntro />
      {/* <RightAnimate /> */}
      {/* <div className="lg:w-2/3">
        <RightSectionModel />
      </div> */}
      {
        eventPopup.popup && <ConfirmationPopup message={eventPopup.message} handleDone={handleDone} btnText={"Finish Set Up"} />
      }
      {/* <AvatarIntroBoy /> */}
      {/* <div className="custom-flex-container-2-3">
                    <ThreeDHeart />
                </div> */}
    </div>
  );
}
export default Home;
