import '../../../styles/sharedStyle.css';
import { PhoneNumberUtil } from 'google-libphonenumber';
import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ThreeDHeart from '../../../components/geometries/rotatingHeart';
import SignUpForm from '../../../components/authBlocks/signUpForm';
import RetriveAccountPopup from '../../../components/popup/retriveAccountPopup';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserSignUp() {
    const location = useLocation();

    // const phoneUtil = PhoneNumberUtil.getInstance();

    // const isPhoneValid = (phone) => {
    // try {
    //     const phoneNumber = phoneUtil.parseAndKeepRawInput(phone);
    //     return phoneUtil.isValidNumber(phoneNumber);
    // } catch (error) {
    //     return false;
    // }
    // };

    const isBookingFlow = location.state && location.state.isRegularUser;
    const userRole = isBookingFlow ? 'regular' : 'creator';
    const [retriveAccountPopup, setRetriveAccountPopup] = React.useState({
        openPopUp: false,
        message: '',
        accountDetailPopUp: false
    })
    const [role, setRole] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [dob, setDob] = React.useState(''); // Date of Birth
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [name, setName] = React.useState(''); // New field
    const [identifyHow, setIdentifyHow] = React.useState(''); // New field
    const [isLoading, setIsLoading] = React.useState(false);
    const [profilePic, setProfilePic] = React.useState(null);
    const [referralCode, setReferralCode] = React.useState('');
    const [referralCodeId, setReferralCodeId] = React.useState('')
    const [coordinates, setCoordinates] = React.useState({
        longitude: '',
        latitude: '',
        placeId: '',
        label:''
    })
    const [instagram, setInstagram] = React.useState('')
    const [socialMediaValid, setSocialMediaValid] = React.useState({
        instagram: '',
        twitter: ''
    })
    const [twitter, setTwitter] = React.useState('')
    // New state for country code
    const [countryCode, setCountryCode] = React.useState('');
    const navigate = useNavigate();


    // Log eventId and redirectTo on component mount and whenever location.state changes
    useEffect(() => {
        const eventId = localStorage.getItem('eventId'); // Fetching eventId from localStorage
        // const redirectTo = localStorage.getItem('redirectTo'); // Fetching redirectTo from localStorage
    }, [location]);

    const handleImageDrop = (file) => {
        setProfilePic(file)
    }

    const HandleSignup = () => {
        setIsLoading(true);
    
        // Create a new FormData object
        let formData = new FormData();
        
        // Append key-value pairs to the FormData object
        formData.append('email', email);
        formData.append('password', password);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('dob', dob);
        formData.append('phoneNumber', "+" + phoneNumber);
        formData.append('identifyHow', identifyHow);
        formData.append('role', userRole);
        formData.append('defaultAvatarModel', location.state.character);
        formData.append('profilePic', profilePic );
        if(referralCodeId){
            formData.append('signupReferralCodeId', referralCodeId );
        }
        if (!isBookingFlow) {
            formData.append('name', name);
        }

        if(isBookingFlow){
            formData.append('instagramProfile', 'https://www.instagram.com/' + instagram);
            formData.append('twitterProfile', 'https://x.com/' + twitter);
                formData.append('location', JSON.stringify({
                        type: "Point",
                        coordinates: [coordinates?.longitude, coordinates?.latitude]  // [longitude, latitude]
                }));
                formData.append('placeId', coordinates?.placeId);
                formData.append('address', coordinates?.label);
        }
    
        // Send the FormData object in the fetch request
        fetch(`${PORT_BACKEND_URL_SRC}/signup`, {
            method: 'POST',
            body: formData  // Use formData instead of JSON.stringify(requestBody)
        })
        .then(response => {
            if (response.status !== 288 && response.status !== 200) {
                response.json().then(data => {
                    alert(data.message); // Assuming the error message is returned in the response JSON
                });
            } else if (response.status === 288) {
                response.json().then(data => {
                    setRetriveAccountPopup({
                        ...retriveAccountPopup,
                        openPopUp: true,
                        message: data.message
                    });
                });
            } else if (response.status === 200) {
                return response.json(); // Continue the promise chain
            }
        })
        .then(data => {
            if (data?.success) {
                // Store the phone number and phoneVerificationToken in local storage
                localStorage.setItem('phoneNumber', phoneNumber);
                localStorage.setItem('phoneVerificationToken', data.phoneVerificationToken);
                localStorage.setItem('emailVerificationToken', data.emailVerificationToken);
        
                // Navigate to the SMS verification page with the token
                navigate(`/verify-sms/${data.phoneVerificationToken}`, {
                    state: { redirectTo: location?.state?.redirectTo, eventId: location?.state?.eventId }
                });
            } else {
                // Handle other scenarios if needed
            }
        })
        .catch(error => {
            console.error('Error during registration:', error.message);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <div className="full-screen">
            <div className="flex flex-wrap">
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b] md:justify-start md:overflow-y-auto md:h-screen">
                    <SignUpForm
                        userRole={userRole}
                        isBookingFlow={isBookingFlow}
                        role={role} setRole={setRole}
                        email={email} setEmail={setEmail}
                        password={password} setPassword={setPassword}
                        firstName={firstName} setFirstName={setFirstName}
                        lastName={lastName} setLastName={setLastName}
                        dob={dob} setDob={setDob}
                        referralCode={referralCode} setReferralCode={setReferralCode}
                        phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                        countryCode={countryCode} setCountryCode={setCountryCode} // New props for country code
                        Name={name} setName={setName}
                        identifyHow={identifyHow} setIdentifyHow={setIdentifyHow}
                        HandleSignup={HandleSignup}
                        isLoading={isLoading}
                        handleImageDrop={handleImageDrop}
                        setReferralCodeId={setReferralCodeId}
                        setCoordinates={setCoordinates}
                        instagram={instagram}
                        setInstagram={setInstagram}
                        twitter={twitter}
                        setTwitter={setTwitter}
                        socialMediaValid={socialMediaValid}
                        setSocialMediaValid={setSocialMediaValid}
                    />
                    {
                        retriveAccountPopup.openPopUp && 
                        <RetriveAccountPopup email={email} retriveAccountPopup={retriveAccountPopup} setRetriveAccountPopup={setRetriveAccountPopup}/>
                    }
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserSignUp;
