import React, { useState, useEffect } from 'react'
import ThreeDHeart from '../../../components/geometries/rotatingHeart';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import { useNavigate, useLocation } from "react-router-dom"
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import StripeFillDetails from '../../../components/popup/StripeFillDetailsPopup';

const UserPaymentIntegration = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [popup, setPopup] = useState(false)
    const [message, setMessage] = useState('')
    const [userId, setUserId] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const stripeConnect = async () => {
        setLoading(true)
        fetch(`${PORT_BACKEND_URL_SRC}/stripe/connect?platform=web_app&userId=${userId}&email=${email}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            //credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to Connect with Stripe');
                }
                return response.json();
            })
            .then(data => {
                setLoading(false)
                window.location.href = data.accountLink.url; // Use window.location to force a page refresh
            })
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setMessage(searchParams.get('message'))
        setUserId(searchParams.get('userId'))
        setEmail(searchParams.get('email'))
    }, [location]);
    return (
        <>
            {
                popup && <StripeFillDetails setPopup={setPopup}/>
            }
            <div className="full-screen">
                <div className="flex flex-wrap">
                    <div className="custom-flex-container-1-3 dark:bg-[#191a1b] md:px-10">
                        <div className="w-11/12 max-w-[700px] px-10 py-20 dark:bg-[#191a1b] rounded-3xl bg-white border-0 border-white">
                            <div className="card">
                                <DetailEventTitle eventName={"Payment"} />
                            </div>
                            {message && <div className="card">
                                <div className='text-xl font-semibold'>{message}</div>
                            </div>}
                            <div className="card">
                                <div className='text-md'>Note : Your Stripe account will be linked to handle all financial transactions on our platform. Once connected, you will not be able to switch or modify this Stripe account. Make sure to double-check your Stripe credentials before continuing to ensure the correct account is used for payments and payouts.</div>
                                <div>Additionally, you will need to provide the <span className='text-blue-600 cursor-pointer' onClick={() => setPopup(true)}>these documents</span> based on your account type</div>
                            </div>
                            <div className='mt-12 flex flex-col '>
                                <button className="createform-button bg-[#686D76]" onClick={stripeConnect} style={{ zIndex: 9999 }}>
                                    {loading ? 'Connecting...' : 'Connect With Stripe'}
                                </button>
                                <button className="deleteform-button" style={{ zIndex: 9999 }} onClick={() => navigate('/')}>
                                    Sign Out
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="custom-flex-container-2-3 overflow-x-hidden">
                        <ThreeDHeart />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserPaymentIntegration
