// PasswordForgetForm.js
import React from 'react';
import DetailBackButton from '../events/detailSection/detailBackButton';
import DetailEventTitle from '../events/detailSection/detailEventTitle';
import DetailEventDescription from '../events/detailSection/detailEventDescription';

function PasswordForgetForm({ resetEmail, setResetEmail, handleForgotPassword }) {
    return (
        <div className='w-11/12 dark:bg-[#191a1b] max-w-[700px] mx-auto px-10 py-20 rounded-3xl bg-white border-0 border-white '>
            <div className="button-container">
                <div className="button-wrapper"><DetailBackButton /></div>
            </div>

            <div className="card">
                <DetailEventTitle eventName={"Forgot Password?"} />
            </div>

            <div className="card">
                <DetailEventDescription description={"We got you. Enter your email address below to reset your password."} />
            </div>

            <div className="card">
                <div className='flex flex-col'>
                    <input
                        value={resetEmail}
                        onChange={(e) => setResetEmail(e.target.value)}
                        type="email"
                        className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        placeholder="Enter your email"
                    />
                </div>
            </div>
            <div className='mt-8 flex flex-col gap-y-4'>
                <button
                    onClick={handleForgotPassword}
                    className='py-4 animated-btn rounded-xl font-bold text-lg'>
                    <span className='text'>Send Reset Email</span>
                </button>
            </div>
        </div>
    );
}

export default PasswordForgetForm;
