import React from 'react';
import '../../../styles/detailIntro.css';
// import BufferToBase64 from '../../../utils/bufferToBase64';
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';

const DetailEventImage = ({ eventImage, altText }) => {

    return (
        <div className="event-image-container">
            {eventImage ? <img src={PORT_BACKEND_URL_SRC + '/' + eventImage} alt={altText} className="event-image" /> : <p>No image available</p>}
        </div>
    );
};

export default DetailEventImage;