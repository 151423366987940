import React, { useEffect, useState } from 'react'
import UserPaymentDetails from './user-payment-details'
import UserPersonalDetails from './user-personal-details'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import { useLocation } from 'react-router-dom'
import PageLoader from '../../utils/PageLoader'

const UserDetailsScan = () => {
    const [userDetails, setUserDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const searchParams = new URLSearchParams(useLocation().search);
    const participantId = searchParams.get('participantId')
    const eventId = searchParams.get('eventId')
    const bookingTransactionId = searchParams.get('bookingTransactionId')
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
              const usersResponse = await fetch(`${PORT_BACKEND_URL_SRC}/get-participant-details?participantId=${participantId}&eventId=${eventId}&bookingTransactionId=${bookingTransactionId}`, {
                method: 'GET',
                headers: { Authorization: localStorage.getItem('authKey') }
              });
              const usersdata = await usersResponse.json();
              setLoading(false)
              if (usersResponse.status === 200) {
                if (usersdata.success) {
                    setUserDetails(usersdata.data[0]);
                } else {
                  alert(usersdata.message)
                }
              }
              else{
                alert(usersdata.message)
              }
            } catch (error) {
              console.error('Error fetching user details:', error);
            } finally {
            }
          };
          fetchUserDetails()
    }, [])
  return (
    <>{loading ? <PageLoader/> :
    <div className='basic-container'>
        <UserPersonalDetails userPersonalDetails={userDetails.participantInfo}/>
        <UserPaymentDetails bookingTransactionDetails={userDetails.bookingTransactionDetails}/>
    </div>}</>
  )
}

export default UserDetailsScan