import React from 'react';
import InputField from '../miscBlocks/inputField'; // Adjust the import path as needed

function VerificationSMS({ verificationCode, setVerificationCode, handleVerification, handleResendSMS, isLoading }) {

    return (
        <div className="m-auto flex dark:bg-black flex-col items-center justify-center w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white">
            <h2 className="text-2xl font-medium mb-4">SMS Verification</h2>
            <InputField
                label="Verification Code"
                type="text"
                placeholder="Enter 6-digit code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
            <div className="mt-8 flex flex-col gap-y-4">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <button
                        onClick={() => handleVerification()}
                        className='animated-btn'
                    >
                        <span className='text'>Verify</span>
                    </button>
                )}
            </div>
            <div className="mt-8 flex justify-center items-center">
                <p className="font-medium text-base">Didn't receive a code?</p>
                <button onClick={handleResendSMS} className="ml-2 font-medium text-base text-[#4535C1]">
                    Resend SMS
                </button>
            </div>
        </div>
    );
}

export default VerificationSMS;
