import React from 'react';
import LoadingData from '../../../components/loading/Loading';

function UserDashboardPopup({ onClose, loading, onDelete }) {
    return (
        <div id="popup-modal" tabIndex="-1" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}} className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" onClick={onClose}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-6 md:p-8 text-center">
                        <svg className="mx-auto mb-6 text-gray-400 w-16 h-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h3 className="mb-6 text-2xl font-normal text-gray-500">Are you sure you want to delete?</h3>
                        <div className='flex justify-center gap-4'>
                            <button onClick={onDelete} className='back-button  !bg-[#686D76]'>
                                {loading ?  <LoadingData/> : "Yes, I'm sure"}
                            </button>
                            <button onClick={onClose} className='deleteform-button'>No, cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserDashboardPopup;
