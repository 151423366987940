import React from 'react'

const YoutubeChatPreview = ({ link }) => {
    const embedUrl = link.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');

    return (

        <iframe
            className="youtube-iframe"
            title="Youtube Content"
            src={embedUrl}
            width="300"
            height="140"
            style={{borderRadius: "10px"}}
            allowTransparency="true"
            allow="encrypted-media">
        </iframe>

    );
}

export default YoutubeChatPreview
