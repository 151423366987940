import React from 'react';
import '../../../styles/detailIntro.css';

const DetailEventTime = ({ eventTime }) => {
  return (
    <div className="event-description">
      <p>{new Date(eventTime).toLocaleString()}</p>
    </div>
  );
};

export default DetailEventTime;
