import React from 'react';
import { Link } from 'react-router-dom';

function UserConfirmationMessageSignUp() {
    return (
        <div className='w-11/12 max-w-[700px] dark:bg-black dark:border-gray-800 px-10 py-20 rounded-3xl bg-white border-2 border-gray-100'>
            <h1 className='text-5xl font-semibold'>Thank You</h1>
            <p className='font-medium text-lg text-gray-500 mt-4'>
                You should have received an introductory email that tells you a little bit more about this platform. We appreciate having you with us.
            </p>
            <div className='mt-8'>
                <Link to="/user-login" className='font-medium text-base text-violet-500'>Sign in</Link>
            </div>
        </div>
    );
}

export default UserConfirmationMessageSignUp;
