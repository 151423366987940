import React, { useEffect, useState } from 'react'
import DetailEventBackButton from '../components/events/detailSection/detailBackButton'
import InputField from '../components/miscBlocks/inputField'
import DetailEventTitle from '../components/events/detailSection/detailEventTitle'
import { useParams } from 'react-router-dom'
import { checkReferralCode, getSingleReferralCode } from '../api/referralCodeAPI'
import "../styles/detail.css"
import ConfirmAdminCodePopup from '../components/popup/confirmAdminCodePopup'
import GenerateCodePopup from '../components/popup/generateCodePopup'

const AddEditReferralCode = () => {
    const params = useParams()
    const [referralData, setReferralData] = useState({
        referralCode: '',
        limit: null,
        expiryDate: '',
        status: 'Active'
    })
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [valid, setValid] = useState({
        valid: null,
        text: ''
    })
    const [generatePopup, setGeneratePopup] = useState(false)
    const [codeData, setCodeData] = useState({
        code: '',
        action: ''
    })

    useEffect(() => {
        if (codeData.action === 'accept') {
            setReferralData({ ...referralData, referralCode: codeData.code })
            handleBlur(codeData.code)
        }
        setCodeData({ ...codeData, action: '' })
    }, [codeData.action])

    useEffect(() => {
        if (params?.id) {
            const getReferralData = async () => {
                const data = await getSingleReferralCode(params?.id)
                setReferralData(data?.data?.signupReferralCodeList[0])
            }
            getReferralData()
        }
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const handleSubmit = async (e) => {
        setConfirmPopup(true)
        e.preventDefault()
    }

    const handleCardChange = (e) => {
        const selectedValue = e.target.value;
        setReferralData({ ...referralData, status: selectedValue })
    }

    const handleBlur = async (val) => {
        if (val !== '') {
            setValid({ ...valid, text: 'Loading...' })
            const newData = await checkReferralCode(val, 'ADMIN_PANEL')
            setValid({ ...valid, text: newData?.message, valid: newData?.success })
        }
        else {
            setValid({ ...valid, text: '', valid: false })
        }
    };

    const now = new Date();

    const formattedDate = now.getFullYear() + '-' +
        ('0' + (now.getMonth() + 1)).slice(-2) + '-' +
        ('0' + now.getDate()).slice(-2) + 'T' +
        ('0' + now.getHours()).slice(-2) + ':' +
        ('0' + now.getMinutes()).slice(-2);

    return (
        <>
            <div className='basic-container'>
                <div className="button-container">
                    <div className="button-wrapper"><DetailEventBackButton /></div>
                </div>
                <form className="event-create-form-container" onSubmit={handleSubmit}>
                    <div className="section">
                        <div className="card">
                            <DetailEventTitle eventName={params.id ? "Edit Referral Details" : "Add Referral Details"} />
                        </div>
                        {params?.id ? <InputField
                            label="Referral Code"
                            id="referralcode"
                            name="referralcode"
                            type="text"
                            value={referralData?.referralCode}
                            disable={true}
                        /> :
                            <div className="card mt-5 mb-5">
                                <label className='text-lg font-medium flex justify-between'>
                                    Referral Code
                                    <div onClick={() => setGeneratePopup(true)} className='ml-4 font-semibold text-[#4535C1] text-md cursor-pointer underline'>Auto Generate Code</div>
                                </label>
                                <input
                                    name='referralcode'
                                    className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                    placeholder="Enter referral code"
                                    type="text"
                                    minLength={8}
                                    maxLength={8}
                                    onBlur={(e) => handleBlur(e.target.value)}
                                    value={referralData?.referralCode}
                                    onChange={(e) => setReferralData({ ...referralData, referralCode: e.target.value })}
                                    required
                                />
                                {valid?.text && valid?.text != 'Loading...' && <div className={`text-sm ml-4 mt-3 ${valid?.valid ? 'text-green-600' : 'text-red-600'}`}> {valid?.valid ? "✅" : "❌"}  {valid?.text}</div>}
                                {valid?.text == 'Loading...' && <div className='text-sm ml-4 mt-3'>Loading...</div>}
                            </div>
                        }
                        {/* Event Description */}
                        <div className="card mt-5 mb-5">
                            <label className='text-lg font-medium'>Expiry</label>
                            <input
                                name='expiry'
                                className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                type="datetime-local"
                                value={formatDate(referralData?.expiryDate)}
                                onChange={(e) => setReferralData({ ...referralData, expiryDate: e.target.value })}
                                required
                                min={formattedDate}
                            />
                        </div>
                        {/* Event Location */}
                        <InputField
                            label="Limit"
                            id="limit"
                            name="limit"
                            type="number"
                            onChange={(e) => setReferralData({ ...referralData, limit: e.target.value })}
                            value={referralData?.limit}
                            placeholder={"Enter limit"}
                        />
                        <div className="card mt-5 mb-5">
                            <label className='text-lg font-medium'>Status</label><br />
                            <select
                                className="shadow border-2 rounded-xl bg-transparent dark:text-gray-400 dark:border-gray-800 rounded p-4 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                onChange={handleCardChange}
                                value={referralData?.status}   // Reset size on mouse out
                            >
                                <option className="capitalize py-3" value="Active">Active</option>
                                <option className="capitalize py-3" value="Inactive">Inactive</option>
                                {/* Add more options as needed */}
                            </select>

                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="animated-btn !w-fit"
                        >
                            <span className='text'>{params?.id ? "Save" : "Create"}</span>
                        </button>
                    </div>
                </form>
            </div>
            {
                confirmPopup && <ConfirmAdminCodePopup page={params?.id ? 'edit' : 'add'} setConfirmPopup={setConfirmPopup} referralData={referralData} />
            }
            {
                generatePopup && <GenerateCodePopup setGeneratePopup={setGeneratePopup} setCodeData={setCodeData} codeData={codeData} />
            }
        </>
    )
}

export default AddEditReferralCode
