import { PORT_BACKEND_URL_SRC } from "../srcLocalPortReferenceBackend";

export const verifyPhoneNumbers = async (verificationCode, phoneNumber) => {
    const isPlus = phoneNumber.includes('+')
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/verify-sms/${localStorage.getItem("phoneVerificationToken")}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('authKey')
            },
            body: JSON.stringify({
                code: verificationCode,
                phoneNumber : isPlus ? phoneNumber : '+' + phoneNumber
            })
        });
        return response
    } catch (error) {
        alert(error.message);
    }
};