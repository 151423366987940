import React, { useState } from 'react';
import { submitFeedback } from '../../api/feedbackAPI';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function FeedbackForm() {
    const [email, setEmail] = useState('');
    const [feedbackText, setFeedbackText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate(); // Get the useNavigate function



    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await submitFeedback(email, feedbackText);

            if (response.success) {
                alert('Feedback submitted successfully!');
                setEmail('');
                setFeedbackText('');
                // Redirect to the thank you page using navigate
                navigate('/user-thank-you-feedback');
            } else {
                alert(`Error: ${response.message}`);
            }
        } catch (error) {
            console.error('Error sending feedback:', error);
            alert('An error occurred while sending feedback.');
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className=" flex flex-col">
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col'>
                    <div className="card">
                        <input
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                            placeholder="Enter your email"
                            type="email"
                            required
                        />
                    </div>
                </div>
                <div className='flex flex-col'>
                    <div className="card">
                        <textarea
                            value={feedbackText}
                            onChange={(e) => setFeedbackText(e.target.value)}
                            className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                            placeholder="Enter your feedback"
                            rows="4"
                            required
                        />
                    </div>
                </div>
                <button
                    type="submit"
                    className='py-4 !bg-[#686D76] rounded-xl text-white font-bold text-lg'
                    disabled={isLoading}
                >
                    {isLoading ? 'Submitting...' : 'Submit Feedback'}
                </button>
            </form>
        </div>
    );
}

export default FeedbackForm;
