import '../../styles/sharedStyle.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ThankYouFeedback from '../../components/support/thankYouFeedback';
import ThreeDHeart from '../../components/geometries/rotatingHeart';

function UserThankYouFeedback() {
    const navigate = useNavigate();

    const redirectToHome = () => {
        navigate("/");
    };

    return (
        <div className="full-screenn">
            <div className="flex flex-wrap">
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b] ">
                    <ThankYouFeedback redirectToHome={redirectToHome} />
                    </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserThankYouFeedback;
