import '../../styles/sharedStyle.css';
import React from 'react';
import TermsOfService from '../../components/support/termsOfService';

function TermsOfServicePage() {
    return (
            <TermsOfService />
    );
}

export default TermsOfServicePage;
