import React, { useState } from 'react'
import ThreeDHeart from '../../components/geometries/rotatingHeart'
import InputField from '../../components/miscBlocks/inputField'
import DetailEventTitle from '../../components/events/detailSection/detailEventTitle'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import { useNavigate } from 'react-router-dom'

const GuestEmail = () => {
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/add-guest-user`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, role : 'guest' })
            });
            const data = await response.json()
            localStorage.setItem('authKey', data.token)
            if(response?.status === 200){
                navigate(`/verify-guest-email/${data?.emailVerificationToken}`)
                setLoading(false)
            }
            else if(response?.status === 245){
                navigate(`/guest-booking`, { state: {email : email} })
                setLoading(false)
            }
            else if(response?.status === 400){
                setLoading(false)
                alert("You already have account Please sign in to your accound to book the event tickets")
            }
            else{
                setLoading(false)
                alert(data?.message)
            }
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    }

  return (
    <div className="full-screen">
            <div className="flex flex-wrap" >
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b]" style={{ zIndex: 9999 }}>
                    <div className="w-11/12 max-w-[700px] dark:bg-[#191a1b] px-10 py-20 rounded-3xl bg-white border-0 border-white">
                    <div className="card">
                            <DetailEventTitle eventName={"Book as a guest"} />
                        </div>
                        <form className='mt-8' onSubmit={handleSubmit}>
                            <InputField
                                label="Email"
                                type="email"
                                name='email'
                                placeholder="Enter your Email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                            <div className='mt-12 flex flex-col '>
                                <button className="animated-btn " style={{ zIndex: 9999 }} disabled={loading}>
                                    <span className='text'> {loading ? "Submitting..." : "Submit"}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
  )
}

export default GuestEmail
