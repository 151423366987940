import React from 'react';
import '../../../styles/detailIntro.css';
import DetailGoogleMapsPreview from './detailGoogleMapsPreview'; // Ensure correct import path

const DetailEventLocation = ({ location }) => {
  return (
    <div className="event-location">
      <p>{location}</p>
      <DetailGoogleMapsPreview address={location} />
    </div>
  );
};

export default DetailEventLocation;