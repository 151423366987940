import React from 'react'

const ConfirmationPopup = ({ message, handleDone, btnText }) => {
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-15">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="p-4 md:p-5">
                        <div className='text-xl'>
                            {message}
                        </div>
                        <div className='flex justify-center mt-6 gap-4'>
                                <button className="!w-fit animated-btn" onClick={handleDone}><span className='text'>{btnText}</span></button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopup
