import * as React from 'react';

function QuestionForm({ event, currentUser, handleSubmit }) {
    if (!event.form || !event.form.questions) {
        return <p className="text-center font-medium text-lg text-gray-500">Questions are not available</p>;
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col items-center space-y-8">
            <input type="hidden" name="email" value={currentUser && currentUser.email ? currentUser.email : ''} />
            <input type="hidden" name="eventId" value={event._id} />
            <input type="hidden" name="eventTitle" value={event.eventName} />

            {Object.keys(event.form.questions).map((questionKey, index) => (
                <div key={index} className="p-4 border-2 border-black rounded-xl bg-transparent hover:bg-gray-100 transition-all w-10/12">
                    <label className="block text-lg font-medium mb-2">{event.form.questions[questionKey]}</label>
                    <input type="hidden" name="questions[]" value={event.form.questions[questionKey]} />
                    <input type="text" name="answers[]" required className='w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent' />
                </div>
            ))}
            <button type="submit" className="mt-8 text-base bg-gray-400 text-black hover:bg-white hover:text-violet-500 shadow-lg rounded-full px-4 py-1 transition-colors duration-200">Submit</button>
        </form>
    );
}

export default QuestionForm;
