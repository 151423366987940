import React from 'react'

const ThankYou = () => {
    return (
        <div>
            <div className='w-full flex justify-center mt-16'>
                <img src='/images/right.png' alt='logo' className='w-40 h-40' />
            </div>
            <div className='flex flex-col items-center justify-center gap-10 text-center mt-24'>
                <div className='font-bold text-4xl w-5/6 lg:w-1/2'>Your booking has been confirmed. Thank you for booking with us</div>
                <div className='text-xl text-gray-500 w-5/6 lg:w-1/2'>The ticket has been sent to your email please check your email.</div>
            </div>
        </div>
    )
}

export default ThankYou
