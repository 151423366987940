import React, { useEffect, useState } from 'react'
import InputField from '../../../components/miscBlocks/inputField';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import { getCurrentUser } from '../../../api/authAPI';
import DetailEventBackButton from '../../../components/events/detailSection/detailBackButton';
import UserDashboardPopup from './user-dashboard-popup';
import UserAddAccountSection from './user-add-bank-account-section';
import { useNavigate } from 'react-router-dom';
import UserAddBankAccountPopup from '../../../components/popup/user-add-bank-account-popup';
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import UserDashboardDeleteAccountPopup from './user-dashboard-delete-account-popup';
import UserAddCardDetails from '../../../components/popup/user-add-card-details';
import DragAndDropImage from '../../../components/miscBlocks/dragAndDropImage';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';
import UserStatusModel from '../../../statuscodemanage/user-status-model';
import PhoneInput from 'react-phone-input-2';
import { checkReferralCode } from '../../../api/referralCodeAPI';
import { updateUserProfile } from '../../../api/updateUserApi';
import VerifyPhoneNumber from '../../../components/popup/VerifyPhoneNumber';
import { SendNumberOtp } from '../../../api/SendNumberOtp';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { customStyles, fetchCoordinatesFromPlaceId } from '../../../utils/getLocation';
import UserTransctionDetails from './user-transction-details';
import { getSubscriptionData } from '../../../api/subscriptionDataAPI';
// import { SocialLinks } from 'social-links';
import LoadingData from '../../../components/loading/Loading';
import PageLoader from '../../../utils/PageLoader';

// const socialLinks = new SocialLinks();

const UserDashboardCreateSettings = () => {
  const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const navigate = useNavigate()
  const [isDisable, setIsDisable] = useState(true)
  const [isDragged, setIsDragged] = useState(false)
  const [deleteAccountMessage, setDeleteAccountMessage] = useState('')
  const [userData, setUserData] = useState({
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dob: ''
  });
  const [socialMediaValid, setSocialMediaValid] = React.useState({
    instagram: true,
    twitter: true
  })
  const [valid, setValid] = useState({
    valid: null,
    text: ''
  })
  const [verifyPhoneNumber, setVerifyPhoneNumber] = useState({
    popup: false,
  })
  const [submitLoading, setSubmitLoading] = useState(false)
  const [verifyPhoneNumberLoading, setVerifyPhoneNumberLoading] = useState(false)
  const [userCardDetails, setUserCardDetails] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentBankDetails, setCurrentBankDetails] = useState({})
  const [userBankDetails, setUserBankDetails] = useState([])
  const [deletePopup, setDeletePopup] = useState(false)
  const [deleteCardPopup, setDeleteCardPopup] = useState(false)
  const [accountPopup, setAccountPopup] = useState(false)
  const [response, setResponse] = useState({})
  const [subscriptionData, setSubscriptionData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const getuserDeatails = async () => {
    setIsLoading(true)
    const authKey = localStorage.getItem('authKey')
    const { data } = await getCurrentUser(navigate, authKey, setResponse)
    if (data?.userProfileDetails?.role === 'regular') {
      const subData = await getSubscriptionData()
      setSubscriptionData(subData?.data)
      if (subData?.isActiveSubscriptionAvailable) {
        setDeleteAccountMessage('You currently have an active subscription. Please be aware that deleting your account will also cancel your subscription. Once your account is deleted, the subscription will no longer be valid. If you need any assistance or have questions, feel free to reach out to our support team.')
      }
    }
    setUserData(data?.userProfileDetails)
    setUserBankDetails(data?.userBankDetails)
    setUserCardDetails(data?.userCardDetails)
    setIsLoading(false)
  }
  useEffect(() => {
    localStorage.removeItem('email');
    localStorage.removeItem('firstName');
    localStorage.removeItem('lastName');
    localStorage.removeItem('googleId');
    getuserDeatails()
  }, [])

  const identifyOptions = [
    "Select one option",
    "Cisgender Male",
    "Cisgender Female",
    "Transgender Male",
    "Transgender Female",
    "I prefer not to say",
    "Other",
  ];

  useEffect(() => {
    if (!userData?.phoneNumber) {
      setIsDisable(false)
    }
  }, [])

  const handleDeleteCard = async () => {
    setLoading(true)
    if (userData.role === 'creator') {
      const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/delete-account?bankId=${currentBankDetails.id}&role=${userData.role}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authKey')
        },
      })
      const jsonData = await response.json()
      setResponse({ response: response, data: jsonData })
      handleSpotifyStatusCode(response.status, jsonData.message)
      if (response.status == 200) {
        setLoading(false)
        setDeleteCardPopup(false)
        window.location.reload()
      }
    }
    else {
      const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/delete-card`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('authKey')
        },
        body: JSON.stringify({ cardId: currentBankDetails.cardId })
      })
      const jsonData = await response.json()
      setResponse({ response: response, data: jsonData })
      handleSpotifyStatusCode(response.status, jsonData.message)
      if (response.status == 200) {
        setLoading(false)
        setDeleteCardPopup(false)
        window.location.reload()
      }
    }
  }
  const handleSetDefault = async (paymentDetails) => {
    let bodyContent;
    if (userData.role === 'creator') {
      bodyContent = JSON.stringify({ bankId: paymentDetails.id, role: userData.role });
    } else {
      bodyContent = JSON.stringify({ cardId: paymentDetails.cardId, role: userData.role });
    }
    setLoading(true)

    const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/set-default-source-account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authKey')
      },
      body: bodyContent
    });
    const jsonData = await response.json()
    setResponse({ response: response, data: jsonData })
    handleSpotifyStatusCode(response.status, jsonData.message)
    if (response.ok) {
      setLoading(false)
    }
    window.location.reload()
    getCurrentUser(localStorage.getItem('authKey'), setResponse)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoading(true)
    if (userData?.role === "creator") {
      if (userData?.email && userData?.profilePic && userData?.firstName && userData?.lastName && userData?.dob && userData?.phoneNumber && userData?.identifyHow && userData?.name) {
        await updateUserProfile(userData, navigate)
        setSubmitLoading(false)
        setIsDisable(true)
      }
      else {
        setSubmitLoading(false)
        alert('Please fill all details')
      }
    }
    else if (userData?.role === "regular") {
      if (userData?.email && userData?.profilePic && userData?.firstName && userData?.lastName && userData?.dob && userData?.phoneNumber && userData?.identifyHow && userData?.instagramProfile && userData?.twitterProfile) {
        if (socialMediaValid?.instagram && socialMediaValid?.twitter){
          await updateUserProfile(userData, navigate)
          setSubmitLoading(false)
          setIsDisable(true)
        }
        else{
          setSubmitLoading(false)
          alert('Social media link is invalid')
        }
      }
      else {
        alert('Please fill all details')
      }
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleImageDrop = (file) => {
    setUserData((prevState) => ({
      ...prevState,
      profilePic: file
    }));
  }

  const handleBlur = async (val) => {
    if (val !== '') {
      setValid({ ...valid, text: 'Loading...' })
      const newData = await checkReferralCode(val, 'SIGNUP_PAGE')
      setUserData((prevState) => ({
        ...prevState,
        referralCodeId: newData?.data?.signupReferralCodeId
      }));
      setValid({ ...valid, text: newData?.message, valid: newData?.success })
    }
    else {
      setValid({ ...valid, text: '', valid: false })
    }
  };

  const sendOtp = async () => {
    setVerifyPhoneNumberLoading(true)
    const response = await SendNumberOtp(userData?.phoneNumber)
    const responseData = await response?.json();
    if (response?.status === 200) {
      localStorage.setItem('phoneVerificationToken', responseData?.data?.phoneVerificationToken);
      localStorage.setItem('phoneNumber', responseData?.data?.phoneNumber);
      setVerifyPhoneNumberLoading(false)
      setVerifyPhoneNumber({ popup: true })
    }
    else {
      setVerifyPhoneNumberLoading(false)
      alert("Please enter your phone number to verify phone number")
    }
  }

  const handlePlaceChange = (place) => {
    fetchCoordinatesFromPlaceId(place.value.place_id, place.label, setUserData, userData);
  };

  return (
    <>{isLoading ? <PageLoader /> : 
    <div className='basic-container'>
      <UserStatusModel eventResponse={response} />
      <div className="button-container">
        <div className="button-wrapper"><DetailEventBackButton /></div>
      </div>
      <div className="card">
        <DetailEventTitle eventName="User Details" />
      </div>
      <form onSubmit={handleSubmit}>
        <div className='card'>
          {userData.profilePic && !isDragged && <img src={PORT_BACKEND_URL_SRC + '/' + userData.profilePic} alt="Profile Pic" style={{ maxWidth: '100%', maxHeight: '300px' }} />}
          <DragAndDropImage setIsDragged={setIsDragged} onImageDrop={handleImageDrop} />
        </div>

        <InputField
          label="First Name"
          placeholder="First Name"
          value={userData.firstName}
          name='firstName'
          disable={true}
          onChange={handleInputChange}
        />

        <InputField
          label="Last Name"
          placeholder="Last Name"
          value={userData.lastName}
          name='lastName'
          onChange={handleInputChange}
          disable={true}
        />
        <InputField
          label="Email"
          type="email"
          placeholder="Enter your email"
          value={userData.email}
          name='email'
          disable={true}
        />

        <InputField
          label="Date of Birth"
          type="date"
          name='dob'
          placeholder="Date of Birth"
          value={userData?.dob?.slice(0, 10)}
          disable={false}
          onChange={handleInputChange}
        />

        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Phone Number</label>
          <PhoneInput
            country={'us'}
            inputStyle={{ width: '100%', height: "50px", borderColor: "rgb(243 244 246)", borderWidth: '2px' }}
            value={userData?.phoneNumber}
            className="phone-input"
            onChange={(phone) => setUserData((prevState) => ({
              ...prevState,
              phoneNumber: phone
            }))}
            disabled={userData?.isPhoneVerificationCompleted}
          />
          <div className='mt-2 flex justify-between'>
            {userData?.isPhoneVerificationCompleted ? <div className='text-green-600 text-sm'>✅ Your phone number is verified</div> : <div className='text-red-600 ml-4 text-sm'>Please verify your phone number</div>}
            {!userData?.isPhoneVerificationCompleted && <div className={`${!verifyPhoneNumberLoading && "text-blue-600 underline cursor-pointer"}`} onClick={sendOtp}>{!verifyPhoneNumberLoading ? "Verify phone number" : "Loading..."}</div>}
          </div>
        </div>

        {localStorage.getItem('flow') && <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>
            Referral Code
          </label>
          <input
            name='referralcode'
            className='w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent'
            placeholder="Enter referral code"
            type="text"
            minLength={8}
            maxLength={8}
            onBlur={(e) => handleBlur(e.target.value)}
            value={userData?.referralcode}
            onChange={handleInputChange}
            required
          />
          {valid?.text && valid?.text != 'Loading...' && <div className={`text-sm mt-2 ${valid?.valid ? 'text-green-600' : 'text-red-600'}`}> {valid?.valid ? "✅" : "❌"}  {valid?.text}</div>}
          {valid?.text == 'Loading...' && <div className='text-sm mt-2'>Loading...</div>}
        </div>}

        <div className="card mt-4">
          <label className="block text-gray-700 text-sm dark:text-white font-bold mb-2">
            Identify As (reach out to us if you don't see your identity)
          </label>
          <select
            value={userData?.identifyHow}
            onChange={handleInputChange}
            name='identifyHow'
            className="focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
          >
            {identifyOptions.map((option) => (
              <option className="dark:bg-black" key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        {userData.role === 'creator' ?
          <InputField
            label="Name"
            placeholder="Name"
            value={userData.name}
            name='name'
            onChange={handleInputChange}
            disable={false}
          />
          :
          <>
            <div className="card mt-5 mb-5">
              <label className='text-lg font-medium'>
                Instagram User Name
              </label>
              <input
                name='instagramProfile'
                className='focus:outline-none focus:border-black focus:ring-0 w-full dark:border-gray-800 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                placeholder="Instagram User Name"
                type="text"
                onBlur={(e) => {
                  if (userData?.instagramProfile) {
                    if(userData?.instagramProfile.includes('instagram.com') || userData?.instagramProfile.includes('/')){
                      setSocialMediaValid({ ...socialMediaValid, instagram: false })
                    }
                    else{
                      setSocialMediaValid({ ...socialMediaValid, instagram: true })
                    }
                  }
                }}
                value={userData?.instagramProfile}
                onChange={handleInputChange}
                required
              />
              {(socialMediaValid?.instagram !== true && socialMediaValid?.instagram !== '') && <div className={`text-sm ml-4 mt-3 text-red-600`}>❌ Invalid user name</div>}
            </div>
            <div className="card mt-5 mb-5">
              <label className='text-lg font-medium'>
                Twitter User Name
              </label>
              <input
                name='twitterProfile'
                className='focus:outline-none focus:border-black focus:ring-0 w-full dark:border-gray-800 border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                placeholder="Twitter User Name"
                type="text"
                onBlur={(e) => {
                  if(userData?.twitterProfile){
                    if(userData?.twitterProfile.includes('x.com') || userData?.twitterProfile.includes('/')){
                      setSocialMediaValid({ ...socialMediaValid, twitter: false })
                    }
                    else{
                      setSocialMediaValid({ ...socialMediaValid, twitter: true })
                    }
                  }
                }}
                value={userData?.twitterProfile}
                onChange={handleInputChange}
                required
              />
              {(socialMediaValid?.twitter !== true && socialMediaValid?.twitter !== '') && <div className={`text-sm ml-4 mt-3 text-red-600`}>❌ Invalid user name</div>}
            </div>
            <div className="card mt-5 mb-5">
              <label className='text-lg font-medium'>Location</label>
              <GooglePlacesAutocomplete
                apiKey={googleMapAPIKey}
                selectProps={{
                  defaultValue: userData?.address  && {
                    label: userData?.address,
                    value: { place_id: userData?.placeId },
                  },
                  styles: customStyles,
                  placeholder: 'Search for a place...',
                  onChange: handlePlaceChange,
                }}
                onLoadFailed={(error) => (
                  console.error("Could not inject Google script", error)
                )}
              />
              <div className="font-bold text-xs mt-2">Note: This location will help to discover events happening nearby</div>
            </div>
          </>
        }

        <button className="!w-fit animated-btn mb-5" type='submit' onClick={handleSubmit}>
          <span className='text'>{submitLoading ? <LoadingData/> : "Submit"}</span>
        </button>
      </form>


      {!localStorage.getItem('flow') && (userCardDetails?.length > 0 || userBankDetails?.length > 0) && <UserAddAccountSection loading={loading} userRole={userData.role} setCurrentBankDetails={setCurrentBankDetails} handleSetDefault={handleSetDefault} setAccountPopup={setAccountPopup} userCardDetails={userCardDetails} userBankDetails={userBankDetails} setDeleteCardPopup={setDeleteCardPopup} />}

      {!localStorage.getItem('flow') && userData.role === 'regular' && subscriptionData.length > 0 && <UserTransctionDetails subscriptionData={subscriptionData} />}

      {!localStorage.getItem('flow') && <div className="flex justify-center align-center mt-4 items-baseline text-lg font-semibold">
        Want to delete your account?
        <button className="deleteform-button" onClick={() => setDeletePopup(true)} style={{ marginLeft: "20px" }}>
          Delete
        </button>
      </div>}
      {
        accountPopup && userData.role === 'creator' && <UserAddBankAccountPopup userBankDetails={userBankDetails} setAccountPopup={setAccountPopup} setResponse={setResponse} />
      }
      {
        accountPopup && userData.role !== 'creator' && <UserAddCardDetails userId={userData.id} setAccountPopup={setAccountPopup} />
      }
      {
        deleteCardPopup && <UserDashboardPopup loading={loading} onClose={() => setDeleteCardPopup(false)} onDelete={() => handleDeleteCard()} />
      }
      {
        deletePopup && <UserDashboardDeleteAccountPopup deleteAccountMessage={deleteAccountMessage} setDeletePopup={setDeletePopup} />
      }
      {
        verifyPhoneNumber?.popup && <VerifyPhoneNumber setVerifyPhoneNumber={setVerifyPhoneNumber} verifyPhoneNumber={verifyPhoneNumber} userData={userData} setUserData={setUserData} />
      }
    </div>}</>
  )
}

export default UserDashboardCreateSettings
