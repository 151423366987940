import React, { useEffect, useState } from 'react'
import { spotifyConnect } from '../../api/spotifyConnectApi'
// import { stripeConnect } from '../../api/stripeConnectApi'
import { useNavigate } from 'react-router-dom'

const SpotifyStripeConnectPopup = ({ email, setPopup, isConnected }) => {
    const navigate = useNavigate()
    const [isSkip, setIsSkip] = useState(false)
    useEffect(() => {
        if(isConnected?.spotify){
            setIsSkip(true)
        }
    }, [])
    const handleSpotifyConnect = async () => {
        await spotifyConnect(navigate)
    }
    const handleStripeConnect = async () => {
        const userId = localStorage.getItem('userId')
        navigate(
          `/stripe-connect?userId=${userId}&email=${email}`
        );
    }
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-[500] justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Connect To {isSkip ? "Stripe" : "Spotify"}
                        </h3>
                        <button type="button" onClick={() => setPopup(false)} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5">
                        <p className='text-lg'>{isSkip ? "Connect to Stripe to securely manage payments and create events seamlessly, ensuring a smooth experience for organizers" : "You will be redirected to Spotify Login for gathering important information regarding your music preferences that will powering your fans onboarding experience."}</p>
                        <p className='text-sm text-gray-700 dark:text-gray-400 mt-2'>Note: {isSkip ? "Your Stripe account will be linked to handle all financial transactions on our platform. Once connected, you will not be able to switch or modify this Stripe account. Make sure to double-check your Stripe credentials before continuing to ensure the correct account is used for payments and payouts." : "Once you authorize your Spotify account with our platform, this connection will be permanent and cannot be changed later. Please ensure that you're using the correct Spotify credentials before proceeding. If you're unsure, take a moment to verify your login details to avoid issues with account access in the future."}</p>
                        {!isSkip ? <div className='flex justify-center mt-6 gap-4'>
                            <button onClick={() => handleSpotifyConnect()} className="text-white bg-[#686D76] py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center">Connect</button>
                            <button onClick={() => setIsSkip(true)} className="text-white bg-[#4535C1] py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center">Skip</button>
                        </div> :
                            <div className='flex justify-center mt-6 gap-4'>
                                <button onClick={() => handleStripeConnect()} className="animated-btn !w-fit py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center" ><span className='text'>Connect</span></button>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SpotifyStripeConnectPopup
