import React, { useState } from 'react'
import { useFetchEventDetailsWithoutAuth } from '../user/dashboardCreate/user-dashboard-event-share-utility';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useLocation, useParams } from 'react-router-dom';
import UserEventDetails from '../regularuser/user-event-details';
import FeaturedConfirmPopup from '../../components/popup/featuredConfirmPopup';

const FeatureEventConfirm = () => {
    const { eventId } = useParams();
    const searchParams = new URLSearchParams(useLocation().search);
    const requestingStatus = searchParams.get('requestingStatus')
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(eventId, PORT_BACKEND_URL_SRC, requestingStatus);
  return (
    <div className='basic-container'>
      <UserEventDetails page={'featuredEvent'} eventDetails={eventData}/>
      <div className='mt-10'>
        <button className='animated-btn !w-fit' onClick={() => setConfirmPopup(true)}><span className='text'>Confirm</span></button>
      </div>
      {
        confirmPopup && <FeaturedConfirmPopup requestingStatus={requestingStatus} setConfirmPopup={setConfirmPopup} eventId={eventId} eventData={eventData}/>
      }
    </div>
  )
}

export default FeatureEventConfirm
