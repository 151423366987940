import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import '../../../styles/googleMapsPreview.css';

// Ensure your API key is correctly set here
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const DetailGoogleMapsPreview = ({ address }) => {
  const [markerLocation, setMarkerLocation] = useState(null);

  // Function to perform geocoding
  const fetchCoordinates = async (address) => {
    if (!address) return;

    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
      const data = await response.json();
      if (data.status === 'OK') {
        setMarkerLocation(data.results[0].geometry.location);
      } else {
        console.error('Geocoding failed:', data.status);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  useEffect(() => {
    fetchCoordinates(address);
  }, [address]);

  const handleClick = () => {
    // Construct the Google Maps search URL
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };

  // Marker component with custom icon
  const Marker = () => (
    <div style={{ width: '24px', height: '24px' }}>
      <img src="/images/locationMarker.png" alt="Location Marker" style={{ width: '100%' }} />
    </div>
  );

  return (
    <div className="google-maps-preview" onClick={handleClick}>
      {markerLocation && (
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          defaultCenter={markerLocation}
          defaultZoom={10}
          draggable={false} // Disables map dragging
        >
          <Marker lat={markerLocation.lat} lng={markerLocation.lng} />
        </GoogleMapReact>
      )}
    </div>
  );
};

export default DetailGoogleMapsPreview;