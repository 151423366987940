import '../../../styles/sharedStyle.css';
import React from 'react';
import UserConfirmationMessage from '../../../components/authBlocks/userConfirmationMessage';
import ThreeDHeart from '../../../components/geometries/rotatingHeart';


function UserConfirmationResetPassword() {
    return (
        <div className="full-screen">
        <div className="flex flex-wrap">
            <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
                <UserConfirmationMessage />
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserConfirmationResetPassword;
