import React from 'react'

const UserPersonalDetails = ({userPersonalDetails}) => {
  return (
    <div className=''>
            <div className="card">
                <div className="event-title-container flex justify-center items-center">
                    <h1 className="event-name mb-0">Personal Information</h1>
                </div>
                <div className='mt-8 flex flex-col gap-5'>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>First Name: </div>
                        <div className='text-lg'>{userPersonalDetails ?userPersonalDetails.firstName : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>Last Name: </div>
                        <div className='text-lg'>{userPersonalDetails ? userPersonalDetails.lastName : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>Email: </div>
                        <div className='text-lg'>{userPersonalDetails ?userPersonalDetails.email : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>Phone Number: </div>
                        <div className='text-lg'>{userPersonalDetails ? userPersonalDetails.phoneNumber : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>Date of birth: </div>
                        <div className='text-lg'>{userPersonalDetails ? userPersonalDetails.dob.slice(0,10) : "Loading..."}</div>
                    </div>
                    <div className='sm:flex justify-between'>
                        <div className='text-xl sm:text-xl font-semibold text-gray-600'>Identify How: </div>
                        <div className='text-lg'>{userPersonalDetails ? userPersonalDetails.identifyHow : "Loading..."}</div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default UserPersonalDetails
