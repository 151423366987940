import React, { useState, useEffect } from 'react';

const  TicketPriceProgress = ({prviewLinks, initialPrice, setPreviewPrices}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [screenWidth]);

  const totalRecommand = prviewLinks; // Example value for totalRecommand
  const maxMessages = 40; // Set maxMessages to match totalRecommand for full screen width
  const startingPrice = initialPrice;
  const cappedRecommand = Math.min(totalRecommand, 40);

  const discountPerRecommand = 0.005; // 0.5% per recommand
  const discount = startingPrice * discountPerRecommand * cappedRecommand;
  const currentPrice = Math.max(startingPrice - discount, 0); // Ensure the price doesn't go below 0

  // Calculate position ensuring the element doesn't go off the screen
  const horizontalPosition = Math.min((screenWidth / maxMessages) * totalRecommand, screenWidth - 100);
  // const verticalPosition = Math.min((window.innerHeight / maxMessages) * totalRecommand, window.innerHeight - 100);

  useEffect(() => {
    setPreviewPrices({ discountPercentage: discountPerRecommand * cappedRecommand * 100, discountedTicketPrice: currentPrice });
  }, [discount, currentPrice, setPreviewPrices]);

  return (
    <div className='w-3/4 md:m-auto sticky top-0 z-50'>
      {/*<hr className='w-1/2 h-3.5 bg-black absolute top-5' style={{backgroundColor: 'rgb(188, 188, 188,.8)'}}/>*/}
      <div
        className="ticket-container"
        style={{
          left: isMobile ? `${horizontalPosition}px` : `${horizontalPosition}px`,
          transform: isMobile ? 'rotate(345deg)' : 'rotate(345deg)'
        }}
      >
        <img src="/images/ticket.webp" alt="Ticket" className="ticket-image" />
        <span className="ticket-price">${currentPrice.toFixed(2)}</span>
      </div>
    </div>
  );
}

export default TicketPriceProgress;
