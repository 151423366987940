import '../../../styles/sharedStyle.css';
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Html5QrcodeScanner } from 'html5-qrcode';
import DynamicParticipantSearch from '../../../components/events/search/dynamicParticipantSearch';
// import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import DetailEventImage from '../../../components/events/detailSection/detailEventImage';
import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
import DetailEventDescriptionIndex from '../../../components/events/detailSection/detailEventDescriptionIndex';
import DetailEventTime from '../../../components/events/detailSection/detailEventTime';
import DetailEventLocation from '../../../components/events/detailSection/detailEventLocation';
import DetailSocialMediaLinks from '../../../components/events/detailSection/detailSocialMediaLinks';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
import DetailDashboardButton from '../../../components/events/detailSection/detailDashboardButton';
import { useFetchEventDetails, updateAttendance, handleScan } from './user-dashboard-event-details-utility';
import QrScanner from 'qr-scanner';
import { fetchParticipantData } from '../../../api/participantDataAPI';
import { attendenceConfirm } from '../../../api/attendenceConfirmAPI';
import UserDashboardPopup from './user-dashboard-popup';
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import UserStatusModel from '../../../statuscodemanage/user-status-model';
import DeleteFeaturedEvent from '../../../components/popup/deleteFeaturedEvent';
import UnFeatureDeatilsPopup from '../../../components/popup/unFeatureDeatilsPopup';
import UserDetailsPopup from '../../../components/popup/userDetailsPopup';
import PageLoader from '../../../utils/PageLoader';

function UserDashboardEventDetails() {
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false)
  const [loader, setLoader] = useState(false)
  const location = useLocation();
  const newEvent = location?.state?.event;
  const { eventId } = useParams();
  const [participantData, setParticipantData] = useState(null)
  const [response, setResponse] = useState({})
  const [eventData, setEventData, bookingDetails] = useFetchEventDetails(setResponse, navigate, setLoader);
  const [attendantMessage, setAttendantMessage] = useState('');
  const [deletePopup, setDeletePopup] = useState(false)
  const [isScanning, setIsScanning] = useState(false);
  // const scannerRef = useRef(null);
  // const isPastEvent = eventData && new Date(eventData.eventTime) < new Date();
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false)
  const [socialPopup, setSocialPopup] = useState(false)

  const [startScan, setStartScan] = useState(false)
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState('');
  const [deleteFeaturedEvent, setDeleteFeaturedEvent] = useState(false)

  const getallParticipantData = async ({ currentPage, searchTerm }) => {
    const newData = await fetchParticipantData(eventId, currentPage, searchTerm)
    setParticipantData(newData)
  }

  useEffect(() => {
    setCurrentPage(1)
    getallParticipantData({ currentPage: 1, searchTerm })
  }, [searchTerm])

  useEffect(() => {
    getallParticipantData({ currentPage, searchTerm })
  }, [currentPage])

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < participantData?.data?.totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const video = videoElementRef.current;
      if (!video) {
        // If video element is not yet available, return early
        return;
      }
      const qrScanner = new QrScanner(
        video,
        (result) => {
          if (result?.data) {
            const parcedData = JSON.parse(result?.data)
            verifyParticipantAndUpdateEventData(parcedData.participantId, parcedData.bookingTransactionId)
          }
          setScannedText(result.data);
          setStartScan(false)
        },
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
      );
      qrScanner.start();

      return () => {
        qrScanner.stop();
        qrScanner.destroy();
      };
    }, 100);
  }, [startScan]);
  //My Code End

  useEffect(() => {
    // Dynamically create and append the QR scanner div to body when scanning starts
    if (isScanning) {
      const scannerElement = document.createElement("div");
      scannerElement.setAttribute("id", "qr-reader");
      document.body.appendChild(scannerElement);

      const html5QrCodeScanner = new Html5QrcodeScanner(
        "qr-reader", { fps: 10, qrbox: { width: 250, height: 250 } }, false
      );

      const onScanSuccess = async (decodedText, decodedResult) => {
        await handleScan(decodedText, setAttendantMessage, eventId, setEventData, setIsScanning);

      };


      const onScanError = (error) => {
        console.error(error);
        // Check if the error is a NotFoundException, then set a user-friendly message
        if (error.includes("NotFoundException")) {
          setAttendantMessage("No QR code detected. Please try again.");
        } else {
          setAttendantMessage("Error scanning QR code. Please try again.");
        }
      };


      html5QrCodeScanner.render(onScanSuccess, onScanError);

      return () => {
        html5QrCodeScanner.clear().catch(err => console.log("Error stopping QR Scanner:", err));
        scannerElement.remove(); // Clean up the dynamically created div
      };
    }
  }, [isScanning]);

  // const startScanning = () => {
  //   setIsScanning(true);
  // };

  const stopScanning = () => {
    setIsScanning(false);
    // Stopping the scanner is handled by the useEffect cleanup function
  };

  const handleDeleteEvent = async () => {
    setLoading(true)
    const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-cancel-by-creator/${eventId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('authKey')
      },
    })
      .then(async (res) => {
        const data = await res.json()
        if (res.status == 200) {
          setLoading(false)
          setDeletePopup(false)
          navigate('/user-dashboard-create')
        }
        else {
          setLoading(false)
          alert(data.message)
          setDeletePopup(false)
        }
      })
  }

  const verifyParticipantAndUpdateEventData = async (participantId, bookingTransactionId) => {
    const response = await attendenceConfirm(bookingTransactionId, participantId, eventId)
    if (response?.success) {
      getallParticipantData({ currentPage, searchTerm })
    }
  };

  return (
    <>{loader ? <PageLoader/> :
    <div className="basic-container">
      <UserStatusModel eventResponse={response} />
      {
        showPopup && <UnFeatureDeatilsPopup setShowPopup={setShowPopup} />
      }
      {
        socialPopup && <UserDetailsPopup eventId={eventId} setPopup={setSocialPopup} />
      }
      {eventData ? (
        <>
          <div className="button-container">
            <div className="button-wrapper"><DetailDashboardButton /></div>
          </div>

          <div className="button-container">
            <div className="button-wrapper"><DetailBackButton /></div>
          </div>

          <div className="card">
            {/*<DetailEventTitle eventName={eventData.eventName} />*/}
            <div className="event-title-container flex justify-between items-center">
              <h1 className="event-name mb-0 whitespace-nowrap w-full overflow-hidden overflow-ellipsis">{eventData.eventName}</h1>
              <h3 className="text-lg mr-4">${eventData.ticketPrice}</h3>
            </div>
          </div>
          <div className="flex-container">
            <div className="card flex-item">
              <DetailEventImage eventImage={eventData.eventImage} altText={eventData.eventName} />

            </div>
            <div className="card flex-item">
              <div className="card dark:bg-[#191a1b]">
                <DetailEventDescriptionIndex name="Max Bookings" value={eventData.eventMaxBookings} />
              </div>
              <div className="card dark:bg-[#191a1b]">
                {/* <DetailEventDescriptionIndex name="Pre Bookings" value={eventData.eventPreBookings} /> */}
                <DetailEventDescriptionIndex name="Pre Bookings" value={eventData.eventPreBookings} />
              </div>
              <div className="card dark:bg-[#191a1b]">
                {/* <DetailEventDescriptionIndex name="Verified Bookings" value={eventData.eventQRBookings} /> */}
                <DetailEventDescriptionIndex name="Verified Bookings" value={eventData.eventVerifiedBookings} />
              </div>
              {eventData.isFeatured && <div className="card dark:bg-[#191a1b]">
                <DetailEventDescriptionIndex name="Featured status" value={eventData.isFeatured} />
              </div>}
              {eventData?.unFeatureReason && <div className="card dark:bg-[#191a1b]">
                <DetailEventDescriptionIndex name="Unfeatured status" value={eventData.isUnfeatured} />
              </div>}
              {bookingDetails &&
              <div className="card dark:bg-[#191a1b] flex justify-between">
                <span className="description-name font-bold dark:text-white">Attendees</span>
                <div className='flex'>
                  {
                    bookingDetails?.data?.map((item, index) => {
                      return (
                        <div onClick={() => setSocialPopup(true)} className={`p-1 bg-white rounded-full cursor-pointer ${index !== 0 && 'ml-[-15px]'}`} title={item?.participantFirstName}>
                          <img className='w-8 h-8 rounded-full' src={PORT_BACKEND_URL_SRC + '/' + item?.profilePic} alt='image'/>
                        </div>
                      )
                    })
                  }
                  {bookingDetails?.totalCount > 3 && <div className='p-1 ml-[-15px] cursor-pointer bg-white text-white rounded-full' onClick={() => setSocialPopup(true)}>
                    <div className='w-8 h-8 rounded-full text-xs bg-gray-300 flex justify-center items-center'>+{bookingDetails?.totalCount - 3}</div>
                  </div>}
                </div>
              </div>
            }
            </div>
          </div>
          <div className="card">
            <DetailEventDescription description={eventData.eventDescription} />
          </div>
          <div className="card">
            <DetailEventTime eventTime={eventData.eventTime} />
          </div>
          <div className="card">
            <DetailEventLocation location={eventData.address} />
          </div>
          <h1 className="participants-title">Previews</h1>
          <p className="participants-description">
            Get a taste of the event.
          </p>
          <DetailSocialMediaLinks links={eventData.eventLinks} />
          <div className='flex justify-between'>
            <button className='my-6 animated-btn !w-fit' onClick={() => {
              eventData.isFeatured && eventData.isFeatured === "Approved" ? setDeleteFeaturedEvent(true) : setDeletePopup(true)
            }} ><span className='text'>Delete Event</span></button>
            {eventData.isFeatured &&
              <span className='mt-10 font-semibold text-[#4535C1] text-md cursor-pointer underline' onClick={() => setShowPopup(true)}>How to unfeature?</span>}
          </div>
          {(
            <>
              <h1 className="participants-title">Participants</h1>
              <p className="participants-description">
                This table shows the list of participants for the event along with their verification status.
                The QR code functions might vary from browser, if not possible, compare the participant user codes
              </p>
              {/*<button onClick={startScanning} className="scan-button">Start Scanning</button>*/}
              <button onClick={() => setStartScan(true)} className="scan-button">Start Scanning</button>
              <DynamicParticipantSearch
                //eventParticipants={eventData.eventParticipants}
                eventParticipants={participantData?.data?.participantList}
                updateAttendance={updateAttendance}
                setAttendantMessage={setAttendantMessage}
                eventId={eventId}
                isScanning={isScanning}
                handleScan={handleScan}
                handleAddClick={verifyParticipantAndUpdateEventData}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
              />

              {participantData?.data?.totalPages > 0 && <div className='flex justify-center gap-4 items-center mt-12'>
                <div className="flex gap-2">
                  <button className='bg-black px-3 py-2 text-white prev-next-button !bg-[#686D76]' onClick={handlePrev}>Prev</button>
                  <button className='bg-black px-3 py-2 text-white prev-next-button !bg-[#686D76]' onClick={handleNext}>Next</button>
                </div>
                <div>
                  <span className="bg-white px-3 py-2 text-black border-2">{currentPage}</span>
                  <span className='ml-2'>of</span>
                  <span className='ml-2'>{participantData?.data?.totalPages} Pages</span>
                </div>
              </div>}

              <div>{attendantMessage}</div>
              {isScanning && (
                <>
                  <button onClick={stopScanning} className="stop-scan-button" style={{ position: "fixed", zIndex: 1000, bottom: "20px", right: "20px" }}>Stop Scanning</button>
                  <div id="full-page-scanner-overlay" style={{ position: "fixed", top: 0, left: 0, height: "100%", width: "100%", backgroundColor: "rgba(0,0,0,0.5)", zIndex: 999 }}>
                    {/* Overlay div remains unchanged */}
                  </div>
                </>
              )}
              {startScan && <div>
                <div className="videoWrapper">
                  <video className="qrVideo" ref={videoElementRef} />
                </div>
                <button onClick={() => setStartScan(false)} className="stop-scan-button" style={{ position: "fixed", zIndex: 1000, bottom: "20px", right: "20px" }}>Stop Scanning</button>
              </div>}
            </>
          )}
        </>
      ) : (
        newEvent &&
        <>
          <div className="button-container">
            <div className="button-wrapper"><DetailDashboardButton /></div>
          </div>

          <div className="button-container">
            <div className="button-wrapper"><DetailBackButton /></div>
          </div>

          <div className="card">
            {/*<DetailEventTitle eventName={newEvent?.eventName} />*/}
            <div className="event-title-container flex justify-between items-center">
              <h1 className="event-name mb-0 whitespace-nowrap w-full overflow-hidden overflow-ellipsis">{newEvent?.eventName}</h1>
              <h3 className="text-lg mr-4">${newEvent.ticketPrice}</h3>
            </div>
          </div>
          <div className="flex-container">
            <div className="card flex-item">
              <DetailEventImage eventImage={newEvent.eventImage} altText={newEvent.eventName} />

            </div>
            <div className="card flex-item">
              <div className="card">
                <DetailEventDescriptionIndex name="Max Bookings" value={newEvent.eventMaxBookings} />
              </div>
            </div>
          </div>
          <div className="card">
            <DetailEventDescription description={newEvent.eventDescription} />
          </div>
          <div className="card">
            <DetailEventTime eventTime={newEvent.eventTime} />
          </div>
          <div className="card">
            <DetailEventLocation location={newEvent.address} />
          </div>
          <h1 className="participants-title">Previews</h1>
          <p className="participants-description">
            Get a taste of the event.
          </p>
          <DetailSocialMediaLinks links={newEvent.eventLinks} />
          <div className='flex justify-between'>
            <button className='mt-6 deleteform-button'>Delete Event</button>
          </div>
        </>
      )}
      {
        deletePopup && <UserDashboardPopup loading={loading} onClose={() => setDeletePopup(false)} onDelete={() => handleDeleteEvent()} />
      }
      {
        deleteFeaturedEvent && <DeleteFeaturedEvent setDeleteFeaturedEvent={setDeleteFeaturedEvent} eventId={eventId} />
      }
    </div>}</>
  );
}

export default UserDashboardEventDetails;
