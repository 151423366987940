import '../../../styles/sharedStyle.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle';
import DetailEventDescription from '../../../components/events/detailSection/detailEventDescription';
import DetailBackButton from '../../../components/events/detailSection/detailBackButton';
import NewsletterForm from '../../../components/newsletter/newsletterForm';
import { fetchUserDataAndEvents, handleSendNewsletter } from './user-dashboard-event-newsletter-utility';
import PageLoader from '../../../utils/PageLoader';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserDashboardEventNewsletter() {
    const [currentUser, setCurrentUser] = useState(null);
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [response, setResponse] = useState({})

    useEffect(() => {
        fetchUserDataAndEvents(setCurrentUser, setEvents, setIsLoading, navigate, PORT_BACKEND_URL_SRC, setResponse);
    }, [navigate, PORT_BACKEND_URL_SRC]);

    return (
        <>
           {isLoading ? <PageLoader/> :  <div className="basic-container">
                <div className="button-container">
                    <div className="button-wrapper"><DetailBackButton /></div>
                </div>

                <div className="card">
                    <DetailEventTitle eventName={"Newsletter"} />
                </div>

                <div className="card">
                    <DetailEventDescription description={"Time to write something great to your audience. Keep it short and to the point. You can send this to all of your audience, or specifically to the group of people that joined an event you are thinking of."} />
                </div>
                <NewsletterForm
                    sendNewsletter={(newsletterData) =>
                        handleSendNewsletter(newsletterData, currentUser, PORT_BACKEND_URL_SRC)}
                    events={events}
                    currentUser={currentUser}
                />
            </div>}
        </>
    );
}

export default UserDashboardEventNewsletter;
