// SimpleQRScanner.js
import React, { useEffect, useRef } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const QRScanner = ({ onScanSuccess, onScanError }) => {
  const qrRef = useRef(null);

  useEffect(() => {
    let html5QrCodeScanner;

    if (qrRef.current) {
      html5QrCodeScanner = new Html5QrcodeScanner(
        "qr-reader", {
          fps: 10,
          qrbox: 250
        },
        false);

      html5QrCodeScanner.render(onScanSuccess, onScanError);
    }

    // Cleanup on component unmount
    return () => {
      if (html5QrCodeScanner) {
        html5QrCodeScanner.clear().catch(err => console.log("Error stopping QR Scanner:", err));
      }
    };
  }, [onScanSuccess, onScanError]);

  return <div id="qr-reader" ref={qrRef} style={{ width: "100%" }}></div>;
};

export default QRScanner;
