import '../../../styles/sharedStyle.css';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserDisplayEmailConfirmationCode() {
    const [code, setCode] = useState('');
    const { token } = useParams(); // Use useParams to get the token

    useEffect(() => {
        if (token) {
            fetch(`${PORT_BACKEND_URL_SRC}/user-display-email-confirmation-code/${token}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setCode(data.code);
                    } else {
                        setCode('Invalid or expired token.');
                    }
                })
                .catch(() => setCode('There was an error processing your request.'));
        }
    }, [token]);

    return (
        <div className="flex flex-col items-center justify-center full-screen bg-gray-100 dark:bg-[#191a1b]">
            <div className="p-10 max-w-md mx-auto bg-white rounded-xl shadow-md dark:bg-black">
                <h1 className="text-2xl font-medium mb-4">Email Verification Code</h1>
                <p className="text-lg">Your verification code is: <strong>{code}</strong></p>
            </div>
        </div>
    );
}

export default UserDisplayEmailConfirmationCode;
