import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const vertexShader = `
  varying vec3 vNormal;
  varying vec3 vPositionNormal;
  
  void main() {
    vNormal = normalize(normalMatrix * normal);
    vPositionNormal = normalize((modelViewMatrix * vec4(position, 1.0)).xyz);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const fragmentShader = `
  uniform vec3 uCameraPosition;
  varying vec3 vNormal;
  varying vec3 vPositionNormal;

  void main() {
    // Create a simple Fresnel effect with more color
    float angle = max(dot(vNormal, vec3(0, 0, 1)), 0.0);
    vec3 color = mix(vec3(1.0, 0.2, 0.0), vec3(0.0, 0.1, 1.0), angle);
    gl_FragColor = vec4(color, 1.0);
  }
`;

const ThreeDHeart = () => {
  const mountRef = useRef(null);
  const heartRef = useRef();
  const frameIdRef = useRef();

  useEffect(() => {
    const currentMountRef = mountRef.current;
    // Scene, camera, and renderer setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
    camera.position.set(0, 0, 4);

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Shader material
    const shaderMaterial = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uCameraPosition: { value: camera.position }
      }
    });

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.6);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    pointLight.position.set(50, 50, 50);
    scene.add(pointLight);

    // Load the OBJ file
    const loader = new OBJLoader();
    loader.load(
      'https://s3-us-west-2.amazonaws.com/s.cdpn.io/253981/heart10.obj',
      object => {
        object.traverse(child => {
          if (child.isMesh) {
            child.material = shaderMaterial;
          }
        });
        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        object.position.sub(center);
        scene.add(object);
        heartRef.current = object; // Store the heart object for use in the animation loop
      },
      xhr => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
      },
      error => {
        console.error('An error happened', error);
      }
    );

    // Animation loop
    const animate = () => {
      frameIdRef.current = requestAnimationFrame(animate);

      if (heartRef.current) {
        const time = Date.now() * 0.001;
        heartRef.current.rotation.x = Math.sin(time * 0.5) * Math.PI * 0.25;
        heartRef.current.rotation.y = Math.PI / 2 + Math.sin(time * 0.2) * Math.PI;
        heartRef.current.rotation.z = Math.sin(time * 0.3) * Math.PI * 0.125;
      }

      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', onWindowResize, false);

    return () => {
      window.removeEventListener('resize', onWindowResize);
      if (currentMountRef && renderer.domElement) {
        currentMountRef.removeChild(renderer.domElement);
      }
      // Add any additional cleanup here, like disposing of Three.js objects
  };
  }, []);


  return <div ref={mountRef} />;
};

export default ThreeDHeart;
