import React, { useState } from 'react'
import InputField from '../miscBlocks/inputField'
import { confirmFeatured } from '../../api/confirmFeaturedAPI'
import { handleSpotifyStatusCode } from '../../statuscodemanage/checkSpotifyStatus'
import LoadingData from '../loading/Loading'

const FeaturedConfirmPopup = ({ requestingStatus, setConfirmPopup, eventId, eventData }) => {
    const [fieldData, setFieldData] = useState('')
    const [loading, setLoading] = useState({
        load: false,
        text: ''
    })

    const handleSubmit = async (action) => {
        setLoading({...loading, load: true, text: action})
        const submitted = await confirmFeatured(fieldData, eventId, action, eventData, requestingStatus)
        const jsonData = await submitted.json()
        setLoading({...loading, load: false, text: action})
        handleSpotifyStatusCode(submitted.status, jsonData.message)
        setConfirmPopup(false)
        alert(jsonData?.message)
    }

    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Confirm {eventData?.unFeatureReason ? "Unfeatured ?" : 'Featured ?'}
                        </h3>
                        <button onClick={() => setConfirmPopup(false)} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 pt-1">
                        <div className="space-y-4">
                            <InputField
                                label="Authenticated Passsword"
                                type="text"
                                value={fieldData}
                                placeholder="Enter your Authenticated Passsword"
                                name='authenticatedPasssword'
                                onChange={(e) => setFieldData(e.target.value)}
                            />
                            <div className='flex justify-center gap-4'>
                                <button className="animated-btn mb-8 !w-fit font-bold" onClick={() => handleSubmit("Approved")}><span className='text'>{loading.load && loading.text === "Approved" ? <LoadingData/> : "Approve" }</span></button>
                                <button className="deleteform-button" onClick={() => handleSubmit("Rejected")}>{loading.load && loading.text === "Rejected" ? <LoadingData/> : "Reject" }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturedConfirmPopup
