export function formatDate(isoDateString) {

    if (!isoDateString) {
        console.error('Date not provided'); // Error log
        return 'Date not provided'; // Or handle as appropriate for your app
    }

    const dateObj = new Date(isoDateString);

    if (isNaN(dateObj.getTime())) { // More accurate check for valid date
        console.error('Invalid Date object constructed from ISO date string:', isoDateString); // Error log
        return 'Invalid Date';
    }

    const options = { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit', 
        timeZoneName: 'short'
    };

    const formattedDate = dateObj.toLocaleDateString(undefined, options);

    return formattedDate;
}
