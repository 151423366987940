const { PORT_BACKEND_URL_SRC } = require('../srcLocalPortReferenceBackend');

export const fetchUsersData = async (eventId, currentPage, searchTerm) => {
    try {
        const participantResponse = await fetch(`${PORT_BACKEND_URL_SRC}/get-booking-participant-details?eventId=${eventId}&page=${currentPage}&serachKeyword=${searchTerm}`, {
            method: 'GET',
            headers: { Authorization: localStorage.getItem('authKey') }
        });
        const participantsData = await participantResponse.json();
        if (participantResponse.status === 200) {
            if (participantsData.success) {
                return participantsData
            }
        }
    } catch (error) {
        console.error('Error fetching Users:', error);
    }
}