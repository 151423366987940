import React from 'react'

const NotFound = ({message}) => {
  return (
    <div className='h-screen bg-black flex flex-col justify-center'>
      <div className='text-center text-white text-9xl shadow-lg' style={{textShadow: 'rgb(255 203 123) 5px 5px 24px'}}>
      404</div>
      <div className='text-center  text-white text-lg font-bold px-5 shadow-lg'>{message}</div>
    </div>
  )
}

export default NotFound
