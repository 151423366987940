import React from 'react'
import { ThreeDots } from 'react-loader-spinner';
// import ConnectToSpotify from '../authBlocks/connectToSpotify';

const LoadingData = () => {
    const isDarkMode = () => document.documentElement.classList.contains('dark');
    const darkMode = isDarkMode();
    return (
        <ThreeDots
            visible={true}
            height="10"
            width="40"
            color={!darkMode ? "black" : "#FFFFFF"}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}

export default LoadingData
