import React from 'react'

const PlaceholderCardUser = () => {
  return (
    <div
      style={{
        width: "80vw", // 80% of the viewport width
        maxWidth: "400px", // Max width can still be 400px
        height: "25vh", // 25% of the viewport height
        maxHeight: "200px", // Max height can still be 200px
        marginBottom: "2rem",
      }}
      className="border dark:border-none border-gray-200 shadow rounded-md p-4 mb-4 bg-gray-200 dark:bg-gray-700"
    >
    </div>
  )
}

export default PlaceholderCardUser
