import React from 'react'
import ThreeDHeart from '../../components/geometries/rotatingHeart';
import InputField from '../../components/miscBlocks/inputField';
import DetailEventTitle from '../../components/events/detailSection/detailEventTitle';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useLocation, useNavigate } from 'react-router-dom';

const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripe = require('stripe')(stripeKey);

const GuestBooking = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)
    const location = useLocation()
    const email = location.state.email
    const [cardDetails, setCardDetails] = React.useState({
        name: '',
        number: '',
        cvc: '',
        expiryDt: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setCardDetails({ ...cardDetails, [name]: value });
    }

    const onChangeExp = (e) => {
        var value = e.target.value;
        if (value.length > 5) {
            value = value.slice(0, -1)
        }
        setCardDetails({
            ...cardDetails,
            expiryDt: value
        });
    };

    const expriy_format = (value) => {
        const expdate = value;
        const expDateFormatter =
            expdate.replace(/\//g, "").substring(0, 2) +
            (expdate.length > 2 ? "/" : "") +
            expdate.replace(/\//g, "").substring(2, 4);

        return expDateFormatter;
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const [selectedMonth, selectedYear] = cardDetails.expiryDt.split('/');
        const token = await stripe.tokens.create({
            card: {
                name: cardDetails.name,
                number: cardDetails.number,
                exp_month: selectedMonth,
                exp_year: '20' + selectedYear,
                cvc: cardDetails.cvc,
            },
        });
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/create-card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify({
                    cardToken: token.id,
                    name: cardDetails?.name,
                    email
                }),
            });
            const data = await response.json()
            if (response.ok) {
                setLoading(false)
                navigate('/user-buy-ticket')
            }
            else {
                setLoading(false)
                alert(data.message)
            }

        } catch (error) {
            console.error('Error sending feedback:', error);
            return { success: false, message: 'An error occurred while sending feedback.' };
        }
    }

    return (
        <div className="full-screen">
            <div className="flex flex-wrap">
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
                    <div className="w-11/12 max-w-[700px] dark:bg-[#191a1b] p-4 sm:px-10 sm:py-20 rounded-3xl bg-white border-0 border-white">

                        <div className='mt-8'>
                            <div className="card">
                                <DetailEventTitle eventName={"Card Details"} />
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='mt-8'>
                                <InputField
                                    label="Card Number"
                                    type="number"
                                    name='number'
                                    placeholder="Enter your Card Number"
                                    value={cardDetails.number}
                                    onChange={handleChange}
                                />
                                <InputField
                                    label="Card Holder Name"
                                    type="text"
                                    name='name'
                                    value={cardDetails.name}
                                    placeholder="Enter your Card Holder Name"
                                    onChange={handleChange}
                                />
                                <InputField
                                    label="CVC"
                                    type="password"
                                    name='cvc'
                                    value={cardDetails.cvc}
                                    placeholder="Enter your Cvc"
                                    onChange={handleChange}
                                />
                                <div className="card mt-5 mb-5">
                                    <label className='text-lg font-medium'>Expiry</label>
                                    <input
                                        type="text"
                                        name="expiryData"
                                        className="focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                                        placeholder="mm / yy"
                                        onChange={onChangeExp}
                                        value={expriy_format(cardDetails.expiryDt)}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <div className="mt-8 flex flex-col gap-y-4">
                                <button
                                    className="animated-btn"
                                >
                                    <span className='text'>{loading ? "Submitting..." : "Submit"}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    )
}

export default GuestBooking
