import React from 'react'
import { formatDate } from '../../utils/dateFormatter';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import LoadingData from '../../components/loading/Loading';

const EventCardUser = ({event, handleViewDetailsClick, handleShareClick, cancelLoading, bookingTransaction ,handleCancelClick}) => {
    const isPastEvent = new Date(event.eventTime) < new Date();
    const eventDateFormatted = formatDate(event.eventTime);
    return (
        <div className="card">
            <h3 className="text-xl font-bold whitespace-nowrap w-full overflow-hidden overflow-ellipsis">{event.eventName}</h3>
            <p className="text-gray-600 event-date dark:text-gray-400">{eventDateFormatted}</p>
            <img className="w-full h-40 object-cover rounded mt-2" src={PORT_BACKEND_URL_SRC + "/" + event.eventImage} alt={event.eventName} />
            <div className="mt-4">
                <div className="description-container">
                    <p className="text-gray-600 event-description !block dark:text-gray-400 !whitespace-nowrap !w-full !overflow-hidden !overflow-ellipsis dark:text-gray-400">{event.eventDescription}</p>
                </div>
                <p className="mt-2">Creator: {event && event.eventCreatorFirstName + ' ' + event.eventCreatorLastName}</p>
                <div className="button-group">
                    {/* Conditionally render the Check In/Details button based on whether the event is in the past */}
                    <button onClick={() => handleViewDetailsClick(event._id)} className="button">
                        Details
                    </button>
                    {/* Hide the Share button for past events */}
                    {!isPastEvent && (bookingTransaction?.status === "pre-booked" || bookingTransaction?.status === "booked" || bookingTransaction?.status === "confirmed") && (
                        <button onClick={handleShareClick} className="button">
                            Share
                        </button>
                    )}
                    
                    {!isPastEvent && ((bookingTransaction?.status === "pre-booked" || bookingTransaction?.status === "booked" || bookingTransaction?.status === "confirmed") && (
                        <button onClick={handleCancelClick} className="button !bg-[#686D76] !border-none !text-white flex justify-center">
                            {cancelLoading.loading && cancelLoading.eventId === event._id ? <LoadingData/> : "Cancel"}
                        </button>
                    ))}
                    
                </div>
            </div>
        </div>
    )
}

export default EventCardUser
