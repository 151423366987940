import React, { useState, useCallback, useEffect } from 'react';
import InputField from '../miscBlocks/inputField';
import DragAndDropImage from '../miscBlocks/dragAndDropImage'; // Ensure this import is correct


function NewsletterForm({ sendNewsletter, events, currentUser }) {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [imageUrl, setImageUrl] = useState(''); // State for image URL
    const [timePeriod, setTimePeriod] = useState('all');
    const [filteredEvents, setFilteredEvents] = useState(events); // Corrected state variable for filtered events
    // const [selectedEvent, setSelectedEvent] = useState('');
    const [messageType, setMessageType] = useState('email'); // 'sms', 'email', or 'both'
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [imageFile, setImageFile] = useState(null); // State for the uploaded image file

    const handleImageDrop = (file) => {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageUrl(reader.result); // Set the image URL for preview
        };
        reader.readAsDataURL(file);
    };
    const handleSearchChange = (e) => {
        const newSearchTerm = e.target.value;
        setSearchTerm(newSearchTerm);
        // Filter the events based on the new search term
        const newFilteredEvents = events.filter(event => {
            // Check if 'eventName' is defined and is a string
            return event.eventName && typeof event.eventName === 'string' &&
                event.eventName.toLowerCase().includes(newSearchTerm.toLowerCase());
        });
        setFilteredEvents(newFilteredEvents);
    };


    const handleEventSelect = (eventId) => {
        const isAlreadySelected = selectedEvents?.includes(eventId);
        setSelectedEvents(isAlreadySelected
            ? selectedEvents.filter(id => id !== eventId)
            : [...selectedEvents, eventId]);
    };

    // Function to filter events based on the selected time period
    const filterEventsByTimePeriod = useCallback((timePeriod) => {
        const now = new Date();
        switch (timePeriod) {
            case 'month':
                return events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 1)));
            case '3months':
                return events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 3)));
            case '6months':
                return events.filter(event => new Date(event.eventTime) > new Date(now.setMonth(now.getMonth() - 6)));
            case 'year':
                return events.filter(event => new Date(event.eventTime) > new Date(now.setFullYear(now.getFullYear() - 1)));
            default:
                return events;
        }
    }, [events]);

    // Update filtered events when the time period changes
    useEffect(() => {
        setFilteredEvents(filterEventsByTimePeriod(timePeriod));
    }, [timePeriod, filterEventsByTimePeriod]);



    const handleTimePeriodChange = (e) => {
        setTimePeriod(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        const newsletterData = {
            title,
            content,
            imageFile,
            timePeriod,
            // selectedEvents: selectedEvents.map(id => events.find(e => e._id === id)), // Ensure this is an array of selected event IDs
            selectedEvents,
            messageType,
            creatorId: currentUser // Add this line
        };


        // Call the function to send the newsletter
        try {
            await sendNewsletter(newsletterData);
            // Handle success response
            // alert('Newsletter sent successfully!');
        } catch (error) {
            // Handle error
            alert('An error occurred while sending the newsletter.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="newsletter-form-container">

            <form onSubmit={handleSubmit} className="flex flex-col">
                <div className="card">
                    <DragAndDropImage onImageDrop={handleImageDrop} />
                </div>
                <div className="card">
                    {/* Time Period Dropdown */}
                    <div className="input-group">
                        <label htmlFor="timePeriod"></label>
                        <select id="timePeriod" className='dark:bg-black' value={timePeriod} onChange={handleTimePeriodChange}>
                            <option value="all">All Time</option>
                            <option value="year">Last Year</option>
                            <option value="6months">Last 6 Months</option>
                            <option value="3months">Last 3 Months</option>
                            <option value="month">Last Month</option>
                        </select>
                    </div>
                </div>


                <div className="card mt-5 mb-5">
                    <label className='text-lg font-medium'></label>
                    <input
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        placeholder='Type to search events'
                        type='text'
                    />
                </div>

                {/* Event List with Checkboxes */}
                <div className="card" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {filteredEvents?.map((event) => (
                        <div key={event._id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                                type="checkbox"
                                id={`event-${event._id}`}
                                checked={selectedEvents.includes(event._id)}
                                onChange={() => handleEventSelect(event._id)}
                                style={{ marginRight: '10px' }}
                            />
                            <label htmlFor={`event-${event._id}`}>{event.eventName}</label>
                        </div>
                    ))}
                </div>
                {/* Display Selected Events */}
                <div className="card">
                    <h2 style={{ fontWeight: 'bold' }}>Selected events are displayed below</h2>

                    {selectedEvents?.map((id, index) => {
                        const event = events.find(e => e._id === id);
                        return <p key={index}>{event ? event.eventName : ''}</p>;
                    })}
                </div>



                <InputField
                    label=""
                    type="text"
                    placeholder="Enter title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />



                <div className="card">
                    <textarea
                        id="content"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className='focus:outline-none focus:border-black dark:border-gray-800 focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                        placeholder="Enter your content"
                        rows="4"
                        required
                    />
                </div>


                <div className="card">
                    Email
                </div>

                <div className="card">
                    <div className="preview-section mt-4">
                        <h2 style={{ fontWeight: 'bold' }}>Preview is displayed below</h2>
                        {imageUrl && <img src={imageUrl} alt="Newsletter" style={{ maxWidth: '100%', height: 'auto' }} />}
                        {/* Rest of your preview content */}
                        <h3 style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '0.5em' }}>{title}</h3>
                        <p style={{ fontSize: '1em', lineHeight: '1.5' }}>{content}</p>
                    </div>
                </div>
                {/* Send Button */}
                <div className="input-group flex justify-end mt-4"> {/* Adjust the margin-top value as needed */}
                    <button
                        type="submit"
                        disabled={isLoading}
                        className='transition-all  py-4 px-6 !bg-[#686D76] rounded-xl text-white font-bold text-lg mr-2' // Added px-6 for more padding on the sides, mr-2 for margin right
                    >
                        {isLoading ? 'Sending...' : 'Send'}
                    </button>
                </div>
            </form>
        </div>
    );
}

export default NewsletterForm;
