import React, { useState, useEffect } from 'react'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { getReferralCode } from '../api/referralCodeAPI';
import ConfirmAdminCodePopup from '../components/popup/confirmAdminCodePopup';
import PageLoader from '../utils/PageLoader';
import AdminHamburgerMenu from '../components/miscBlocks/adminHamburger';
import { getCurrentUser } from '../api/authAPI';

const AllReferralCode = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [referralData, setReferralData] = useState({})
    const [searchTerm, setSearchTerm] = useState('')
    const [menuVisible, setMenuVisible] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false)
    const [response, setResponse] = useState({})
    const [currentRefferalCode, setCurrentRefferalCode] = useState({})
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate()
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage !== referralData?.totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if(confirmPopup === false){
                setLoading(true)
                const allReferralCode = await getReferralCode(currentPage, searchTerm);
                setReferralData(allReferralCode?.data)
                setLoading(false)
            }
        };
        fetchData();
    }, [currentPage, confirmPopup]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setCurrentPage(1)
            const allReferralCode = await getReferralCode(1, searchTerm);
            setReferralData(allReferralCode?.data)
            setLoading(false)
        };
        fetchData();
    }, [searchTerm]);

    useEffect(() => {
        const getUser = async () => {
            let users = await getCurrentUser(navigate, localStorage.getItem('authKey'), setResponse, 'dashboard')
            setCurrentUser(users.data)
        }
        getUser()
    }, [])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    const columns = [
        {
            name: <div className='font-bold text-xl'>Sr</div>,
            selector: (row) => 1,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {(currentPage - 1) * 10 + i + 1}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Code</div>,
            selector: (row) => row.referralCode,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {row.referralCode}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Expiry</div>,
            selector: (row) => row.expiryDate,
            width: '280px',
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {formatDate(row.expiryDate)}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Redeemed</div>,
            selector: (row) => row.redeemed,
            format: (row, i) => {
                return (
                    <div className='text-lg ml-7'>
                        {row.redeemed}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Limit</div>,
            selector: (row) => row.limit,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {row.limit}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Status</div>,
            selector: (row) => row.status,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {row.status}
                    </div>
                );
            },
        },
        {
            name: <div className='font-bold text-xl'>Action</div>,
            selector: (row) => row.year,
            width: '240px',
            format: (row, i) => {
                return (
                    <div className='flex gap-3'>
                        <button className='animated-btn !w-fit font-bold text-[18px] my-3' onClick={() => navigate(`/edit-referral-details/${row._id}`)}><span className='text'>Edit</span></button>
                        <button className='deleteform-button my-3' onClick={() => {
                            setConfirmPopup(true)
                            setCurrentRefferalCode(row)
                        }}>Delete</button>
                    </div>
                )
            }
        },
    ];

    return (
        <>{loading ? <PageLoader/> : 
        <div className='basic-container pt-8'>
            
            <div className='sm:flex gap-20 justify-between'> 
            <AdminHamburgerMenu setMenuVisible={setMenuVisible}
                                    menuVisible={menuVisible} currentUser={currentUser}/>                
                <input type="search" onChange={(e) => setSearchTerm(e.target.value)} id="default-search" class="focus:outline-none focus:border-black focus:ring-0 block w-2/3 sm:w-1/2 p-4 text-sm h-13 text-gray-900 border border-gray-300 rounded-lg bg-white mb-8 dark:bg-black dark:text-white" placeholder="Search referral code..." />
            </div>
            <DataTable columns={columns} data={referralData?.signupReferralCodeList} progressPending={loading} />
            <div className='mt-6 flex items-center justify-center gap-6'>
                <div className="flex gap-2">
                    <button
                        className="bg-[#686D76] px-3 py-2 text-white prev-next-button"
                        onClick={handlePrev}
                    >
                        Prev
                    </button>
                    <button
                        className="bg-[#686D76] px-3 py-2 text-white prev-next-button"
                        onClick={handleNext}
                    >
                        Next
                    </button>
                </div>
                <div>
                    <span className="bg-white px-3 py-2 text-black border-2">{referralData?.currentPage}</span>
                    <span className="ml-2">of</span>
                    <span className="ml-2">{referralData?.totalPages} Pages</span>
                </div>
            </div>
            {
                confirmPopup && <ConfirmAdminCodePopup page={'delete'} setConfirmPopup={setConfirmPopup} referralData={currentRefferalCode}/>
            }
        </div>}</>
    )
}

export default AllReferralCode
