import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

export const useFetchUserData = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userResponse = await fetch(`${PORT_BACKEND_URL_SRC}/check-auth`, {
                    method: 'GET',
                    credentials: 'include',
                });
                const userData = await userResponse.json();

                if (userData.isAuthenticated && userData.userId) {
                    setCurrentUser(userData.userId);
                } else {
                    navigate('/');
                }
            } catch (error) {
                navigate('/');
            }
        };

        fetchUserData();
    }, [navigate]);

    return currentUser;
};
export const validateEventData = (eventData, imageFile, validateQuestion) => {
    const {
        eventName,
        eventDescription,
        eventTime,
        eventLocation,
        eventMaxBookings,
        formQuestions
    } = eventData;

    let missingFields = [];

    if (!eventName) missingFields.push("Event Name");
    if (!eventDescription) missingFields.push("Event Description");
    if (!eventTime) missingFields.push("Event Time");
    if (!eventLocation) missingFields.push("Event Location");
    if (eventMaxBookings == null) missingFields.push("Event Maximum Bookings");

    if (!imageFile) {
        missingFields.push("Event Image");
    } else {
        // Additional image validations (like file size, type, etc.) can go here
        // Example: Validate file size (e.g., less than 5MB)
        if (imageFile.size >  1048576) { // 1MB in bytes
            missingFields.push("Event Image too large (max 5MB)");
        }
    }

    if (formQuestions && formQuestions.some(question => !validateQuestion(question))) {
        missingFields.push("Invalid or Inappropriate Questions");
    }
    return true;
};


export const validateQuestion = (question) => {
    const wordCount = question.trim().split(/\s+/).length;
    const isValidLength = wordCount > 10;
    const containsVulgarities = /some_regex_for_vulgarities/.test(question);
    return isValidLength && !containsVulgarities;
};
