import React from 'react';
import InputField from '../miscBlocks/inputField'; // Adjust the import path as needed

function VerificationEmail({ verificationCode, setVerificationCode, handleVerification, handleResendEmail, isLoading }) {
    return (
        <div className="m-auto flex flex-col dark:bg-black items-center justify-center w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white">
            <h2 className="text-2xl font-medium mb-4">Last step, Email Verification. When verified, You will be redirected to login.</h2>
            <InputField
                label="Verification Code"
                type="text"
                placeholder="Enter 6-digit code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
            />
            <div className="mt-8 flex flex-col gap-y-4">
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <button
                        onClick={handleVerification}
                        className='animated-btn'
                    >
                        <span className='text'>Verify</span>
                    </button>
                )}
            </div>
            <div className="mt-8 flex justify-center items-center">
                <p className="font-medium text-base">Didn't receive a code?</p>
                <button onClick={handleResendEmail} className="ml-2 font-medium text-base text-[#4535C1]">
                    Resend Email
                </button>
            </div>
        </div>
    );
}

export default VerificationEmail;
