import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend'
import CancelSubscriptionPopup from '../../../components/popup/cancelSubscriptionPopup'
import SubscriptionViewDetailsPopup from '../../../components/popup/subscriptionViewDetailsPopup'

const UserTransctionDetails = ({ subscriptionData }) => {
    const [popup, setPopup] = useState(false)
    const [viewDetails, setViewDeatails] = useState({
        popup: false,
        data: {}
    })
    const [cancelDetails, setCancelDetails] = useState({})
    // const navigate = useNavigate()
    const cancelSubscription = async () => {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/cancel-subscription/${cancelDetails?.stripeSubscriptionId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': localStorage.getItem('authKey')
            },
        })
        const data = await response.json()
        if (response?.ok) {
            setPopup(false)
            window.location.reload();
        }
        else {
            alert(data?.message)
            setPopup(false)
        }
    }
    return (
        <>
            {
                popup && <CancelSubscriptionPopup setPopup={setPopup} cancelSubscription={cancelSubscription} />
            }
            {
                viewDetails?.popup && <SubscriptionViewDetailsPopup viewDetails={viewDetails} setViewDeatails={setViewDeatails}/>
            }
            <div className='card'>
                <div className='flex items-center'>
                    <div className="event-title-container">
                        <h1 className="text-md sm:text-xl font-bold md:event-name m-0">Subscription Details</h1>
                    </div>
                </div>
                <hr className='mt-4' />
                <div className='mt-4 max-h-[420px] overflow-y-auto'>
                    {
                        subscriptionData?.length > 0 &&
                        subscriptionData?.map((item) => {
                            return (
                                <div className='card cursor-pointer' onClick={() => setViewDeatails({popup: true, data: item})}>
                                    <div className='flex justify-between gap-5  mr-2'>
                                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Subscription Id</div>
                                        <div className='text-gray-700 dark:text-white md:text-xl break-all'>
                                            {item?.stripeSubscriptionId}
                                        </div>
                                    </div>
                                    <div className='flex justify-between gap-5  mr-2'>
                                        <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Status</div>
                                        <div className='text-gray-700 dark:text-white md:text-xl'>
                                            {item?.status}
                                        </div>
                                    </div>
                                    <div className='flex justify-end mt-4 gap-4'>
                                        <button
                                            type='button'
                                            className={`addBank-button py-2 px-3 !bg-[#686D76] text-sm  ${item?.status === 'InActive' && '!bg-gray-700 cursor-not-allowed'}`}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setCancelDetails(item)
                                                setPopup(true)}}
                                        >
                                            {item?.status === 'InActive' ? 'Cancelled Subscription' : 'Cancel Subscription'}
                                        </button>
                                        <button
                                            type='button'
                                            className='addBank-button !bg-[#686D76] py-2 px-3 text-sm '
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setViewDeatails({popup: true, data: item})}}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default UserTransctionDetails
