// src/components/UserPlanTransactionDetails.js
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { getTransactionData } from '../../api/transactionDataAPI';
import { useLocation } from 'react-router-dom';
import DetailEventBackButton from '../../components/events/detailSection/detailBackButton';
import PageLoader from '../../utils/PageLoader';


const UserPlanTransactionDetails = () => {
    const [transationData, setTransactionData] = useState([])
    const [pending, setPending] = useState(true);
    const location = useLocation()
    const columns = [
        {
            name: <div className='text-lg font-bold'>Transaction Id</div>,
            selector: row => row.transactionId,
            sortable: true,
            format: (row, i) => {
                return (
                    <div className='text-lg' title={row?.transactionId}>
                        {row?.transactionId}
                    </div>
                );
            },
        },

        {
            name: <div className='text-lg font-bold'>Currency</div>,
            selector: row => row.currency,
            sortable: true,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {row?.currency}
                    </div>
                );
            },
        },

        {
            name: <div className='text-lg font-bold'>Card Number</div>,
            selector: row => row.status,
            sortable: true,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        **** **** {row?.last4}
                    </div>
                );
            },
        },

        {
            name: <div className='text-lg font-bold'>Amount</div>,
            selector: row => row.amount,
            sortable: true,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        ${row?.amount}
                    </div>
                );
            },
        },

        {
            name: <div className='text-lg font-bold'>Status</div>,
            selector: row => row.status,
            sortable: true,
            format: (row, i) => {
                return (
                    <div className='text-lg'>
                        {row?.status}
                    </div>
                );
            },
        },

    ];

    const transactions = [
        {
            "_id": "66e814bf430607920fb22c07",
            "subscriptionId": "sub_1PzczmC0269sphpC2Pa0X1uw",
            "transactionId": "txn_3PzcznC0269sphpC0Bz9UpN0",
            "payment_method_types": ["card"],
            "payment_method_id": "card_1PzXhSC0269sphpCpjBLG4Gn",
            "currency": "usd",
            "amount": "2",
            "status": "succeed",
            "__v": 0
        },
        {
            "_id": "66e814bf430607920fb22c07",
            "subscriptionId": "sub_1PzczmC0269sphpC2Pa0X1uw",
            "transactionId": "txn_3PzcznC0269sphpC0Bz9UpN0",
            "payment_method_types": ["card"],
            "payment_method_id": "card_1PzXhSC0269sphpCpjBLG4Gn",
            "currency": "usd",
            "amount": "2",
            "status": "succeed",
            "__v": 0
        }
    ];

    useEffect(() => {
        const subsciptionsFunc = async () => {
            const data = await getTransactionData({ stripeSubscriptionId: location?.state?.stripeSubscriptionId })
            setTransactionData(data?.data)
            setPending(false);
        }
        subsciptionsFunc()
    }, [])

    return (
        <>{pending ? <PageLoader/> : 
        <div className='mt-16 md:mx-40 mx-6'>
            <div className="button-container">
                <div className="button-wrapper"><DetailEventBackButton /></div>
            </div>
            <div className='card '>
            <DataTable
                columns={columns}
                data={transationData}
                progressPending={pending}   
                highlightOnHover
                pointerOnHover
            />
            </div>
        </div>}</>
    );
};

export default UserPlanTransactionDetails;
