import React from 'react'

const UnFeatureDeatilsPopup = ({ setShowPopup }) => {
    const handleDone = () => {
        setShowPopup(false)
    }
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-50">
            <div className="relative p-4 w-full max-w-md sm:max-w-xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="p-4 md:p-5">
                        <div>
                            <div className='text-lg font-bold text-center'>
                                Important: Unfeaturing an Event: A Step-by-Step Guide
                            </div>
                            <div className='mt-3 text-sm'>
                                ( If you want to delete an event that's currently featured, you'll need to take a few extra steps to ensure a smooth process )
                            </div>
                            <div className='text-lg font-bold mt-3'>
                                Step 1: Unfeature the Event
                            </div>

                            Before deleting, you'll need to change the "Featured" status from the event. You can do this by clicking on the "Delete" button.

                            <div className='text-lg font-bold mt-3'>
                                Step 2: Provide a Reason
                            </div>

                            When you choose to delete the event, you'll be asked to provide a reason for doing so. This helps our admin team understand your decision and make informed choices.

                            <div className='text-lg font-bold mt-3'>
                                Step 3: Submit Request for Review
                            </div>

                            Once you've provided a reason, your request will be sent to our admin team for review. They'll assess your request and respond accordingly. If approved, the event's status will be updated. <br />
                            <div className='mt-3'>
                                By following these steps, you'll ensure that featured events are handled with care, and our admin team can make informed decisions to maintain a great user experience
                            </div>
                        </div>
                        <div className='flex justify-center mt-6 gap-4'>
                            <button className="text-white bg-[#686D76] py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center " onClick={handleDone}>Okay</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnFeatureDeatilsPopup
