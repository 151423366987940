import React, { useEffect, useState } from 'react'
import BuyNowPopup from '../../components/popup/buyNowPopup'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'
import ConfirmationPopup from '../../components/popup/bookingDonePopup'
import UserEventDetails from './user-event-details'
import { useNavigate, useLocation } from 'react-router-dom'
import { handleSpotifyStatusCode } from '../../statuscodemanage/checkSpotifyStatus'
import UserStatusModel from '../../statuscodemanage/user-status-model'
import { removeLocalStorage } from '../../utils/removeLocalstorage'
import PageLoader from '../../utils/PageLoader'
import UserAddCardDetails from '../../components/popup/user-add-card-details'

const UserBuyTicket = () => {
    const searchParams = new URLSearchParams(useLocation().search);
    const [isLoaded, setIsLoaded] = useState(true)
    const parEventId = searchParams.get('eventId')
    const eventId = localStorage.getItem('eventId') || parEventId;
    const location = useLocation();
    const newPrice = location.state || {};
    const navigate = useNavigate()
    const [eventDetails, setEventDetails] = useState({});
    const [accountPopup, setAccountPopup] = useState(false)
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [selectedCard, setSelectedCard] = useState({})
    const [ticketQuantity, setTicketQuantity] = useState(1)
    const [confirmBooking, setConfirmBooking] = useState({
        popup: false,
        message: ''
    })
    const userId = localStorage.getItem('userId')
    const [isCardAdded, setIsCardAdded] = useState(true)
    const [response, setResponse] = useState({})
    const [loading, setLoading] = useState(false)

    const getApiResponse = async () => {
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/create-checkout?eventId=${eventId}&source_type=card`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify(newPrice)
            });
            const allData = await response.json();
            setIsLoaded(false)
            if (response.status === 211) {
                alert(allData?.message)
                removeLocalStorage()
                navigate('/')
            }
            if(response.status === 212){
                alert(allData?.message)
                setIsCardAdded(false)
                setAccountPopup(true)
            }
            setResponse({ response: response, data: allData })
            if (!response.ok) {
                throw new Error(`Login failed: ${response.statusText}`);
            }
            if (response.ok) {
                return allData
            }
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiData = await getApiResponse();
                setEventDetails(apiData?.data);
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };
        fetchData();
    }, [eventId]);

    const handleDone = () => {
        setConfirmBooking(false)
        if (eventDetails?.isGuestUser) {
            navigate('/thank-you')
        }
        else {
            navigate('/user-dashboard-book')
        }
    }

    const handleBuy = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/confirm-payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
                body: JSON.stringify({
                    "cardId": selectedCard.cardId,
                    "numberOfTickets": ticketQuantity,
                    "eventId": eventId,
                    "creatorId": eventDetails.eventDetails.eventCreator,
                    'discountPercentage': newPrice.discountPercentage,
                    'discountedTicketPrice': newPrice.discountedTicketPrice
                })
            })
            const data = await response.json()
            handleSpotifyStatusCode(response.status, data.message)
            if (response.ok) {
                setConfirmBooking({ ...confirmBooking, message: data.message, popup: true })
                setConfirmPopup(false)
                setLoading(false)
            }
            else {
                setConfirmBooking({ ...confirmBooking, message: data.message, popup: true })
                setConfirmPopup(false)
                setLoading(false)
            }
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    }

    return (
        <>
            {
                isLoaded ?
                    <PageLoader/>
                    :
                    <>
                        {
                            !isCardAdded && accountPopup && <UserAddCardDetails userId={userId} setAccountPopup={setAccountPopup} />
                        }
                        <div className="basic-container">
                            <UserStatusModel eventResponse={response} />
                            {eventDetails?.eventDetails && <UserEventDetails page={'buyTicket'} eventDetails={eventDetails?.eventDetails} bookingParticipantInfo={eventDetails?.bookingParticipantInfo} />}
                            {eventDetails?.isGuestUser && <div className='font-semibold'>Note: once you book an event: cancellations are not permitted as long as you purchase the ticket after signing up</div>}
                            {eventDetails?.eventDetails && <button onClick={() => setConfirmPopup(true)} className="!w-fit mt-4 animated-btn"><span className='text'>Checkout</span></button>}
                        </div>
                        {confirmPopup && <BuyNowPopup loading={loading} setTicketQuantity={setTicketQuantity} ticketQuantity={ticketQuantity} handleBuy={handleBuy} selectedCard={selectedCard} setSelectedCard={setSelectedCard} setConfirmPopup={setConfirmPopup} eventDetails={eventDetails} />}
                        {confirmBooking.popup && <ConfirmationPopup message={confirmBooking.message} handleDone={handleDone} btnText={"Done"} />}
                    </>}
        </>
    );
};

export default UserBuyTicket;
