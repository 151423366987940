import '../../styles/sharedStyle.css';
import React from 'react';
import PrivacyPolicy from '../../components/support/privacyPolicy';

function PrivacyPolicyPage() {
    return (
        <PrivacyPolicy />
    );
}

export default PrivacyPolicyPage;
