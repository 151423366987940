// BackButton.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/detail.css';

function DetailEventBackButton() {
  let navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="back-button !bg-[#686D76]">
      Back
    </button >
  );
}

export default DetailEventBackButton;
