import React, { useState, useCallback } from 'react';
import ImageCropPopup from '../popup/imageCropPopup';

const DragAndDropImage = ({ onImageDrop, setIsDragged }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [popup, setPopup] = useState(false)
  const [croppedImage, setCroppedImage] = useState('');

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      if (file.type.startsWith('image/')) {
        const objectUrl = URL.createObjectURL(file);
        if(setIsDragged){
          setIsDragged(true)
        }
        setImagePreview(objectUrl);
        setPopup(true);
      } else {
        console.error('Selected file is not an image');
      }
    }
  }, [onImageDrop]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleTextClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';

    fileInput.onchange = (event) => {
      const file = event.target.files[0];

      if (file) {
        // Validate that the file is an image
        if (file.type.startsWith('image/')) {
          const objectUrl = URL.createObjectURL(file);
          if(setIsDragged){
            setIsDragged(true)
          }
          setImagePreview(objectUrl);
          setPopup(true);
        } else {
          console.error('Selected file is not an image');
        }
      } else {
        console.error('No file selected');
      }
    };

    fileInput.click();
  };


  return (
    <>
      {
        popup && <ImageCropPopup file={imagePreview} onImageDrop={onImageDrop} setCroppedImage={setCroppedImage} setPopup={setPopup} />
      }
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className='drag-drop'
        style={{ padding: '20px', textAlign: 'center' }}
      >
        Drag and drop your image here, keep it under 1MB in size!
        Pro tip: take a screenshot of the image you want to use and crop it in a square format
        or <span className='text-[#4535C1] cursor-pointer' onClick={handleTextClick}>Upload from here</span>
        {croppedImage && (
          <img
            src={croppedImage}
            alt="Preview"
            style={{ display: 'block', marginTop: '10px', maxWidth: '100%' }}
          />
        )}
      </div>
    </>
  );
};
export default DragAndDropImage;