import React from 'react'
import { useNavigate } from 'react-router-dom'

const SubscriptionViewDetailsPopup = ({ viewDetails, setViewDeatails }) => {
    const navigate = useNavigate()
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-50">
            <div className="relative p-4 w-full max-w-xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Subscription Details
                        </h3>
                        <button type="button" onClick={() => setViewDeatails({...viewDetails, popup: false})} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 flex flex-col gap-4">
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Subscription Id</div>
                            <div className='text-gray-700 dark:text-white md:text-xl break-all'>
                                {viewDetails?.data?.stripeSubscriptionId}
                            </div>
                        </div>
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Status</div>
                            <div className='text-gray-700 dark:text-white md:text-xl'>
                                {viewDetails?.data?.status}
                            </div>
                        </div>
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Start Date</div>
                            <div className='text-gray-700 dark:text-white md:text-xl'>
                                {new Date(viewDetails?.data?.startDate).toLocaleDateString()}
                            </div>
                        </div>
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Renewal Date</div>
                            <div className='text-gray-700 dark:text-white md:text-xl'>
                                {new Date(viewDetails?.data?.renewalDate).toLocaleDateString()}
                            </div>
                        </div>
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>End Date</div>
                            <div className='text-gray-700 dark:text-white md:text-xl'>
                                {new Date(viewDetails?.data?.endDate).toLocaleDateString()}
                            </div>
                        </div>
                        <div className='flex justify-between gap-5 mr-2'>
                            <div className='text-gray-400 font-semibold block pt-1 md:text-xl'>Price</div>
                            <div className='text-gray-700 dark:text-white md:text-xl'>
                                ${viewDetails?.data?.amount}
                            </div>
                        </div>
                        <div className='flex justify-center mt-5'>
                            <button
                                type='button'
                                className='!w-fit animated-btn'
                                onClick={() => navigate('/transaction-details', { state: { stripeSubscriptionId: viewDetails?.data?.stripeSubscriptionId } })}
                            >
                                <span className='text'>View Details</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionViewDetailsPopup
