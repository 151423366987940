import React, { useEffect } from 'react'

const GenerateCodePopup = ({ setGeneratePopup, setCodeData, codeData }) => {
    function generateReferralCode() {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let referralCode = '';
        for (let i = 0; i < 8; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            referralCode += characters[randomIndex];
        }
        return referralCode;
    }
    useEffect(() => {
        const generatedCode = generateReferralCode()
        setCodeData({ ...codeData, code: generatedCode })
    }, [])
    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full max-h-full h-screen">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Generate Code
                        </h3>
                        <button onClick={() => setGeneratePopup(false)} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 pt-1">
                        <div className="space-y-4">
                            <div>
                                <div className='text-lg mt-3'>
                                    A distinctive eight-character <span className='font-bold'>Signup referral code</span> is generated. You can use this code to serve as your invitation tool to bring new users aboard the platform
                                </div>
                                <div className='text-xl w-fit border-2 border-gray-400 rounded p-2 m-auto my-9'>
                                    {codeData?.code}
                                </div>
                            </div>
                            <div className='flex justify-center gap-4'>
                                <button className="back-button !bg-[#686D76]" onClick={() => {
                                    setCodeData({ ...codeData, action: "accept" })
                                    setGeneratePopup(false)
                                }}>Accept</button>
                                <button className="deleteform-button" onClick={() => {
                                    setCodeData({ ...codeData, action: "reject" })
                                    setGeneratePopup(false)
                                }}>Reject</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GenerateCodePopup
