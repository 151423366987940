import React, { useState, useEffect } from 'react';

const SpotifyPreview = () => {
  // State to track dragging state and position of the div
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState(false);
  const [minimize, setMinimize] = useState(true)
  const [position, setPosition] = useState({});
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  // Event handler for mouse down
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
    setMinimize(true)
  };

  window.addEventListener('resize', handleResize);

  useEffect(() => {
    setPosition({
      x: window.innerWidth - 320, // Adjust based on div width
      y: window.innerHeight / 2 - 80, // Adjust based on div height and iframe height
    })
  }, [minimize])

  // Event handler for mouse move
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  // Event handler for mouse up
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add event listeners for mouse move and mouse up when dragging starts
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      // Clean up event listeners when dragging ends
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]); // Dependency array ensures this effect runs only when isDragging changes

  return (
    <>
      {
        screenWidth > 768 &&
          (!minimize ?
          <div style={{ position: 'relative' }}>
            {/* Draggable div */}
            <div
              style={{
                width: '300px',
                height: '60px',
                zIndex: 999999,
                background: 'black',
                position: 'fixed',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                top: position.y + 70 + 'px',
                left: position.x + 'px',
                cursor: isDragging ? 'grabbing' : 'grab',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              onMouseDown={handleMouseDown}
            >
              <div className='text-white text-md mt-1  ml-3'>Move me around</div>
              <div className='text-white text-lg font-bold float-right mt-1 mr-3 cursor-pointer' onClick={(e) => {
                e.stopPropagation()
                setMinimize(true)
              }}>
              
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path fillRule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM6.75 9.25a.75.75 0 0 0 0 1.5h6.5a.75.75 0 0 0 0-1.5h-6.5Z" clipRule="evenodd" />
                </svg>
              </div>
            </div>
            {/* Iframe */}
            <iframe
              src="https://open.spotify.com/embed/playlist/37i9dQZF1DX7HOk71GPfSw?si=b253f681744743ae"
              title="Spotify Player"
              width="300"
              height="350"
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
              style={{
                position: 'fixed',
                top: position.y + 100 + 'px', // Adjust based on div height
                left: position.x + 'px',
                zIndex: 999999,
              }}
            />
          </div>
          : <img
            onClick={() => {
              setMinimize(false)
            }}
            src="/images/spotify_logo.png"  // Adjust the path as necessary
            alt="Spotify Logo"
            className="mx-auto h-10 fixed right-4 bottom-4 z-50 cursor-pointer"  // Set the height, adjust as necessary
            style={{zIndex: 999999}}
          />)
      }
    </>

  );
}

export default SpotifyPreview;
