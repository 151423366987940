import React from 'react'

const WelcomeEventDiscoveryPopup = ({message, setModel, model}) => {
    const emojiIndex = message?.indexOf('🎉');
    const textBeforeEmoji = message?.slice(0, emojiIndex + 2).trim();
    const regularText = message?.slice(emojiIndex + 2, ).trim()
  return (
    <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-[150]">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                    <div className="p-4 md:p-5">
                        <div className='text-2xl font-bold text-center'>
                            {textBeforeEmoji}
                        </div>
                        <div className='text-lg mt-5'>
                            {regularText}
                        </div>
                        <div className='flex justify-center mt-6 gap-4'>
                                <button className="text-white py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center bg-[#686D76]" onClick={() => setModel({...model, isFirstVisit: false})}>Done</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default WelcomeEventDiscoveryPopup
