import React, { useState } from 'react'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend';
import { useNavigate } from 'react-router-dom';
import { handleSpotifyStatusCode } from '../../../statuscodemanage/checkSpotifyStatus';

const UserDashboardDeleteAccountPopup = ({ deleteAccountMessage, setDeletePopup }) => {
    const navigate = useNavigate()
    const [selectedInfo, setSelectedInfo] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/delete-user`, {
                method: 'POST',
                body: JSON.stringify({
                    deleteAccountReason: selectedInfo,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('authKey')
                },
            });
            const data = await response.json();
            handleSpotifyStatusCode(response.status, data.message)
            if (response.status === 200) {
                setDeletePopup(false)
                setLoading(false)
                alert(data.message)
                navigate('/')
                localStorage.removeItem('authKey')
                localStorage.removeItem('phoneNumber')
                localStorage.removeItem('phoneVerificationToken')
                localStorage.removeItem('emailVerificationToken')
                localStorage.removeItem('eventId')
                localStorage.removeItem('userId')
            }
            else {
                alert(data.message)
                setLoading(false)
            }

            return data;
        } catch (error) {
            alert(error)
        }


    };
    return (
        <div>
            <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen">
                <div className="relative p-4 w-full max-w-lg max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b]">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Delete Account
                            </h3>
                            <button type="button" onClick={() => setDeletePopup(false)} className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5">
                            <form className="space-y-4" action="#">
                                <input type="radio" id="opt_1" name="delete_account_question" value="I no longer need the service" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_1">I no longer need the service</label><br />
                                <input type="radio" id="opt_2" name="delete_account_question" value="I found a better alternative." onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_2">I found a better alternative.</label><br />
                                <input type="radio" id="opt_3" name="delete_account_question" value="I'm concerned about privacy/security" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_3">I'm concerned about privacy/security</label><br />
                                <input type="radio" id="opt_4" name="delete_account_question" value="I'm experiencing technical issues" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_4">I'm experiencing technical issues</label><br />
                                <input type="radio" id="opt_5" name="delete_account_question" value="I'm not satisfied with the features/functionality" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_5">I'm not satisfied with the features/functionality</label><br />
                                <input type="radio" id="opt_6" name="delete_account_question" value="I'm having difficulty using the platform" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_6">I'm having difficulty using the platform.</label><br />
                                <input type="radio" id="opt_7" name="delete_account_question" value="I'm leaving the industry/event field" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_7">I'm leaving the industry/event field</label><br />
                                <input type="radio" id="opt_8" name="delete_account_question" value="I'm closing my business" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_8">I'm closing my business</label><br />
                                <input type="radio" id="opt_9" name="delete_account_question" value="Other" onChange={(e) => setSelectedInfo(e.target.value)} />
                                <label className='text-lg font-medium ml-3' htmlFor="opt_9">Other</label>
                                {deleteAccountMessage !== '' && <div className='font-semibold'>
                                    Note: {deleteAccountMessage}
                                </div>}
                                <button type="submit" disabled={loading} onClick={handleSubmit} className="w-full py-4 font-semibold rounded-lg text-lg px-5 py-4 text-center animated-btn"><span className='text'>{loading ? "Submitting..." : "Submit"} </span></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDashboardDeleteAccountPopup
