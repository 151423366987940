export const fetchCoordinatesFromPlaceId = (placeId, label, setUserData, userData) => {
    return new Promise((resolve, reject) => {
      // Create a PlacesService instance
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      // Fetch place details
      service.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          // Get latitude and longitude
          const { location } = place.geometry;
          // resolve({ latitude: location.lat(), longitude: location.lng() });
          setUserData({...userData, location: {
            longitude: location.lng(),
            latitude: location.lat(),
            placeId: placeId,
            label: label
          }})
        } else {
          reject('Failed to fetch coordinates');
        }
      });
    });
  };

// Function to determine if dark mode is enabled
const isDarkMode = () => document.documentElement.classList.contains('dark');

export const customStyles = {
  control: (base, state) => {
    const darkMode = isDarkMode();
    return {
      ...base,
      borderColor: state.isFocused ? (darkMode ? 'rgb(31 41 55)' : 'black') : (darkMode ? 'rgb(31 41 55)' : 'rgb(243, 244, 246)'), // Adjust border colors based on dark mode
      padding: '0.5rem', // Equivalent to Tailwind's p-4
      borderWidth: '2px',
      color: 'green',
      borderRadius: '0.75rem', // Equivalent to Tailwind's rounded-xl
      backgroundColor: 'transparent', // Equivalent to Tailwind's bg-transparent
      boxShadow: 'none', // Equivalent to Tailwind's focus:ring-0
      '&:hover': {
        borderColor: darkMode ? 'rgb(34, 34, 34)' : 'rgb(243, 244, 246)', // Same as non-focused border color
      },
    };
  },
  option: (base, state) => {
    const darkMode = isDarkMode();
    return {
      ...base,
      color: state.isFocused ? (darkMode ? 'black' : 'white') : (darkMode ? 'gray' : 'gray'), // Adjust text colors based on dark mode
      backgroundColor: state.isFocused ? (darkMode ? 'white' : 'black') : 'transparent', // Adjust background colors based on dark mode
      '&:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    };
  },
};
