import React from 'react'
import DetailEventBackButton from '../../components/events/detailSection/detailBackButton'

const UserCheckout = () => {
    return (
        <div className="basic-container">
            <div className="button-container">
                <div className="button-wrapper"><DetailEventBackButton /></div>
            </div>
        </div>
    )
}

export default UserCheckout
