import React, { useEffect } from 'react'
// import InputField from '../miscBlocks/inputField'
// import { ThreeDots } from 'react-loader-spinner';
// import Loading from '../loading/Loading';
import LoadingData from '../loading/Loading';

const BuyNowPopup = ({ loading, ticketQuantity, setTicketQuantity, setConfirmPopup, eventDetails, setSelectedCard, selectedCard, handleBuy }) => {
    const handleCardChange = (e) => {
        const selectedIndex = e.target.value;
        const selectCard = eventDetails.card_details.find(item => item.last4 === selectedIndex)
        setSelectedCard(selectCard);
    };

    useEffect(() => {
        const filteredData = eventDetails.card_details.find(item => item.is_default)
        setSelectedCard(filteredData)
    }, [])

    return (
        <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-150 justify-center items-center w-full md:inset-0 max-h-full h-screen bg-white">
            <div className="relative p-4 w-full max-w-3xl h-screen">
                <div className="relative bg-white rounded-lg dark:bg-[#191a1b] dark:text-white shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Confirm Payment
                        </h3>
                        <button type="button" onClick={() => setConfirmPopup(false)} className="end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <form onSubmit={handleBuy}>
                        <div className='md:flex'>
                            <div className="p-4 md:p-5">
                                <div className='card'>
                                    <label className="text-lg font-medium">
                                        Select Card
                                    </label>
                                    <select
                                        className="shadow border-2 rounded dark:bg-black rounded-xl dark:text-white dark:border-gray-800 p-4 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        onChange={handleCardChange}
                                    >
                                        {eventDetails.card_details.map((acctype, index) => <option className='capitalize py-3' key={index} value={acctype.last4}>
                                            {'********' + acctype.last4}
                                        </option>)}
                                    </select>
                                </div>
                                <div className="card">
                                    <label className='text-lg font-medium'>Card Name</label>
                                    <input
                                        label="Card Number"
                                        type='text'
                                        id="cardNumber"
                                        className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                        name="cardNumber"
                                        value={"********" + selectedCard.last4}
                                        disabled={true}
                                    />
                                </div>
                                <div className="card">
                                    <label className='text-lg font-medium'>Card Name</label>
                                    <input
                                        label="Card Name"
                                        type='text'
                                        id="name"
                                        className='focus:outline-none dark:border-gray-800  focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                        name="name"
                                        value={selectedCard.name}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="p-4 md:p-5">
                                <div className="card">
                                    <label className='text-lg font-medium'>Ticket</label>
                                    <input
                                        label="Ticket"
                                        type='number'
                                        min={1}
                                        required={true}
                                        id="ticketQuantity"
                                        className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
                                        name="ticketQuantity"
                                        value={ticketQuantity}
                                        disabled={true}
                                    />
                                </div>
                                <div className='card gap-4 flex flex-col'>
                                    <div className='flex justify-between'>
                                        <div className='text-lg font-medium'>Ticket Price</div>
                                        <div>${selectedCard.totalPrice && (selectedCard.totalPrice.ticketPrice * ticketQuantity)}</div>
                                    </div>
                                    <div className='flex justify-between'>
                                        <div className='text-lg font-medium'>Processing Fees</div>
                                        <div>${selectedCard.totalPrice && (selectedCard.totalPrice.processingFee * ticketQuantity).toFixed(2)}</div>
                                    </div>
                                    {selectedCard?.totalPrice?.discountedTicketPrice && <div className='flex justify-between'>
                                        <div className='text-lg font-medium'>Discount ({selectedCard.totalPrice.discountPercentage}%)</div>
                                        <div>- ${selectedCard.totalPrice && (selectedCard.totalPrice.ticketPrice - selectedCard.totalPrice.discountedTicketPrice).toFixed(2)}</div>
                                    </div>}
                                    <hr className='dark:border-gray-800'/>
                                    <div className='flex justify-between'>
                                        <div className='text-lg font-medium'>Sub Total</div>
                                        <div>${selectedCard.totalPrice && selectedCard.totalPrice.totalAmount * ticketQuantity}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='mb-5 mx-5 font-semibold '>Note: User should not be able to edit card name and number during payment time</div>
                        <div className='flex justify-center pb-5 gap-4'>
                            <button type='submit' className="!w-fit animated-btn mb-8" disabled={loading}><span className='text'>{loading ? <LoadingData/> : "Buy Now"
                            }</span></button>
                            <button className="deleteform-button" onClick={() => setConfirmPopup(false)}>Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BuyNowPopup
