import React from 'react';
import '../../../styles/detailIntro.css';

const DetailEventDescriptionIndex = ({ name, value }) => {
  return (
    <div className="event-description">
     <span className="description-name dark:text-white">{name}</span>
      <span className="description-value dark:text-white">{value}</span>
    </div>
  );
};

export default DetailEventDescriptionIndex;