import React, { useEffect, useState } from 'react'
// import { useFetchEventDetailsWithoutAuth } from '../user/dashboardCreate/user-dashboard-event-share-utility';
import DetailEventTitle from '../../components/events/detailSection/detailEventTitle';
import DetailEventDescriptionIndex from '../../components/events/detailSection/detailEventDescriptionIndex';
import DetailEventDescription from '../../components/events/detailSection/detailEventDescription';
import DetailEventTime from '../../components/events/detailSection/detailEventTime';
import DetailEventLocation from '../../components/events/detailSection/detailEventLocation';
import DetailSocialMediaLinks from '../../components/events/detailSection/detailSocialMediaLinks';
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend';
import { useLocation, useNavigate } from 'react-router-dom';
import DetailEventImage from '../../components/events/detailSection/detailEventImage';
import UserAddCardDetails from '../../components/popup/user-add-card-details';
import { removeLocalStorage } from '../../utils/removeLocalstorage';
import PageLoader from '../../utils/PageLoader';

const EventDetails = () => {
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(useLocation().search);
    const eventId = searchParams.get('eventId')
    const [accountPopup, setAccountPopup] = useState(false)
    const userId = localStorage.getItem('userId')
    const [eventData, setEventData] = useState({});
    const [cardAvailable, setCardAvailable] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${PORT_BACKEND_URL_SRC}/api/event-details-for-regular-user/${eventId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': localStorage.getItem('authKey')
                    }
                });
                const data = await response.json();
                setLoading(false)
                if (response.status === 211) {
                    alert(data?.message)
                    removeLocalStorage()
                    navigate('/')
                }
                if (response.ok) {
                    setEventData(data.event);
                    setCardAvailable(data.isCardAvailable)
                } else {
                    console.error(data.message || 'Failed to fetch event details.');
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {
                loading ? <PageLoader /> :
                    <div className="basic-container">
                        {
                            accountPopup && <UserAddCardDetails userId={userId} setAccountPopup={setAccountPopup} />
                        }
                        {eventData ? (
                            <>
                                <div className="card">
                                    <DetailEventTitle eventName={eventData.eventName} />
                                </div>
                                <div className="flex-container">
                                    <div className="card flex-item">
                                        <DetailEventImage
                                            eventImage={eventData?.eventImage} altText={eventData?.eventName} />
                                    </div>
                                    <div className="card flex-item">
                                        <div className="card dark:bg-[#191a1b]">
                                            <DetailEventDescriptionIndex name="Max Bookings" value={eventData.eventMaxBookings} />
                                        </div>
                                        <div className="card dark:bg-[#191a1b]">
                                            <DetailEventDescriptionIndex name="Price" value={eventData?.ticketPrice && '$' + eventData.ticketPrice} />
                                        </div>
                                        {eventData.isFeatured === "Approved" && <div className="card">
                                            <DetailEventDescriptionIndex name="Featured" value={eventData.isFeatured} />
                                        </div>}
                                    </div>
                                </div>
                                <div className="card">
                                    <DetailEventDescription description={eventData.eventDescription} />
                                </div>
                                <div className="card">
                                    <DetailEventTime eventTime={eventData.eventTime} />
                                </div>
                                <div className="card">
                                    <DetailEventLocation location={eventData.address} />
                                </div>
                                <h1 className="participants-title">Previews</h1>
                                <p className="participants-description">
                                    Get a taste of the event.
                                </p>
                                <DetailSocialMediaLinks links={eventData.eventLinks} />

                                <div className="flex justify-center gap-5 mt-10 mb-4">
                                    <button
                                        type="button"
                                        className='py-6 px-20 animated-btn !w-fit'
                                        onClick={() => {
                                            if (!cardAvailable) {
                                                setAccountPopup(true)
                                            }
                                            else {
                                                navigate(`/user-buy-ticket?eventId=${eventId}`)
                                            }
                                        }}
                                    >
                                        <span className='text'>Book</span>
                                    </button>
                                    <button
                                        type="button"
                                        className='py-6 px-20 bg-red-600 text-white rounded font-bold'
                                        onClick={() => navigate('/user-dashboard-book')}
                                    >
                                        Cancel

                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>Loading event details...</p>
                        )}
                    </div>
            }
        </>
    )
}

export default EventDetails
