// InteractiveAvatarTest.js
import React, { useState } from 'react';
import MindARFace from './mindARface';
import RotatingModel from './rotatingModel';

const Avatar = ({currentAvatar}) => {
  const [rotation, setRotation] = useState({ x: 0, y: 0, z: 0 });

  const handleRotationChange = (newRotation) => {
    setRotation(newRotation);
  };

  return (
    <div style={{ perspective: '1000px', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ visibility: 'hidden', display: 'none' }}>
        <MindARFace onRotationChange={handleRotationChange} />
      </div>
      <div style={{ position: 'absolute', zIndex: -10 }}>
        <RotatingModel currentAvatar={currentAvatar} rotation={rotation} />
      </div>
    </div>
  );
};


export default Avatar;
