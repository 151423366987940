import React, { useState } from "react";
import InputField from "../miscBlocks/inputField";
import DetailEventDescription from "./detailSection/detailEventDescription";
import DetailEventTitle from "./detailSection/detailEventTitle";
import DragAndDropImage from "../miscBlocks/dragAndDropImage"; // Adjust the import path as necessary
import LoadingData from "../loading/Loading";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { customStyles } from "../../utils/getLocation";

const EventCreateForm = ({
  eventData,
  handleInputChange,
  handleSubmit,
  handleLinkChange,
  handleImageDrop,
  loading,
  featured,
  setFeatured,
  setCoordinates
}) => {

  const spotifyLinkRegex = /^https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?.*)?$/;

  const [errors, setErrors] = useState(Array(eventData.eventLinks.length).fill(false));

  const handleLinkBlur = (index, value) => {
    if (!spotifyLinkRegex.test(value)) {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = true; // Set error for this specific input
        return newErrors;
      });
    } else {
      setErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false; // Clear error if valid
        return newErrors;
      });
    }
  };

  const now = new Date();
  const formattedDate = now.getFullYear() + '-' +
    ('0' + (now.getMonth() + 1)).slice(-2) + '-' +
    ('0' + now.getDate()).slice(-2) + 'T' +
    ('0' + now.getHours()).slice(-2) + ':' +
    ('0' + now.getMinutes()).slice(-2);
  const googleMapAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  const fetchCoordinatesFromPlaceId = (placeId, label) => {
    return new Promise((resolve, reject) => {
      // Create a PlacesService instance
      const service = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );

      // Fetch place details
      service.getDetails({ placeId }, (place, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          place.geometry
        ) {
          // Get latitude and longitude
          const { location } = place.geometry;
          setCoordinates({
            longitude: location.lng(),
            latitude: location.lat(),
            placeId: placeId,
            label: label
          })
        } else {
          // Handle errors
          reject('Failed to fetch coordinates');
        }
      });
    });
  };
  const handlePlaceChange = (place) => {
    fetchCoordinatesFromPlaceId(place.value.place_id, place.label);
  };

  return (
    <form onSubmit={handleSubmit} className="event-create-form-container">
      {/* Essentials Section */}
      <div className="section">
        <div className="card">
          <DetailEventTitle eventName="Create Event" />
        </div>

        <DragAndDropImage onImageDrop={handleImageDrop} />

        <InputField
          label="Event Name"
          id="eventName"
          name="eventName"
          type="text"
          required
          value={eventData.eventName}
          onChange={handleInputChange}
        />
        {/* Event Description */}
        <InputField
          label="Description"
          id="eventDescription"
          name="eventDescription"
          isTextArea={true}
          required
          value={eventData.eventDescription}
          onChange={handleInputChange}
        />
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Event Time</label>
          <input
            name='eventTime'
            value={eventData.eventTime}
            onChange={handleInputChange}
            className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
            min={formattedDate}
            type="datetime-local"
          />
        </div>
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Location</label>
          <GooglePlacesAutocomplete
            apiKey={googleMapAPIKey}
            selectProps={{
              styles: customStyles,
              placeholder: 'Search for a place...',
              onChange: handlePlaceChange,
            }}
            onLoadFailed={(error) => (
              console.error("Could not inject Google script", error)
            )}
          />
        </div>
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Maximum Capacity</label>
          <input
            name="eventMaxBookings"
            value={eventData.eventMaxBookings}
            onChange={handleInputChange}
            className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
            min={1}
            type="number"
            required={true}
          />
        </div>
        <div className="card mt-5 mb-5">
          <label className='text-lg font-medium'>Ticket Price</label>
          <input
            name="ticketPrice"
            value={eventData.ticketPrice}
            onChange={handleInputChange}
            className='focus:outline-none dark:border-gray-800 focus:border-black focus:ring-0 w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent'
            min={1}
            type='number'
            required={true}
          />
        </div>
      </div>
      {/* Artists Links Section */}
      <div className="section">
        <div className="card mt-5">
          <DetailEventTitle eventName="Previews" />
        </div>
        <div className="card mt-4">
          <DetailEventDescription description="The preview will render Spotify tracks, Soundcloud tracks, TikTok posts and Instagram posts links only, not profile links." />
        </div>
        {eventData.eventLinks.map((link, index) => (

          <div className="card mt-5 mb-5">
            {/* <div className='flex flex-col mt-4'> */}
            <label className='text-lg font-medium'>{`Creator Link ${index + 1}`}</label>
            <input
              key={`artistLink-${index}`}
              id={`artistLink-${index}`}
              name={`artistLink-${index}`}
              value={link}
              onChange={(e) => handleLinkChange(index, e.target.value)}
              onBlur={(e) => handleLinkBlur(index, e.target.value)}
              className='focus:outline-none focus:border-black focus:ring-0 w-full border-2 border-gray-100 dark:border-gray-800 rounded-xl p-4 mt-1 bg-transparent'
              type="text"
              required={true}
            />
            {errors[index] && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid Spotify link</p>
          )}
            {/* </div> */}
          </div>
        ))}
      </div>
      <input type="checkbox" id="featured" className="text-lg w-5 h-5 relative top-0.5" name="featured" value={featured} onChange={(e) => { setFeatured(e.target.checked) }} />
      <label for="featured" className="ml-2 text-lg font-semibold">Featured Event?<span className="text-gray-500 ml-2 font-semibold text-sm">(A featured event receives special promotion and increased visibility on our platform. It stands out among other events, attracting more attention from our audience and increasing the likelihood of attendance. Being featured can elevate the success and reach of your event, providing valuable exposure to potential attendees.)</span></label>

      {/* Form Questions Section */}
      <div className="section">
        <div className="flex justify-end mt-4">
          <button className="animated-btn !w-fit" disabled={loading}>
            <span className="text">{loading ? <LoadingData /> : 'Create'}</span>
          </button>
        </div>
      </div>
    </form>
  );
};

export default EventCreateForm;
