import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const CustomXAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(0)" fontSize="10">
      {payload.value}
    </text>
  </g>
);

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dx={-4} textAnchor="end" fill="#666" fontSize="10">
      {payload.value}
    </text>
  </g>
);

const AnalyticGraphParticipantToEvent = ({ graphData, chartWidth }) => {
  return (
    <AreaChart width={chartWidth} height={400} data={graphData}>
      <defs>
        <linearGradient id="colorMaxParticipants" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="gray" stopOpacity={0} />
          <stop offset="95%" stopColor="#fff" stopOpacity={0.6} />
        </linearGradient>
        <linearGradient id="colorPreBookings" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="orange" stopOpacity={0} />
          <stop offset="95%" stopColor="#fff" stopOpacity={0.6} />
        </linearGradient>
        <linearGradient id="colorQrBookings" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="green" stopOpacity={0} />
          <stop offset="95%" stopColor="#fff" stopOpacity={0.6} />
        </linearGradient>
      </defs>
      <XAxis
        dataKey="name"
        tick={<CustomXAxisTick />}
        stroke="#666" // Axis line color
        strokeWidth={0} // Axis line thickness
      />
      <YAxis
        tick={<CustomYAxisTick />}
        stroke="#666" // Axis line color
        strokeWidth={0} // Axis line thickness
      />
      <Tooltip
        formatter={(value, name) => [value, '']}
        labelFormatter={(name) => `${name} (${graphData.find(event => event.name === name)?.date})`}
        labelStyle={{ fontSize: '12px' }}
        contentStyle={{ fontSize: '12px' }}
      />
      <Legend
        iconType="0" // This will render a plain line instead of a box
        wrapperStyle={{
          top: 20, // Adjust these values to position the legend as desired
          right: 20, // You can add left, right, top, bottom offsets
        }}
        formatter={(value, entry, index) => {
          // This function allows you to customize the text of the legend
          const names = {
            maxParticipants: "Maximum",
            preBookings: "Pre-Booked",
            verifiedBookings: "Verified"
          };
          return names[value] || value;
        }}
      />
      <Area
        type="monotone"
        dataKey="maxParticipants"
        stroke="gray"
        fillOpacity={1}
        fill="url(#colorMaxParticipants)" // Reference the gradient definition
      />
      <Area
        type="monotone"
        dataKey="preBookings"
        stroke="orange"
        fillOpacity={1}
        fill="url(#colorPreBookings)" // Reference the gradient definition
      />
      <Area
        type="monotone"
        dataKey="verifiedBookings"
        stroke="green"
        fillOpacity={1}
        fill="url(#colorQrBookings)" // Reference the gradient definition
      />
    </AreaChart>
  );
};

export default AnalyticGraphParticipantToEvent;
