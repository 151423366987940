import "../../../styles/sharedStyle.css";
import "../../../styles/chatHistory.css";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Chat from "../../../components/miscBlocks/chat";
import Avatar from "../../../components/interactiveAvatar/avatar";
import { handleSpotifyStatusCode } from "../../../statuscodemanage/checkSpotifyStatus";
import LoadingData from "../../../components/loading/Loading";
import { TypeAnimation } from "react-type-animation";
import TicketPriceProgress from "../../../components/ticketDiscount/ticketPriceProgress";
import YoutubeChatPreview from "../../../components/previews/youtubechatPreview";
import SpotifyLinkPreviewer from "../../../components/previews/spotifyLinkPreviewer";
import { useFetchEventDetailsWithoutAuth } from "../dashboardCreate/user-dashboard-event-share-utility";
import ChatExperiencePopup from "../../../components/popup/chatExperiencePopup";
import { getAvatar } from "../../../api/getAvatar";
import { removeLocalStorage } from "../../../utils/removeLocalstorage";
const {
  PORT_BACKEND_URL_SRC,
} = require("../../../srcLocalPortReferenceBackend");

function UserChatExperience() {
  const navigate = useNavigate();
  const eventId = localStorage.getItem('eventId');
  const chatTranscriptId = localStorage.getItem('chatTranscriptId')
  const [eventData, setEventData] = useFetchEventDetailsWithoutAuth(eventId, PORT_BACKEND_URL_SRC, "SHARED_EVENT_LINK");
  const [chatHistory, setChatHistory] = useState([]);
  const [prviewLinks, setPreviewLinks] = useState(null)
  const [currentAvatar, setCurrentAvatar] = useState('')
  const chatHistoryRef = useRef(null);
  const [responseLoading, setResponseLoading] = useState(false)
  const [previewPrices, setPreviewPrices] = useState({})
  const [firstTime, setFirstTime] = useState(false)
  const [isDoneShowing, setIsDoneShowing] = useState(true)
  const [showButton, setShowButton] = useState(false);
  const [regeneratedMessage, setRegeneratedMessage] = useState('')
  const youtubeRegex = /https:\/\/(youtu\.be\/\w+|www\.youtube\.com\/watch\?v=[\w-]+)/g;
  const spotifyRegex = /https:\/\/open\.spotify\.com\/track\/[a-zA-Z0-9]+(\?[a-zA-Z0-9=&]+)?/g;

  useEffect(() => {
    // Simulate a bot initiating the conversation after the component mounts
    const initialBotMessage = {
      type: "bot",
      text: "Let's talk about music! What are your favorite artists?",
    };
    setChatHistory([initialBotMessage]);
  }, []);

  useEffect(() => {
    setFirstTime(true)
    localStorage.removeItem('chatTranscriptId')
  }, [])

  const passChatTranscript = async () => {
    try {
      const transcriptResponse = await fetch(`${PORT_BACKEND_URL_SRC}/handle-chat-vectore-messages/${localStorage.getItem('chatTranscriptId')}`, {
        method: 'GET',
        headers: { Authorization: localStorage.getItem('authKey') }
      });
      const transcriptData = await transcriptResponse.json();
      handleSpotifyStatusCode(transcriptResponse.status, transcriptData.message)
      if (transcriptResponse.status === 211) {
        alert(transcriptData.message)
        removeLocalStorage()
        navigate('/')
      
      }
      if (transcriptResponse.status === 200) {
        if (transcriptResponse.success) {
          return transcriptData
        }
      }
      else {
        alert(transcriptData?.message)
      }
    } catch (error) {
      console.error('Error fetching created events:', error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const avatarData = await getAvatar()
      setCurrentAvatar(avatarData?.userInfo?.defaultAvatarModel)
  };
  fetchData();
  }, [])

  const handleSkip = async () => {
    navigate('/user-buy-ticket', { state: previewPrices })
    if (chatTranscriptId) {
      await passChatTranscript()
    }
    localStorage.removeItem('chatTranscriptId')
  }

  function removePreviewText(inputString) {
    // Define the text to be removed using a regular expression
    const regex = /https:\/\/open\.spotify\.com\/track\/[A-Za-z0-9]+/g;

    // Replace the matched text with an empty string
    const result = inputString?.replace(regex, '');

    return result;
  }

  const handleReSendMessage = async () => {
    setShowButton(false)
      try {
        setResponseLoading(true)
        setIsDoneShowing(false)
        const response = await fetch(
          `${PORT_BACKEND_URL_SRC}/user-chat-experience/${eventId}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': localStorage.getItem('authKey') },
            body: JSON.stringify({ message: regeneratedMessage, chatTranscriptId: localStorage.getItem('chatTranscriptId') }),
          }
        );
        // const jsonData = await response.json
        const data = await response.json();
        handleSpotifyStatusCode(response.status, data.message)
        if (response.status === 229) {
          setRegeneratedMessage(data.data.previousMessage)
          setResponseLoading(false)
          setShowButton(true);
          return
        }
        if (!response.ok) {
          throw new Error("Failed to send message");
        }
        const aiResponseText = data.data.AIResponse;
        localStorage.setItem('chatTranscriptId', data.data.chatTranscriptId)
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { type: "bot", text: aiResponseText },
        ]);
        if (aiResponseText) {
          setResponseLoading(false)

          // get the all preview links
          const youtubeLinks = aiResponseText.match(youtubeRegex) || [];
          const spotifyLinks = aiResponseText.match(spotifyRegex) || [];
          const allLinks = [...youtubeLinks, ...spotifyLinks]
          setPreviewLinks(prviewLinks + allLinks.length)
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }
  };

  // This assumes you have a state named `chatHistory` and a method `setChatHistory` to update it.
  const handleSendMessage = async (userMessage) => {
    if (chatHistory.length > 20) {
      navigate('/user-buy-ticket', { state: previewPrices })
      if (chatTranscriptId) {
        await passChatTranscript()
      }
      localStorage.removeItem('chatTranscriptId')
    }
    else {
      try {
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { type: "user", text: userMessage },
        ]);
        setResponseLoading(true)
        setIsDoneShowing(false)
        const response = await fetch(
          `${PORT_BACKEND_URL_SRC}/user-chat-experience/${eventId}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': localStorage.getItem('authKey') },
            body: JSON.stringify({ message: userMessage, chatTranscriptId: localStorage.getItem('chatTranscriptId') }),
          }
        );
        // const jsonData = await response.json
        const data = await response.json();
        handleSpotifyStatusCode(response.status, data.message)
        localStorage.setItem('chatTranscriptId', data.data.chatTranscriptId)
        if (response.status === 229) {
          setRegeneratedMessage(data.data.previousMessage)
          setResponseLoading(false)
          setShowButton(true);
          return
        }
        if (!response.ok) {
          throw new Error("Failed to send message");
        }
        const aiResponseText = data.data.AIResponse;
        setChatHistory((chatHistory) => [
          ...chatHistory,
          { type: "bot", text: aiResponseText },
        ]);
        if (aiResponseText) {
          setResponseLoading(false)

          // get the all preview links
          const youtubeLinks = aiResponseText.match(youtubeRegex) || [];
          const spotifyLinks = aiResponseText.match(spotifyRegex) || [];
          const allLinks = [...youtubeLinks, ...spotifyLinks]
          setPreviewLinks(prviewLinks + allLinks.length)
        }
      } catch (error) {
        console.error("Error sending message:", error);
      }

    }

  };

  // Function to extract music preferences from the user message
  // function extractMusicPreferences(userMessage) {
  //   // Implement your logic to extract genres, artists, and tracks from the user message
  //   // Example:
  //   const genres = ["rock", "pop"];
  //   const artists = ["Artist1", "Artist2"];
  //   const tracks = ["Track1", "Track2"];

  //   return { genres, artists, tracks };
  // }

  // Function to get music recommendations from Spotify
  // async function getSpotifyRecommendations(preferences) {
  //   try {
  //     const response = await fetch("/api/spotify/music-recommendations", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ preferences }),
  //     });

  //     if (!response.ok) throw new Error("Failed to fetch recommendations");

  //     const tracks = await response.json();
  //     return tracks;
  //   } catch (error) {
  //     console.error("Error fetching recommendations:", error);
  //     throw error;
  //   }
  // }

  const renderSocialMediaPreview = (link) => {
    if (link.includes("open.spotify.com")) {
      return <SpotifyLinkPreviewer link={link} />;
    } else if (link.includes("youtube.com")) {
      return <YoutubeChatPreview link={link} />;
    } else {
      return <div className="unsupported-link">Unsupported link type.</div>;
    }
  };

  return (
    <>
      {
        firstTime && <ChatExperiencePopup setFirstTime={setFirstTime}/>
      }
      <div className="user-chat-experience-container">
        {/* <div className="w-full z-50">
          <div className="bg-black text-center text-white py-2 text-sm z-50 dark:bg-gray-600">This model is made by ai model <a className='text-blue-500 hover:underline' target='_blank' href='https://sketchfab.com/3d-models/blu-anime-girl-character-c75ab6b43e1e49da952361ee6b36f0a4'>link</a>, if you would like to submit your model to be part of the website experience contact bbfunai4u@gmail.com</div>
        </div> */}
        <TicketPriceProgress prviewLinks={prviewLinks} initialPrice={eventData ? eventData?.ticketPrice : ''} setPreviewPrices={setPreviewPrices} />
        <div className="avatar-container">
          {currentAvatar !== '' && <Avatar currentAvatar={currentAvatar}/>}
        </div>
        <div className="chat-history-container" ref={chatHistoryRef}>
          {chatHistory.map((message, index) => (
            <div key={index} className={`mt-4 ${message.type === "UserMessage" ? "flex justify-end gap-2 " : "flex justify-start flex-col gap-2"}`}>
              <div
                key={index}
                className={`message ${message.type === "user" ? "user-message" : "bot-message"
                  }`}
              >
                {message.type === "user" ? message.text : <TypeAnimation cursor={false} sequence={[(removePreviewText(message.text)), () => setIsDoneShowing(true)]} speed={90} />}
                <div className="flex flex-col gap-3 mt-3">
                  {message.text &&
                    [].concat(
                      message.text.match(youtubeRegex) || [],
                      message.text.match(spotifyRegex) || []
                    ).map((newLink, linkIndex) => {
                      return (
                        <div key={linkIndex}>
                          {renderSocialMediaPreview(newLink)}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          ))}
          {showButton && (
                <div className="mt-4 flex justify-center">
                  <button className="bg-red-500 text-white px-3 py-2 rounded flex items-center gap-3" onClick={handleReSendMessage}>
                    <i className="fa fa-refresh"></i>
                    Regenerate
                  </button>
                </div>
              )}
          {
            responseLoading && <div className="flex justify-start flex-col gap-2"><div
              className='message bot-message'
            >
              <LoadingData />
            </div></div>
          }
        </div>
        <div className="chat-input-container dark:bg-black">
          <Chat isDoneShowing={isDoneShowing} responseLoading={responseLoading} onSendMessage={handleSendMessage} />
        </div>
        <button className="fixed font-bold right-5 px-6 text-lg py-4 cursor-pointer animated-btn text-white rounded top-12 md:top-12 z-50 !w-fit" onClick={handleSkip}><span className="text">Skip</span></button>
        {/*<div className="fixed bg-black text-white rounded w-52 p-3 right-2 bottom-20">This model is made by [name Link], if you would like to submit your model to be part of the website experience contact [staff email link] BB4U</div>*/}
      </div>
    </>
  );
}

export default UserChatExperience;
