import React from 'react'

const CancelSubscriptionPopup = ({setPopup, cancelSubscription}) => {
  return (
    <div id="authentication-modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabindex="-1" aria-hidden="true" className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 max-h-full h-screen z-50">
            <div className="relative p-4 w-full max-w-lg max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-[#191a1b] ">
                    <div className="p-4 md:p-5">
                    <svg className="mx-auto mb-6 text-gray-400 w-16 h-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <h3 className="mb-6 text-2xl font-normal text-gray-500 text-center" >Are you sure you want to cancel subscription?</h3>
                        <div className='flex justify-center mt-10 gap-4'>
                                <button className='back-button !bg-[#686D76]' onClick={cancelSubscription}>Yes</button>
                                <button className="deleteform-button" onClick={() => setPopup(false)}>No</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default CancelSubscriptionPopup
