import React, { useState } from 'react'
import InputField from '../../components/miscBlocks/inputField'
import DetailEventTitle from '../../components/events/detailSection/detailEventTitle'
import ThreeDHeart from '../../components/geometries/rotatingHeart'
import { useNavigate, useParams } from 'react-router-dom'
import { PORT_BACKEND_URL_SRC } from '../../srcLocalPortReferenceBackend'

const GuestVerifyEmail = () => {
    const { emailVerificationToken } = useParams();
    const [loading, setLoading] = useState(false)
    const [verifyEmailCode, setVerifyEmailCode] = useState('')
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/verify-email/${emailVerificationToken}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('authKey') },
                body: JSON.stringify({ code: verifyEmailCode })
            });
            const data = response.json()
            if(response?.status === 200){
                navigate(`/guest-booking`, { state: {email : data.email} })
                setLoading(false)
            }
            else{
                setLoading(false)
                alert(data?.message)
            }
        } catch (error) {
            console.error('Error during login:', error);
            throw error;
        }
    }
    return (
        <div className="full-screen">
            <div className="flex flex-wrap" >
                <div className="custom-flex-container-1-3 dark:bg-[#191a1b]" style={{ zIndex: 9999 }}>
                    <div className="w-11/12 max-w-[700px] dark:bg-[#191a1b]  px-10 py-20 rounded-3xl bg-white border-0 border-white">
                        <div className="card">
                            <DetailEventTitle eventName={"Verify Email"} />

                        </div>
                        <form className='mt-8' onSubmit={handleSubmit}>
                            <InputField
                                label="Verification Code"
                                type="number"
                                name='verifyEmailCode'
                                placeholder="Enter Verification Code"
                                onChange={(e) => setVerifyEmailCode(e.target.value)}
                                value={verifyEmailCode}
                            />
                            <div className='mt-12 flex flex-col '>
                                <button className="animated-btn" style={{ zIndex: 9999 }}>
                                    <span className='text'>{loading ? "Submitting..." : "Submit"}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    )
}

export default GuestVerifyEmail
