
import '../../../styles/sharedStyle.css';
import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EventHeader from '../../../components/events/eventHeader';
import QuestionForm from '../../../components/events/questionForm';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');


function UserEventForm({ currentUser }) {
    const { eventId } = useParams();
    const [event, setEvent] = React.useState(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        fetch(`${PORT_BACKEND_URL_SRC}/rsvp/${eventId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setEvent(data.event);
                }
            })
            .catch(error => console.error('Error fetching event:', error.message));
    }, [eventId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const questions = Object.values(event.form.questions);
        const answers = Array.from(e.target).filter(input => input.name.startsWith("answers")).map(input => input.value);
        
        const body = {
            email: currentUser && currentUser.email ? currentUser.email : '',
            eventId: event._id,
            eventTitle: event.eventName,
            responses: questions.map((q, index) => ({ question: q, answer: answers[index] }))
        };
    
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/thankYou`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
                credentials: 'include'
            });
            
            const data = await response.json();
            if (data.success) {
                navigate('/thankYou');
            } else {
                // Handle error
                console.error('Error from server:', data.message);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    
    if (!event) {
        return <p>Loading...</p>;
    }

    return (
        <div className="flex w-full justify-center bg-white overflow-y-auto">
            <div className="w-11/12 max-w-[600px] flex flex-col gap-8 p-10 rounded-3xl bg-white border-2 border-white mt-16">
                <EventHeader eventName={event.eventName} />
                <QuestionForm event={event} currentUser={currentUser} handleSubmit={handleSubmit} />
            </div>
        </div>
    );
}

export default UserEventForm;