import '../../../styles/sharedStyle.css';
import React, { useState } from 'react';
import UserConfirmationMessageSignUp from '../../../components/authBlocks/userConfirmationMessageSignUp';
import ThreeDHeart from '../../../components/geometries/rotatingHeart';
import { useNavigate, useParams } from 'react-router-dom';
const { PORT_BACKEND_URL_SRC } = require('../../../srcLocalPortReferenceBackend');

function UserConfirmationSignUp() {
    const [verificationCode, setVerificationCode] = useState('');
    const navigate = useNavigate();
    const { phoneVerificationToken } = useParams(); // Retrieve the phoneVerificationToken from the URL using useParams

    const handleVerification = async () => {
        try {
            // Make an API call to your verification endpoint with the phoneVerificationToken parameter
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/verify-sms/${phoneVerificationToken}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ code: verificationCode })
            });
            const data = await response.json();

            if (data.success) {
                navigate('/success_page_route'); // Redirect to success page
            } else {
                alert('Verification failed. Please try again.');
            }
        } catch (error) {
            console.error('Verification error:', error);
            alert('Error during verification.');
        }
    };

    return (
        <div className="full-screen">
        <div className="flex flex-wrap">
            <div className="custom-flex-container-1-3 dark:bg-[#191a1b]">
                <UserConfirmationMessageSignUp
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    handleVerification={handleVerification}
                />
                      </div>
                <div className="custom-flex-container-2-3 overflow-x-hidden">
                    <ThreeDHeart />
                </div>
            </div>
        </div>
    );
}

export default UserConfirmationSignUp;
