import React from 'react'

const PageLoader = () => {
  return (
    <div className="flex justify-center items-center w-screen h-screen">
      {/* Light mode loader */}
      <img
        src="/images/black-loader.gif"
        alt="Loading..."
        className="block dark:hidden w-32 h-32 z-[1000000000]"
      />
      
      {/* Dark mode loader */}
      <img
        src="/images/white-loader.gif"
        alt="Loading..."
        className="hidden dark:block w-32 h-32 z-[1000000000]"
      />
    </div>
  )
}

export default PageLoader
