
import React from 'react';

function SoundcloudPreview({ link }) {
    // The embed URL for SoundCloud can be constructed from the track or playlist URL
    // Here's a basic way to convert a regular SoundCloud link to an embeddable link
    const embedUrl = `https://w.soundcloud.com/player/?url=${encodeURIComponent(link)}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`;

    return (

        <iframe 
            title="Soundcloud Content"
            scrolling="no" 
            frameBorder="no" 
            allow="autoplay"
            src={embedUrl}>
              </iframe>
    
    );
}

export default SoundcloudPreview;
