import { PORT_BACKEND_URL_SRC } from "../srcLocalPortReferenceBackend";

export const updateUserProfile = async (userData, navigate) => {
    const usedFor = localStorage.getItem('flow') ? 'FIRST_TIME_UPDATE' : 'EXISTING_DETAILS_UPDATE'
    let formData = new FormData();
    // Append key-value pairs to the FormData object
    formData.append('profilePic', userData?.profilePic);
    formData.append('firstName', userData?.firstName);
    formData.append('lastName', userData?.lastName);
    formData.append('email', userData?.email);
    formData.append('dob', userData?.dob);
    formData.append('phoneNumber', userData?.phoneNumber);
    formData.append('identifyHow', userData?.identifyHow);
    if (localStorage.getItem('flow')) {
        formData.append('signupReferralCodeId', userData?.referralCodeId);
    }
    if (userData?.role === 'creator') {
        formData.append('name', userData?.name);
    }
    else {
        formData.append('instagramProfile', 'https://www.instagram.com/' + userData?.instagramProfile);
        formData.append('twitterProfile', 'https://x.com/' + userData?.twitterProfile);
        if(userData?.location?.placeId){
            formData.append('location', JSON.stringify({
                type: "Point",
                coordinates: [userData?.location?.longitude, userData?.location?.latitude]  // [longitude, latitude]
            }));
            formData.append('placeId', userData?.location?.placeId);
            formData.append('address', userData?.location?.label);
        }
    }
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/update-user-profile?usedFor=${usedFor}`, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem('authKey')
            },
            body: formData
        });

        const responseData = await response.json();

        if (response.ok) {
            alert(responseData?.message)
            if (userData?.role === 'creator') {
                if (!localStorage.getItem('flow')) {
                    navigate('/user-dashboard-create')
                }
                else {
                    navigate('/user-free-chat')
                }
            }
            else {
                if (!localStorage.getItem('flow')) {
                    navigate('/user-dashboard-book')
                }
                else {
                    if(localStorage.getItem('eventId')){
                        navigate('/user-add-card')
                    }
                    else{
                        navigate('/user-discovery')
                    }
                }
            }

        } else {
            alert(responseData?.message);
        }
    } catch (error) {
        alert(error.message);
    }
};