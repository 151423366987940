import React from 'react';

function SpotifyPreview({ link }) {
    // Transform the link to an embed URL
    const embedUrl = link.replace('open.spotify.com', 'open.spotify.com/embed');

    return (
    
            <iframe
                className="spotify-iframe"
                title="Spotify Content"
                src={embedUrl}
                // frameBorder="0"
                allowTransparency="true"
                allow="encrypted-media">
            </iframe>
  
    );
}

export default SpotifyPreview;
