import { PORT_BACKEND_URL_SRC } from "../srcLocalPortReferenceBackend";

export const getTransactionData = async ({stripeSubscriptionId}) => {
    try {
        const response = await fetch(`${PORT_BACKEND_URL_SRC}/stripe/fetch-transaction-details?stripeSubscriptionId=${stripeSubscriptionId}`, {
            method: 'GET',
            headers: {
                 'Authorization': localStorage.getItem('authKey')
            }
        });
        const data = await response.json()
        if (response.ok) {
            return data
        }
        else{
            alert(data?.message)
        }

    } catch (error) {
        alert(error.message);
    }
};