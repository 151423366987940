import React from 'react'
import ThreeDHeart from '../../../components/geometries/rotatingHeart'
import InputField from '../../../components/miscBlocks/inputField'
import DetailEventTitle from '../../../components/events/detailSection/detailEventTitle'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { PORT_BACKEND_URL_SRC } from '../../../srcLocalPortReferenceBackend'

const UserRetrieveAccount = () => {
    const location = useLocation()
    const navigate = useNavigate()
    localStorage.setItem('authKey', location.state.userData.token)
    const handleRetriveUserData = async () => {
        try {
            const response = await fetch(`${PORT_BACKEND_URL_SRC}/active-retrieve-delete-user`, {
                method: 'POST',
                headers: {
                    'Authorization': location.state.userData.token,
                    'Content-Type': 'application/json',
                },
            })
            if(response.ok){
                const responseData = await response.json()
                localStorage.setItem('phoneVerificationToken', responseData.data.phoneVerificationToken);
                localStorage.setItem('emailVerificationToken', responseData.data.emailVerificationToken);
                localStorage.setItem('phoneNumber', responseData.data.phoneNumber);
                navigate(`/verify-sms/${responseData.data.phoneVerificationToken}`)
            }
        } catch (error) {
            alert(error)
        }
    }
    return (
        <div>
            <div className="full-screen">
                <div className="flex flex-wrap">
                    <div className="custom-flex-container-1-3">
                        <div className="w-11/12 max-w-[700px] px-10 py-20 rounded-3xl bg-white border-0 border-white">
                            <div className="card">
                                <DetailEventTitle eventName={"Retrieve Account"} />
                            </div>
                            <div className="mt-8">
                                <InputField
                                    label="Email"
                                    type="email"
                                    value={location.state.userData.userData.email}
                                    disable={true}
                                />
                                <InputField
                                    label="First Name"
                                    type="text"
                                    value={location.state.userData.userData.firstName}
                                    disable={true}
                                />
                                <InputField
                                    label="Last Name"
                                    type="email"
                                    value={location.state.userData.userData.lastName}
                                    disable={true}
                                />
                                <InputField
                                    label="date of Birth"
                                    type="date"
                                    value={location.state.userData.userData.dob.slice(0, 10)}
                                    disable={true}
                                />
                                <InputField
                                    label="Phone Number"
                                    type="text"
                                    value={location.state.userData.userData.phoneNumber}
                                    disable={true}
                                />
                                <InputField
                                    label="Identify How"
                                    type="text"
                                    value={location.state.userData.userData.identifyHow}
                                    disable={true}
                                />
                                <div className="mt-8 flex gap-5 justify-end">
                                    <button
                                        className='addBank-button'
                                        onClick={handleRetriveUserData}
                                    >
                                        Continue
                                    </button>
                                    <button
                                        className='cancelform-button'
                                        onClick={() => navigate('/user-signup')}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-flex-container-2-3 overflow-x-hidden">
                        <ThreeDHeart />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserRetrieveAccount
