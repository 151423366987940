import React from 'react';
import { Link } from 'react-router-dom';
import DetailEventDescription from '../events/detailSection/detailEventDescription';

function UserConfirmationMessage() {
    return (
        <div className='w-11/12 max-w-[700px] dark:bg-[#191a1b] px-10 py-20 rounded-3xl bg-transparent'>

            <div className="card mt-5 mb-5">
                <div className="event-title-container">
                    <h1 className="event-name">{"Check Your Email"}</h1>
                </div>
            </div>

            <div className="card mt-5 mb-5">
                <DetailEventDescription description={" An email has been sent to you."} />
            </div>
            <div className='mt-8'>
                <Link to="/" className='font-medium text-base text-[#4535C1]'>Go to Login</Link>
            </div>
        </div>
    );
}

export default UserConfirmationMessage;
